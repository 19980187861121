<template>

<!-- Testimonials -->
			<section class="testimonials">
				<div class="container">
					<div class="section-header text-center">
						<h5>TESTIMONIALS</h5>
						<h2>What Patient say about us</h2>
						<p class="sub-title">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took</p>
					</div>
					<div class="row">
						<div class="col-12">
							<!-- Slider -->
							<div class="testimonials-slider slider">
							
								<!-- Slider Item -->
								<div class="testimonials-item" v-for="item in testimonial" :key="item.id">
									<div class="card">
										<div class="card-header">
											<div class="d-flex align-items-center justify-content-between">
												<div class="">
													<img :src="loadImg(item.img)" alt="" width="83" class="rounded-circle">
												</div>
												<div class="patient-details">
													<h5>{{item.title}}</h5>
													<h6>{{item.title1}}</h6>
												</div>
												<div>
													<img :src="loadImg1(item.img1)" alt="">
												</div>
											</div>
										</div>
										<div class="card-body">
											<p>{{item.description}}</p>
										</div>
									</div>	
								</div>
								<!-- /Slider Item -->
								
							</div>
							<!-- /Slider -->
						</div>
					</div>
				</div>
			</section>
			<!-- /Testimonials -->
</template>
<script>
	import testimonial from '../../../../assets/json/frontend/home/testimonial.json'
	const images = require.context('../../../../assets/img/patients', false, /\.png$|\.jpg$/)
	const images1 = require.context('../../../../assets/img', false, /\.png$|\.jpg$/)
	export default {
	mounted() {
	if($('.testimonials-slider').length > 0) {
		$('.testimonials-slider').slick({
			dots: true,
			autoplay:false,
			infinite: true,
			prevArrow: false,
			nextArrow: false,
			slidesToShow: 3,
  			slidesToScroll: 3,
  			responsive: [
			    {
			      breakpoint: 991,
			      settings: {
			        slidesToShow: 2,
			        slidesToScroll: 2,
			      }
			    },
			    {
			      breakpoint: 767,
			      settings: {
			        slidesToShow: 1,
			        slidesToScroll: 1
			      }
			    }
			]
		});
	}
	},
	methods:{
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
		loadImg1(img1Path) {
            return images1('./' + img1Path).default
        },
    },
	data() {
        return {
            testimonial: testimonial
        }
    }
	}
	</script>