<template>

    <div class="main-wrapper">

        <layout-header></layout-header>

        <!-- Breadcrumb -->
			<breadcrumb15></breadcrumb15>
			<!-- /Breadcrumb -->
			
			<!-- Page Content -->
			<div class="content">
				<div class="container">

					
					<div class="comp-sec-wrapper">
					
						<!-- Avatar -->
						<avatar></avatar>
						<!-- /Avatar -->
						
						<!-- Alerts -->
						<alert></alert>
						<!-- /Alerts -->
						
						<!-- Breadcrumbs -->
						<section class="comp-section">
							<div class="comp-header">
                                <h3 class="comp-title">Breadcrumbs</h3>
                                <div class="line"></div>
                            </div>
							<div class="card">
								<div class="card-body">
									<nav aria-label="breadcrumb">
										<ol class="breadcrumb">
											<li class="breadcrumb-item active" aria-current="page">Home</li>
										</ol>
									</nav>
									<nav aria-label="breadcrumb">
										<ol class="breadcrumb">
											<li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
											<li class="breadcrumb-item active" aria-current="page">Products</li>
										</ol>
									</nav>
									<nav aria-label="breadcrumb">
										<ol class="breadcrumb mb-0">
											<li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
											<li class="breadcrumb-item"><a href="javascript:void(0);">Products</a></li>
											<li class="breadcrumb-item active" aria-current="page">Accessories</li>
										</ol>
									</nav>
								</div>
							</div>
						</section>
						<!-- /Breadcrumbs -->
						
						<!-- Buttons -->
						<section class="comp-section comp-buttons">
							<div class="comp-header">
                                <h3 class="comp-title">Buttons</h3>
                                <div class="line"></div>
                            </div>
							<div class="card">
								<div class="card-body">
									<h4 class="card-title">Default Button</h4>
									<button type="button" class="btn btn-primary ms-1">Primary</button>
									<button type="button" class="btn btn-secondary ms-1">Secondary</button>
									<button type="button" class="btn btn-success ms-1">Success</button>
									<button type="button" class="btn btn-danger ms-1">Danger</button>
									<button type="button" class="btn btn-warning ms-1">Warning</button>
									<button type="button" class="btn btn-info ms-1">Info</button>
									<button type="button" class="btn btn-light ms-1">Light</button>
									<button type="button" class="btn btn-dark ms-1">Dark</button>
									<button type="button" class="btn btn-link ms-1">Link</button>
									<hr>
									<h4 class="card-title">Button Sizes</h4>
									<p>
										<button type="button" class="btn btn-primary btn-lg">Primary</button>
										<button type="button" class="btn btn-secondary btn-lg ms-1">Secondary</button>
										<button type="button" class="btn btn-success btn-lg ms-1">Success</button>
										<button type="button" class="btn btn-danger btn-lg ms-1">Danger</button>
										<button type="button" class="btn btn-warning btn-lg ms-1">Warning</button>
										<button type="button" class="btn btn-info btn-lg ms-1">Info</button>
										<button type="button" class="btn btn-light btn-lg ms-1">Light</button>
										<button type="button" class="btn btn-dark btn-lg ms-1">Dark</button>
									</p>
									<p>
										<button type="button" class="btn btn-primary">Primary</button>
										<button type="button" class="btn btn-secondary ms-1">Secondary</button>
										<button type="button" class="btn btn-success ms-1">Success</button>
										<button type="button" class="btn btn-danger ms-1">Danger</button>
										<button type="button" class="btn btn-warning ms-1">Warning</button>
										<button type="button" class="btn btn-info ms-1">Info</button>
										<button type="button" class="btn btn-light ms-1">Light</button>
										<button type="button" class="btn btn-dark ms-1">Dark</button>
									</p>
									<p>
										<button type="button" class="btn btn-primary btn-sm">Primary</button>
										<button type="button" class="btn btn-secondary btn-sm ms-1">Secondary</button>
										<button type="button" class="btn btn-success btn-sm ms-1">Success</button>
										<button type="button" class="btn btn-danger btn-sm ms-1">Danger</button>
										<button type="button" class="btn btn-warning btn-sm ms-1">Warning</button>
										<button type="button" class="btn btn-info btn-sm ms-1">Info</button>
										<button type="button" class="btn btn-light btn-sm ms-1">Light</button>
										<button type="button" class="btn btn-dark btn-sm ms-1">Dark</button>
									</p>
									<hr>
									<h4 class="card-title">Button Groups</h4>
									<div class="btn-toolbar">
										<div class="btn-group btn-group-lg">
											<button type="button" class="btn btn-primary">Left</button>
											<button type="button" class="btn btn-primary">Middle</button>
											<button type="button" class="btn btn-primary">Right</button>
										</div>
									</div>
									<br>
									<div class="btn-toolbar">
										<div class="btn-group">
											<button type="button" class="btn btn-primary">Left</button>
											<button type="button" class="btn btn-primary">Middle</button>
											<button type="button" class="btn btn-primary">Right</button>
										</div>
									</div>
									<br>
									<div class="btn-toolbar">
										<div class="btn-group btn-group-sm">
											<button type="button" class="btn btn-primary">Left</button>
											<button type="button" class="btn btn-primary">Middle</button>
											<button type="button" class="btn btn-primary">Right</button>
										</div>
									</div>
								</div>
							</div>
							
							<!-- Rounded Button -->
							<div class="card">
								<div class="card-header">
									<h4 class="card-title">Rounded Button</h4>
									<p class="card-text">use <code>.btn-rounded</code> in class <code>.btn</code> class to get Rounded button</p>
								</div>
								<div class="card-body">
									<button type="button" class="btn btn-rounded btn-primary">Primary</button>
									<button type="button" class="btn btn-rounded btn-secondary ms-1">Secondary</button>
									<button type="button" class="btn btn-rounded btn-success ms-1">Success</button>
									<button type="button" class="btn btn-rounded btn-danger ms-1">Danger</button>
									<button type="button" class="btn btn-rounded btn-warning ms-1">Warning</button>
									<button type="button" class="btn btn-rounded btn-info ms-1">Info</button>
									<button type="button" class="btn btn-rounded btn-light ms-1">Light</button>
									<button type="button" class="btn btn-rounded btn-dark ms-1">Dark</button>
									<hr>
									<p>use <code>.btn-rounded</code> in class <code>.btn-outline-*</code> class to get Rounded Outline button</p>
									<button type="button" class="btn btn-rounded btn-outline-primary">Primary</button>
									<button type="button" class="btn btn-rounded btn-outline-secondary ms-1">Secondary</button>
									<button type="button" class="btn btn-rounded btn-outline-success ms-1">Success</button>
									<button type="button" class="btn btn-rounded btn-outline-danger ms-1">Danger</button>
									<button type="button" class="btn btn-rounded btn-outline-warning ms-1">Warning</button>
									<button type="button" class="btn btn-rounded btn-outline-info ms-1">Info</button>
									<button type="button" class="btn btn-rounded btn-outline-light ms-1">Light</button>
									<button type="button" class="btn btn-rounded btn-outline-dark ms-1">Dark</button>
								</div>
							</div>
							<!-- /Rounded Button -->
							
							<!-- Outline Buttons -->
							<div class="card">
								<div class="card-header">
									<h4 class="card-title">Outline Buttons</h4>
									<p class="card-text">Use <code>.btn-outline-*</code> class for outline buttons.</p>
								</div>
								<div class="card-body">
									<div class="row row-sm align-items-center">
										<div class="col-12 col-xl mb-3 mb-xl-0">Normal</div>
										<div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
											<button type="button" class="btn w-100 btn-outline-primary">Primary</button>
										</div>
										<div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
											<button type="button" class="btn w-100 btn-outline-secondary">Secondary</button>
										</div>
										<div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
											<button type="button" class="btn w-100 btn-outline-success">Success</button>
										</div>
										<div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
											<button type="button" class="btn w-100 btn-outline-warning">Warning</button>
										</div>
										<div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
											<button type="button" class="btn w-100 btn-outline-danger">Danger</button>
										</div>
										<div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
											<button type="button" class="btn w-100 btn-outline-info">Info</button>
										</div>
										<div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
											<button type="button" class="btn w-100 btn-outline-light">Light</button>
										</div>
										<div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
											<button type="button" class="btn w-100 btn-outline-dark">Dark</button>
										</div>
									</div>
									<div class="row row-sm align-items-center mt-3">
										<div class="col-12 col-xl mb-3 mb-xl-0">Active</div>
										<div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
											<button type="button" class="btn w-100 btn-outline-primary active">Primary</button>
										</div>
										<div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
											<button type="button" class="btn w-100 btn-outline-secondary active">Secondary</button>
										</div>
										<div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
											<button type="button" class="btn w-100 btn-outline-success active">Success</button>
										</div>
										<div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
											<button type="button" class="btn w-100 btn-outline-warning active">Warning</button>
										</div>
										<div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
											<button type="button" class="btn w-100 btn-outline-danger active">Danger</button>
										</div>
										<div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
											<button type="button" class="btn w-100 btn-outline-info active">Info</button>
										</div>
										<div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
											<button type="button" class="btn w-100 btn-outline-light active">Light</button>
										</div>
										<div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
											<button type="button" class="btn w-100 btn-outline-dark active">Dark</button>
										</div>
									</div>
									<div class="row row-sm align-items-center mt-3">
										<div class="col-12 col-xl mb-3 mb-xl-0">Disabled</div>
										<div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
											<button disabled="" type="button" class="btn w-100 btn-outline-primary">Primary</button>
										</div>
										<div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
											<button disabled="" type="button" class="btn w-100 btn-outline-secondary">Secondary</button>
										</div>
										<div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
											<button disabled="" type="button" class="btn w-100 btn-outline-success">Success</button>
										</div>
										<div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
											<button disabled="" type="button" class="btn w-100 btn-outline-warning">Warning</button>
										</div>
										<div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
											<button disabled="" type="button" class="btn w-100 btn-outline-danger">Danger</button>
										</div>
										<div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
											<button disabled="" type="button" class="btn w-100 btn-outline-info">Info</button>
										</div>
										<div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
											<button disabled="" type="button" class="btn w-100 btn-outline-light">Light</button>
										</div>
										<div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
											<button disabled="" type="button" class="btn w-100 btn-outline-dark">Dark</button>
										</div>
									</div>
								</div>
							</div>
							<!-- /Outline Buttons -->

						</section>
						<!-- Buttons -->
						
						<!-- Cards -->
						<card></card>
						<!-- /Cards -->
						
						<!-- Dropdowns -->
						<dropdown></dropdown>
						<!-- /Dropdowns -->

						<!-- Pagination -->
						<pagination></pagination>
						<!-- /Pagination -->
						
						<!-- Progress -->
						<section class="comp-section">
							<div class="comp-header">
                                <h3 class="comp-title">Progress</h3>
                                <div class="line"></div>
                            </div>
							<div class="progress-example card">
								<div class="card-header">
									<h4 class="card-title">Large Progress Bars</h4>
								</div>
								<div class="card-body pb-0">
									<div class="row">
										<div class="col-md-6">
											<div>
												<div class="progress progress-lg">
													<div class="progress-bar" role="progressbar" style="width: 10%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
												</div>
												<div class="progress progress-lg">
													<div class="progress-bar bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
												</div>
												<div class="progress progress-lg">
													<div class="progress-bar bg-info" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
												</div>
												<div class="progress progress-lg">
													<div class="progress-bar bg-warning" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
												</div>
												<div class="progress progress-lg">
													<div class="progress-bar bg-danger" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
												</div>
											</div>
										</div>
										<div class="col-md-6">
											<div class="progress progress-lg">
												<div class="progress-bar progress-bar-striped" role="progressbar" style="width: 10%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
											<div class="progress progress-lg">
												<div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
											<div class="progress progress-lg">
												<div class="progress-bar progress-bar-striped bg-info" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
											<div class="progress progress-lg">
												<div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
											<div class="progress progress-lg">
												<div class="progress-bar progress-bar-striped bg-danger" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
										</div>
									</div>
								</div>
							</div>
							
							<div class="progress-example card">
								<div class="card-header">
									<h4 class="card-title">Default Progress Bars</h4>
								</div>
								<div class="card-body pb-0">
									<div class="row">
										<div class="col-md-6">
											<div class="progress">
												<div class="progress-bar" role="progressbar" style="width: 10%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
											<div class="progress">
												<div class="progress-bar bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
											<div class="progress">
												<div class="progress-bar bg-info" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
											<div class="progress">
												<div class="progress-bar bg-warning" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
											<div class="progress">
												<div class="progress-bar bg-danger" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
										</div>
										<div class="col-md-6">
											<div class="progress">
												<div class="progress-bar progress-bar-striped" role="progressbar" style="width: 10%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
											<div class="progress">
												<div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
											<div class="progress">
												<div class="progress-bar progress-bar-striped bg-info" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
											<div class="progress">
												<div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
											<div class="progress">
												<div class="progress-bar progress-bar-striped bg-danger" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
										</div>
									</div>
								</div>
							</div>
							
							<div class="progress-example card">
								<div class="card-header">
									<h4 class="card-title">Medium Progress Bars</h4>
								</div>
								<div class="card-body pb-0">
									<div class="row">
										<div class="col-md-6">
											<div>
												<div class="progress progress-md">
													<div class="progress-bar" role="progressbar" style="width: 10%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
												</div>
												<div class="progress progress-md">
													<div class="progress-bar bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
												</div>
												<div class="progress progress-md">
													<div class="progress-bar bg-info" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
												</div>
												<div class="progress progress-md">
													<div class="progress-bar bg-warning" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
												</div>
												<div class="progress progress-md">
													<div class="progress-bar bg-danger" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
												</div>
											</div>
										</div>
										<div class="col-md-6">
											<div class="progress progress-md">
												<div class="progress-bar progress-bar-striped" role="progressbar" style="width: 10%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
											<div class="progress progress-md">
												<div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
											<div class="progress progress-md">
												<div class="progress-bar progress-bar-striped bg-info" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
											<div class="progress progress-md">
												<div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
											<div class="progress progress-md">
												<div class="progress-bar progress-bar-striped bg-danger" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
										</div>
									</div>
								</div>
							</div>
							
							<div class="progress-example card">
								<div class="card-header">
									<h4 class="card-title">Small Progress Bars</h4>
								</div>
								<div class="card-body pb-0">
									<div class="row">
										<div class="col-md-6">
											<div>
												<div class="progress progress-sm">
													<div class="progress-bar" role="progressbar" style="width: 10%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
												</div>
												<div class="progress progress-sm">
													<div class="progress-bar bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
												</div>
												<div class="progress progress-sm">
													<div class="progress-bar bg-info" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
												</div>
												<div class="progress progress-sm">
													<div class="progress-bar bg-warning" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
												</div>
												<div class="progress progress-sm">
													<div class="progress-bar bg-danger" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
												</div>
											</div>
										</div>
										<div class="col-md-6">
											<div class="progress progress-sm">
												<div class="progress-bar progress-bar-striped" role="progressbar" style="width: 10%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
											<div class="progress progress-sm">
												<div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
											<div class="progress progress-sm">
												<div class="progress-bar progress-bar-striped bg-info" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
											<div class="progress progress-sm">
												<div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
											<div class="progress progress-sm">
												<div class="progress-bar progress-bar-striped bg-danger" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
										</div>
									</div>
								</div>
							</div>
							
							<div class="progress-example card">
								<div class="card-header">
									<h4 class="card-title">Extra Small Progress Bars</h4>
								</div>
								<div class="card-body pb-0">
									<div class="row">
										<div class="col-md-6">
											<div>
												<div class="progress progress-xs">
													<div class="progress-bar w-75" role="progressbar" style="width: 10%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
												</div>
												<div class="progress progress-xs">
													<div class="progress-bar bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
												</div>
												<div class="progress progress-xs">
													<div class="progress-bar bg-info" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
												</div>
												<div class="progress progress-xs">
													<div class="progress-bar bg-warning" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
												</div>
												<div class="progress progress-xs">
													<div class="progress-bar bg-danger" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
												</div>
											</div>
										</div>
										<div class="col-md-6">
											<div class="progress progress-xs">
												<div class="progress-bar progress-bar-striped" role="progressbar" style="width: 10%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
											<div class="progress progress-xs">
												<div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
											<div class="progress progress-xs">
												<div class="progress-bar progress-bar-striped bg-info" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
											<div class="progress progress-xs">
												<div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
											<div class="progress progress-xs">
												<div class="progress-bar progress-bar-striped bg-danger" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
						<!-- /Progress -->
						
						<!-- Tabs -->
						<tab></tab>
						<!-- /Tabs -->

						<!-- Typography -->
						<typography></typography>
						<!-- /Typography -->
					</div>	

				</div>

			</div>		
			<!-- /Page Content -->

        <layout-footer></layout-footer>

    </div>
	
</template>