<template>

    <div class="main-wrapper">

        <layout-header></layout-header>

        <!-- Breadcrumb -->
			<breadcrumb6></breadcrumb6>
			<!-- /Breadcrumb -->
			
			<!-- Page Content -->
			<div class="content" style="min-height: 282.2px !important;">
				<div class="container-fluid">
					<div class="row">
						<div class="col-12">
							<h5>Blank Page</h5>
						</div>
					</div>
				</div>

			</div>		
			<!-- /Page Content -->

        <layout-footer></layout-footer>

    </div>
	
</template>

<script>
export default {
    data() {
        return {
        }
    }
}
</script>