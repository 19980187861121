<template>
<div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar dct-dashbd-lft">
						<div class="stickyside">
							<!-- Profile Widget -->
							<div class="card widget-profile pat-widget-profile">
								<div class="card-body">
									<div class="pro-widget-content">
										<div class="profile-info-widget">
											<a href="javascript:void(0);" class="booking-doc-img">
												<img src="../../../../../assets/img/patients/patient.jpg" alt="User Image">
											</a>
											<div class="profile-det-info">
												<h3>David Pope</h3>
												
												<div class="patient-details">
													<h5><b>Patient ID :</b> PT0016</h5>
													<h5 class="mb-0"><i class="fas fa-map-marker-alt"></i> Newyork, United States</h5>
												</div>
											</div>
										</div>
									</div>
									<div class="patient-info">
										<ul>
											<li>Phone <span>+1 952 001 8563</span></li>
											<li>Age <span>38 Years, Male</span></li>
											<li>Blood Group <span>AB+</span></li>
										</ul>
									</div>
								</div>
							</div>
							<!-- /Profile Widget -->
							
							<!-- Last Booking -->
							<div class="card">
								<div class="card-header">
									<h4 class="card-title">Last Booking</h4>
								</div>
								<ul class="list-group list-group-flush">
									<li class="list-group-item">
										<div class="media align-items-center d-flex">
											<div class="me-3 flex-shrink-0">
												<img alt="Image placeholder" src="../../../../../assets/img/doctors/doctor-thumb-02.jpg" class="avatar  rounded-circle">
											</div>
											<div class="media-body flex-grow-1">
												<h5 class="d-block mb-0">Dr. Eric Buckles </h5>
												<span class="d-block text-sm text-muted">Dentist</span>
												<span class="d-block text-sm text-muted">14 Nov 2019 5.00 PM</span>
											</div>
										</div>
									</li>
									<li class="list-group-item">
										<div class="media align-items-center d-flex">
											<div class="me-3 flex-shrink-0">
												<img alt="Image placeholder" src="../../../../../assets/img/doctors/doctor-thumb-02.jpg" class="avatar  rounded-circle">
											</div>
											<div class="media-body flex-grow-1">
												<h5 class="d-block mb-0">Dr. Eric Buckles </h5>
												<span class="d-block text-sm text-muted">Dentist</span>
												<span class="d-block text-sm text-muted">12 Nov 2019 11.00 AM</span>
											</div>
										</div>
									</li>
								</ul>
							</div>
							<!-- /Last Booking -->
						</div>	
						</div>
</template>