<template>

<!-- Category Section -->
<section class="select-category">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 category-col d-flex" v-for="item in category" :key="item.id">
                <div class="category-subox pb-0 d-flex flex-wrap w-100">
                    <h4>{{item.title}}</h4>
                    <p>{{item.title1}}</p>
                    <div class="subox-img">
                        <div class="subox-circle">
                            <img :src="loadImg(item.img)" alt="" width="42">
                        </div>                                    
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Category Section -->
</template>
<script>
	import category from '../../../../../assets/json/frontend/page/about/category.json'
	const images = require.context('../../../../../assets/img/icons', false, /\.png$|\.jpg$/)
	export default {
	mounted() {
	},
	methods:{
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
    },
	data() {
        return {
            category: category
        }
    }
	}
	</script>