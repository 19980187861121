<template>
<section class="comp-section">
							<div class="comp-header">
                                <h3 class="comp-title">Breadcrumbs</h3>
                                <div class="line"></div>
                            </div>
							<div class="card">
								<div class="card-body">
									<nav aria-label="breadcrumb">
										<ol class="breadcrumb">
											<li class="breadcrumb-item active" aria-current="page">Home</li>
										</ol>
									</nav>
									<nav aria-label="breadcrumb">
										<ol class="breadcrumb">
											<li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
											<li class="breadcrumb-item active" aria-current="page">Products</li>
										</ol>
									</nav>
									<nav aria-label="breadcrumb">
										<ol class="breadcrumb mb-0">
											<li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
											<li class="breadcrumb-item"><a href="javascript:void(0);">Products</a></li>
											<li class="breadcrumb-item active" aria-current="page">Accessories</li>
										</ol>
									</nav>
								</div>
							</div>
						</section>
</template>