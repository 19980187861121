<template>
<!-- Footer -->
<footer class="footer">
				
	<!-- Footer Top -->
	<div class="footer-top">
		<div class="container-fluid">
			<div class="row align-items-center">
				<div class="col-lg-3 col-md-6">
				
					<!-- Footer Widget -->
					<div class="footer-widget footer-about">
						<div class="footer-logo">
							<img src="../../../assets/img/logoFooter.png" alt="logo">
						</div>
						<div class="footer-about-content">
							<div class="social-icon">
								<ul>
									<li>
										<a href="#" target="_blank"><img src="../../../assets/img/payment1.jpg" alt=""></a>
									</li>
									<li>
										<a href="#" target="_blank"><img src="../../../assets/img/payment2.jpg" alt=""></a>
									</li>
									<li>
										<a href="#" target="_blank"><img src="../../../assets/img/payment3.jpg" alt=""></a>
									</li>
									<li>
										<a href="#" target="_blank"><img src="../../../assets/img/payment4.jpg" alt=""></a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<!--
				<div class="col-lg-3 col-md-6">
					<div class="footer-widget footer-menu">
						<h2 class="footer-title">For Patients</h2>
						<ul>
							<li><router-link to="/search">Search for Doctors</router-link></li>
							<li><router-link to="/login">Login</router-link></li>
							<li><router-link to="/register">Register</router-link></li>
							<li><router-link to="/booking">Booking</router-link></li>
							<li><router-link to="/patient-dashboard">Patient Dashboard</router-link></li>
						</ul>
					</div>
				</div>
				-->
				<!--
				<div class="col-lg-3 col-md-6">
					<div class="footer-widget footer-menu">
						<h2 class="footer-title">For Doctors</h2>
						<ul>
							<li><router-link to="/appointments">Appointments</router-link></li>
							<li><router-link to="/chat">Chat</router-link></li>
							<li><router-link to="/login">Login</router-link></li>
							<li><router-link to="/doctor-register">Register</router-link></li>
							<li><router-link to="/doctor-dashboard">Doctor Dashboard</router-link></li>
						</ul>
					</div>					
				</div>
				-->
				<!--
				<div class="col-lg-3 col-md-6">
					<div class="footer-widget footer-menu">
						<h2 class="footer-title">Sitemap</h2>
						<ul>
							<li><router-link to="/blog">Blog</router-link></li>
							<li><router-link to="/chat">FAQs</router-link></li>
							<li><router-link to="/payment">Payment</router-link></li>
							<li><router-link to="/checkout">Shipment</router-link></li>
							<li><router-link to="/privacy-policy">Return policy</router-link></li>
						</ul>
					</div>
				</div>
				-->
				
			</div>
		</div>
	</div>
	<!-- /Footer Top -->
	
	<!-- Footer Bottom -->
	<div class="footer-bottom">
		<div class="container-fluid">
		
			<!-- Copyright -->
			<div class="copyright">
				<div class="row">
					<div class="col-md-6 col-lg-6">
						<div class="copyright-text">
							<p class="mb-0">&copy; 2022 TheBuukMe. All rights reserved.</p>
						</div>
					</div>
					<div class="col-md-6 col-lg-6">
						<div class="copyright-menu">
							<ul class="policy-menu">
								<li><router-link to="/term-condition">Terms and Conditions</router-link></li>
								<li><router-link to="/privacy-policy">Policy</router-link></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<!-- /Copyright -->
			
		</div>
	</div>
	<!-- /Footer Bottom -->
	
</footer>
<!-- /Footer -->
</template>