<template>
<div class="card booking-schedule schedule-widget">
							
								<!-- Schedule Header -->
								<div class="schedule-header">
									<div class="row">
										<div class="col-md-12">
										
											<!-- Day Slot -->
											<div class="day-slot">
												<ul>
													<li class="left-arrow">
														<a href="">
															<i class="fa fa-chevron-left"></i>
														</a>
													</li>
													<li>
														<span>Mon</span>
														<span class="slot-date">11 Nov <small class="slot-year">2019</small></span>
													</li>
													<li>
														<span>Tue</span>
														<span class="slot-date">12 Nov <small class="slot-year">2019</small></span>
													</li>
													<li>
														<span>Wed</span>
														<span class="slot-date">13 Nov <small class="slot-year">2019</small></span>
													</li>
													<li>
														<span>Thu</span>
														<span class="slot-date">14 Nov <small class="slot-year">2019</small></span>
													</li>
													<li>
														<span>Fri</span>
														<span class="slot-date">15 Nov <small class="slot-year">2019</small></span>
													</li>
													<li>
														<span>Sat</span>
														<span class="slot-date">16 Nov <small class="slot-year">2019</small></span>
													</li>
													<li>
														<span>Sun</span>
														<span class="slot-date">17 Nov <small class="slot-year">2019</small></span>
													</li>
													<li class="right-arrow">
														<a href="">
															<i class="fa fa-chevron-right"></i>
														</a>
													</li>
												</ul>
											</div>
											<!-- /Day Slot -->
											
										</div>
									</div>
								</div>
								<!-- /Schedule Header -->
								
								<!-- Schedule Content -->
								<div class="schedule-cont">
									<div class="row">
										<div class="col-md-12">
										
											<!-- Time Slot -->
											<div class="time-slot">
												<ul class="clearfix">
													<li>
														<a class="timing" href="javascript:void(0);">
															<span>9:00</span> <span>AM</span>
														</a>
														<a class="timing" href="javascript:void(0);">
															<span>10:00</span> <span>AM</span>
														</a>
														<a class="timing" href="javascript:void(0);">
															<span>11:00</span> <span>AM</span>
														</a>
													</li>
													<li>
														<a class="timing" href="javascript:void(0);">
															<span>9:00</span> <span>AM</span>
														</a>
														<a class="timing" href="javascript:void(0);">
															<span>10:00</span> <span>AM</span>
														</a>
														<a class="timing" href="javascript:void(0);">
															<span>11:00</span> <span>AM</span>
														</a>
													</li>
													<li>
														<a class="timing" href="javascript:void(0);">
															<span>9:00</span> <span>AM</span>
														</a>
														<a class="timing" href="javascript:void(0);">
															<span>10:00</span> <span>AM</span>
														</a>
														<a class="timing" href="javascript:void(0);">
															<span>11:00</span> <span>AM</span>
														</a>
													</li>
													<li>
														<a class="timing" href="javascript:void(0);">
															<span>9:00</span> <span>AM</span>
														</a>
														<a class="timing" href="javascript:void(0);">
															<span>10:00</span> <span>AM</span>
														</a>
														<a class="timing" href="javascript:void(0);">
															<span>11:00</span> <span>AM</span>
														</a>
													</li>
													<li>
														<a class="timing" href="javascript:void(0);">
															<span>9:00</span> <span>AM</span>
														</a>
														<a class="timing selected" href="javascript:void(0);">
															<span>10:00</span> <span>AM</span>
														</a>
														<a class="timing" href="javascript:void(0);">
															<span>11:00</span> <span>AM</span>
														</a>
													</li>
													<li>
														<a class="timing" href="javascript:void(0);">
															<span>9:00</span> <span>AM</span>
														</a>
														<a class="timing" href="javascript:void(0);">
															<span>10:00</span> <span>AM</span>
														</a>
														<a class="timing" href="javascript:void(0);">
															<span>11:00</span> <span>AM</span>
														</a>
													</li>
													<li>
														<a class="timing" href="javascript:void(0);">
															<span>9:00</span> <span>AM</span>
														</a>
														<a class="timing" href="javascript:void(0);">
															<span>10:00</span> <span>AM</span>
														</a>
														<a class="timing" href="javascript:void(0);">
															<span>11:00</span> <span>AM</span>
														</a>
													</li>
												</ul>
											</div>
											<!-- /Time Slot -->
											
										</div>
									</div>
								</div>
								<!-- /Schedule Content -->
								
							</div>
</template>