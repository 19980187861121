<template>
	<div class="main-wrapper">
		
        <layout-adminheader></layout-adminheader>
		
        <layout-adminfooter></layout-adminfooter>
        
        <!-- Page Wrapper -->
        <div class="page-wrapper">
            <div class="content container-fluid">
            
                <!-- Page Header -->
                <breadcrumb54></breadcrumb54>
                <!-- /Page Header -->
                
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="table-responsive">
                                    <div class="table-responsive">
                                    <table class="datatable table table-hover table-center mb-0" id="patientlist">
                                        <thead>
                                            <tr>
                                                <th>Client ID</th>
                                                <th>Client Name</th>
                                                <th>Age</th>
                                                <th>Address</th>
                                                <th>Phone</th>
                                                <th>Last Visit</th>
                                                <th class="text-end">Paid</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in patientlist" :key="item.id">
                                                <td>{{item.patientid}}</td>
                                                <td>
                                                    <h2 class="table-avatar">
                                                        <router-link to="/admin/profile" class="avatar avatar-sm me-2"><img class="avatar-img rounded-circle" :src="loadImg(item.image)" alt="User Image"></router-link>
                                                        <router-link to="/admin/profile">{{item.patientname}} </router-link>
                                                    </h2>
                                                </td>
                                                <td>{{item.age}}</td>
													<td>{{item.address}}</td>
													<td>{{item.phno}}</td>
													<td>{{item.lastvisit}}</td>
													<td class="text-end">{{item.paid}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>			
                </div>
                
            </div>			
        </div>
        <!-- /Page Wrapper -->
		
	</div>
</template>
<script>
    import patientlist from '../../../../../assets/json/admin/Patientlist/patientlist.json'
    import util from '../../../../../assets/utils/util'
    const images = require.context('../../../../../assets/admin_img/patients', false, /\.png$|\.jpg$/)
    export default {
        data() {
		return {
			patientlist: patientlist
		}
	},
    mounted() {
        util.datatable('#patientlist')
    },
methods:{
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
    }
    }
</script>