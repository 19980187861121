<template>
	<div class="main-wrapper">
		
        <layout-adminheader></layout-adminheader>
		
        <layout-adminfooter></layout-adminfooter>
        
        <!-- Page Wrapper -->
        <div class="page-wrapper">
            <div class="content container-fluid">
            
                <!-- Page Header -->
                <breadcrumb50></breadcrumb50>
                <!-- /Page Header -->
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="table-responsive">
                                    <table class="datatable table table-hover table-center mb-0" id="specialities">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Specialities</th>
                                                <th class="text-end">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in specialities" :key="item.id">
                                                <td>{{item.SNO}}</td>
                                                
                                                <td>
                                                    <h2 class="table-avatar">
                                                        <router-link to="/admin/profile" class="avatar avatar-sm me-2">
                                                            <img class="avatar-img" :src="loadImg(item.image)" alt="Speciality">
                                                        </router-link>
                                                        <router-link to="/admin/profile">{{item.speciality}}</router-link>
                                                    </h2>
                                                </td>
                                            
                                                <td class="text-end">
                                                    <div class="actions">
                                                        <a class="btn btn-sm bg-success-light me-1" data-bs-toggle="modal" href="#edit_specialities_details">
                                                            <i class="fe fe-pencil"></i> Edit
                                                        </a>
                                                        <a  data-bs-toggle="modal" href="#delete_modal" class="btn btn-sm bg-danger-light">
                                                            <i class="fe fe-trash"></i> Delete
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>			
                </div>
            </div>			
        </div>
        <!-- /Page Wrapper -->
        
        <specialitymodal></specialitymodal>
		
	</div>
</template>
<script>
    import specialities from '../../../../../assets/json/admin/speciality/specialities.json'
    import util from '../../../../../assets/utils/util'
    const images = require.context('../../../../../assets/admin_img/specialities', false, /\.png$|\.jpg$/)
    export default {
        data() {
		return {
			specialities: specialities
		}
	},
    mounted() {  
        util.datatable('#specialities')
    },
methods:{
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
    }
    }
</script>