<template>
	<div class="main-wrapper">
		
        <layout-adminheader></layout-adminheader>
		
        <layout-adminfooter></layout-adminfooter>
        
        <!-- Page Wrapper -->
        <div class="page-wrapper">
            <div class="content container-fluid">
            
                <!-- Page Header -->
               <breadcrumb40></breadcrumb40>
                <!-- /Page Header -->
                
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card">
                            <basicinput></basicinput>
                        </div>
                        <div class="card">
                            <inputsize></inputsize>
                        </div>
                    </div>
                </div>
            
            </div>			
        </div>
        <!-- /Main Wrapper -->
		
	</div>
</template>