<template>
<section class="comp-section">
							<div class="comp-header">
                                <h3 class="comp-title">Avatar</h3>
                                <div class="line"></div>
                            </div>
							<div class="row">
								<div class="col-md-12">
									<div class="card">
										<div class="card-header">
											<h4 class="card-title">Sizing</h4>
										</div>
										<div class="card-body">
											<div class="avatar avatar-xxl">
												<img class="avatar-img rounded-circle" alt="User Image" src="../../../../../assets/img/patients/patient8.jpg">
											</div>
											<div class="avatar avatar-xl ms-1">
												<img class="avatar-img rounded-circle" alt="User Image" src="../../../../../assets/img/patients/patient8.jpg">
											</div>
											<div class="avatar avatar-lg ms-1">
												<img class="avatar-img rounded-circle" alt="User Image" src="../../../../../assets/img/patients/patient8.jpg">
											</div>
											<div class="avatar ms-1">
												<img class="avatar-img rounded-circle" alt="User Image" src="../../../../../assets/img/patients/patient8.jpg">
											</div>
											<div class="avatar avatar-sm ms-1">
												<img class="avatar-img rounded-circle" alt="User Image" src="../../../../../assets/img/patients/patient8.jpg">
											</div>
											<div class="avatar avatar-xs ms-1">
												<img class="avatar-img rounded-circle" alt="User Image" src="../../../../../assets/img/patients/patient8.jpg">
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-12">
									<div class="card">
										<div class="card-header">
											<h4 class="card-title">Avatar With Status</h4>
										</div>
										<div class="card-body">
											<div class="avatar avatar-online">
												<img class="avatar-img rounded-circle" alt="User Image" src="../../../../../assets/img/patients/patient8.jpg">
											</div>
											<div class="avatar avatar-offline ms-1">
												<img class="avatar-img rounded-circle" alt="User Image" src="../../../../../assets/img/patients/patient8.jpg">
											</div>
											<div class="avatar avatar-away ms-1">
												<img class="avatar-img rounded-circle" alt="User Image" src="../../../../../assets/img/patients/patient8.jpg">
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-12">
									<div class="card">
										<div class="card-header">
											<h4 class="card-title">Shape</h4>
										</div>
										<div class="card-body">
											<div class="avatar">
												<img class="avatar-img rounded" alt="User Image" src="../../../../../assets/img/patients/patient8.jpg">
											</div>
											<div class="avatar ms-1">
												<img class="avatar-img rounded-circle" alt="User Image" src="../../../../../assets/img/patients/patient8.jpg">
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-12">
									<div class="card">
										<div class="card-header">
											<h4 class="card-title">Group</h4>
										</div>
										<div class="card-body">
											<div class="avatar-group">
												<div class="avatar">
													<img class="avatar-img rounded-circle border border-white" alt="User Image" src="../../../../../assets/img/patients/patient8.jpg">
												</div>
												<div class="avatar">
													<img class="avatar-img rounded-circle border border-white" alt="User Image" src="../../../../../assets/img/patients/patient2.jpg">
												</div>
												<div class="avatar">
													<img class="avatar-img rounded-circle border border-white" alt="User Image" src="../../../../../assets/img/patients/patient3.jpg">
												</div>
												<div class="avatar">
													<span class="avatar-title rounded-circle border border-white">CF</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
</template>