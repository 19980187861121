import { initializeApp } from 'firebase/app'
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCD-Gp1Pr8zUQxCVyZmU79huuaM130MV_4",
  authDomain: "thebuukme.firebaseapp.com",
  projectId: "thebuukme",
  storageBucket: "thebuukme.appspot.com",
  messagingSenderId: "584852574783",
  appId: "1:584852574783:web:86a1a86cbb8d0a8aca5e8a",
  measurementId: "G-09H5E12EV6",
  //databaseURL: "https://thebuukme-default-rtdb.firebaseio.com/",
};

const fireBase = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(fireBase);

export{
  db,fireBase
}
