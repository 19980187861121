<template>

    <div class="main-wrapper">

        <layout-patientheader></layout-patientheader>

        <!-- Breadcrumb -->
			<div class="breadcrumb-bar">
				<div class="container-fluid">
					<div class="row align-items-center">
						<div class="col-md-12 col-12">
							<nav aria-label="breadcrumb" class="page-breadcrumb">
								<ol class="breadcrumb">
									<li class="breadcrumb-item"><router-link to="/index">Home</router-link></li>
									<li class="breadcrumb-item active" aria-current="page">Favourites</li>
								</ol>
							</nav>
							<h2 class="breadcrumb-title">Favourites</h2>
						</div>
					</div>
				</div>
			</div>
			<!-- /Breadcrumb -->
			
			<!-- Page Content -->
			<div class="content">
				<div class="container-fluid">
					<div class="row">
						<layout-patientsidebar></layout-patientsidebar>
						<div class="col-md-7 col-lg-8 col-xl-9">
							<div class="row row-grid">
								<div class="col-md-6 col-lg-4 col-xl-3" v-for="item in favourites" :key="item.id">
									<div class="profile-widget">
										<div class="doc-img">
											<router-link to="/doctor-profile">
												<img class="img-fluid" alt="User Image" :src="loadImg(item.img)">
                                            </router-link>
											<a href="javascript:void(0)" class="fav-btn">
												<i class="far fa-bookmark"></i>
											</a>
										</div>
										<div class="pro-content">
											<h3 class="title">
												<router-link to="/doctor-profile">{{item.name}}</router-link> 
												<i class="fas fa-check-circle verified ms-2"></i>
											</h3>
											<p class="speciality">{{item.description}}</p>
											<div class="rating mapgridrating">
												<i class="fas fa-star filled"></i>
												<i class="fas fa-star filled"></i>
												<i class="fas fa-star filled"></i>
												<i class="fas fa-star filled"></i>
												<i class="fas fa-star filled"></i>
												<span class="d-inline-block average-rating">{{item.rating}}</span>
											</div>
											<ul class="available-info">
												<li>
													<i class="fas fa-map-marker-alt"></i> {{item.city}}
												</li>
												<li>
													<i class="far fa-clock"></i> {{item.date}}
												</li>
												<li>
													<i class="far fa-money-bill-alt"></i> {{item.rate}} <i class="fas fa-info-circle" data-bs-toggle="tooltip" title="Lorem Ipsum"></i>
												</li>
											</ul>
											<div class="row row-sm">
												<div class="col-6">
													<router-link to="/doctor-profile" class="btn view-btn">View Profile</router-link>
												</div>
												<div class="col-6">
													<router-link to="/booking" class="btn book-btn">Book Now</router-link>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>		
			<!-- /Page Content -->

        <layout-footer></layout-footer>

    </div>
	
</template>
<script>
	import favourites from '../../../../../assets/json/frontend/patient/favourites.json'
	const images = require.context('../../../../../assets/img/doctors', false, /\.png$|\.jpg$/)
	export default {
	mounted() {
	},
	methods:{
        loadImg(imgPath) {
            return images('./' + imgPath).default
        }
    },
	data() {
        return {
            favourites: favourites
        }
    }
	}
	</script>