<template>
	<div class="main-wrapper">
		
        <layout-adminheader></layout-adminheader>
		
        <layout-adminfooter></layout-adminfooter>
        
        <!-- Page Wrapper -->
        <div class="page-wrapper">
            <div class="content container-fluid">
            
                <!-- Page Header -->
                <breadcrumb35></breadcrumb35>
                <!-- /Page Header -->
                <div class="row">
                    <div class="col-md-12">
                    
                        <!-- Recent Orders -->
                        <div class="card">
                            <div class="card-body">
                                <div class="table-responsive">
                                    <table class="datatable table table-hover table-center mb-0" id="appointment">
                                        <thead>
                                            <tr>
                                                <th>Professional Name</th>
                                                <th>Speciality</th>
                                                <th>Client Name</th>
                                                <th>Apointment Time</th>
                                                <th>Status</th>
                                                <th class="text-end">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in appointment" :key="item.id">
                                                <td>
                                                    <h2 class="table-avatar">
                                                        <router-link to="/admin/profile" class="avatar avatar-sm me-2"><img class="avatar-img rounded-circle" :src="loadImg(item.image, 1)" alt="User Image"></router-link>
                                                        <router-link to="/admin/profile">{{item.name}}</router-link>
                                                    </h2>
                                                </td>
                                                <td>{{item.speciality}}</td>
                                                <td>
                                                    <h2 class="table-avatar">
                                                        <router-link to="/admin/profile" class="avatar avatar-sm me-2"><img class="avatar-img rounded-circle" :src="loadImg(item.image1, 2)" alt="User Image"></router-link>
                                                        <router-link to="/admin/profile">{{item.patient_name}}</router-link>
                                                    </h2>
                                                </td>
                                                <td>{{item.date}}<span class="text-primary d-block">{{item.Time}}</span></td>
                                                <td>
                                                    <div class="status-toggle">
                                                        <input type="checkbox" id="status_1" class="check" checked>
                                                        <label for="status_1" class="checktoggle">checkbox</label>
                                                    </div>
                                                </td>
                                                <td class="text-end">
                                                    {{item.amount}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <!-- /Recent Orders -->
                        
                    </div>
                </div>
            </div>			
        </div>
        <!-- /Page Wrapper -->
		
	</div>
</template>
<script>
    import appointment from '../../../../assets/json/admin/appointmentlist/appointment.json'
    import util from '../../../../assets/utils/util'
    const images = require.context('../../../../assets/admin_img/patients', false, /\.png$|\.jpg$/)
	const doctorsimages = require.context('../../../../assets/admin_img/doctors', false, /\.png$|\.jpg$/)
    export default {
        data() {
		return {
			appointment: appointment
		}
	},
    mounted() {
        util.datatable('#appointment')
    },
methods:{
        loadImg(imgPath, value) {
			if(value == 2) {
				return images('./' + imgPath).default
			} else {
				return doctorsimages('./' + imgPath).default
			}
            
        },
    }
    }
</script>