<template>
<div class="card-header">
                                <h4 class="card-title">Input Sizes</h4>
                            </div>
                            <div class="card-body">
                                <form action="#">
                                    <div class="form-group row">
                                        <label class="col-form-label col-md-2">Large Input</label>
                                        <div class="col-md-10">
                                            <input type="text" class="form-control form-control-lg" placeholder=".form-control-lg">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-form-label col-md-2">Default Input</label>
                                        <div class="col-md-10">
                                            <input type="text" class="form-control" placeholder=".form-control">
                                        </div>
                                    </div>
                                    <div class="form-group mb-0 row">
                                        <label class="col-form-label col-md-2">Small Input</label>
                                        <div class="col-md-10">
                                            <input type="text" class="form-control form-control-sm" placeholder=".form-control-sm">
                                        </div>
                                    </div>
                                </form>
                            </div>
</template>