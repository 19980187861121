<template>
	<div class="main-wrapper">
		
        <layout-adminheader></layout-adminheader>
        <layout-adminfooter></layout-adminfooter>
        
        <!-- Page Wrapper -->
        <div class="page-wrapper">
            <div class="content container-fluid">
            
                <!-- Page Header -->
                <breadcrumb39></breadcrumb39>
                <!-- /Page Header -->
                
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="table-responsive">
                                    <table class="datatable table table-hover table-center mb-0" id="doctorlist">
                                        <thead>
                                            <tr>
                                                <th>Professional Name</th>
                                                <th>Speciality</th>
                                                <th>Member Since</th>
                                                <th>Earned</th>
                                                <th>Account Status</th>
                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in doctorlist" :key="item.id">
                                                <td>
                                                    <h2 class="table-avatar">
                                                        <router-link to="/admin/profile" class="avatar avatar-sm me-2"><img class="avatar-img rounded-circle" :src="loadImg(item.image)" alt="User Image"></router-link>
                                                        <router-link to="/admin/profile">{{item.name}}</router-link>
                                                    </h2>
                                                </td>
                                                <td>{{item.speciality}}</td>
                                                
                                                <td>{{item.date}} <br><small>{{item.time}}</small></td>
                                                
                                                <td>{{item.earned}}</td>
                                                
                                                <td>
                                                    <div class="status-toggle">
                                                        <input type="checkbox" id="status_1" class="check" checked>
                                                        <label for="status_1" class="checktoggle">checkbox</label>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>			
                </div>
                
            </div>			
        </div>
        <!-- /Page Wrapper -->
		
	</div>
</template>
<script>
    import doctorlist from '../../../../assets/json/admin/Doctorlist/doctorlist.json'
    import util from '../../../../assets/utils/util'
    const images = require.context('../../../../assets/admin_img/doctors', false, /\.png$|\.jpg$/)
    export default {
        data() {
		return {
			doctorlist: doctorlist
		}
	},
    mounted() {
        util.datatable('#doctorlist')
    },
    methods:{
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
    }
    }
</script>