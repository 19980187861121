<template>
<div class="col-lg-4 col-md-12 sidebar-right theiaStickySidebar">
<div class="stickyside">
							<!-- Search -->
							<div class="card search-widget">
								<div class="card-body">
									<form class="search-form">
										<div class="input-group">
											<input type="text" placeholder="Search..." class="form-control">
											<button type="submit" class="btn btn-primary"><i class="fa fa-search"></i></button>
										</div>
									</form>
								</div>
							</div>
							<!-- /Search -->

							<!-- Latest Posts -->
							<div class="card post-widget">
								<div class="card-header">
									<h4 class="card-title">Latest Posts</h4>
								</div>
								<div class="card-body">
									<ul class="latest-posts">
										<li>
											<div class="post-thumb">
												<router-link to="/blog-details">
													<img class="img-fluid" src="../../../../assets/img/blog/blog-thumb-01.jpg" alt="">
                                                </router-link>
											</div>
											<div class="post-info">
												<h4>
													<router-link to="/blog-details">Doccure – Making your clinic painless visit?</router-link>
												</h4>
												<p>4 Dec 2019</p>
											</div>
										</li>
										<li>
											<div class="post-thumb">
												<router-link to="/blog-details">
													<img class="img-fluid" src="../../../../assets/img/blog/blog-thumb-02.jpg" alt="">
                                                </router-link>
											</div>
											<div class="post-info">
												<h4>
													<router-link to="/blog-details">What are the benefits of Online Doctor Booking?</router-link>
												</h4>
												<p>3 Dec 2019</p>
											</div>
										</li>
										<li>
											<div class="post-thumb">
												<router-link to="/blog-details">
													<img class="img-fluid" src="../../../../assets/img/blog/blog-thumb-03.jpg" alt="">
                                                </router-link>
											</div>
											<div class="post-info">
												<h4>
													<router-link to="/blog-details">Benefits of consulting with an Online Doctor</router-link>
												</h4>
												<p>3 Dec 2019</p>
											</div>
										</li>
										<li>
											<div class="post-thumb">
												<router-link to="/blog-details">
													<img class="img-fluid" src="../../../../assets/img/blog/blog-thumb-04.jpg" alt="">
                                                </router-link>
											</div>
											<div class="post-info">
												<h4>
													<router-link to="/blog-details">5 Great reasons to use an Online Doctor</router-link>
												</h4>
												<p>2 Dec 2019</p>
											</div>
										</li>
										<li>
											<div class="post-thumb">
												<router-link to="/blog-details">
													<img class="img-fluid" src="../../../../assets/img/blog/blog-thumb-05.jpg" alt="">
                                                </router-link>
											</div>
											<div class="post-info">
												<h4>
													<router-link to="/blog-details">Online Doctor Appointment Scheduling</router-link>
												</h4>
												<p>1 Dec 2019</p>
											</div>
										</li>
									</ul>
								</div>
							</div>
							<!-- /Latest Posts -->

							<!-- Categories -->
							<div class="card category-widget">
								<div class="card-header">
									<h4 class="card-title">Blog Categories</h4>
								</div>
								<div class="card-body">
									<ul class="categories">
										<li><a href="javascript:void(0);">Cardiology <span>(62)</span></a></li>
										<li><a href="javascript:void(0);">Health Care <span>(27)</span></a></li>
										<li><a href="javascript:void(0);">Nutritions <span>(41)</span></a></li>
										<li><a href="javascript:void(0);">Health Tips <span>(16)</span></a></li>
										<li><a href="javascript:void(0);">Medical Research <span>(55)</span></a></li>
										<li><a href="javascript:void(0);">Health Treatment <span>(07)</span></a></li>
									</ul>
								</div>
							</div>
							<!-- /Categories -->

							<!-- Tags -->
							<div class="card tags-widget">
								<div class="card-header">
									<h4 class="card-title">Tags</h4>
								</div>
								<div class="card-body">
									<ul class="tags">
										<li><a href="#" class="tag">Children</a></li>
										<li><a href="#" class="tag">Disease</a></li>
										<li><a href="#" class="tag">Appointment</a></li>
										<li><a href="#" class="tag">Booking</a></li>
										<li><a href="#" class="tag">Kids</a></li>
										<li><a href="#" class="tag">Health</a></li>
										<li><a href="#" class="tag">Family</a></li>
										<li><a href="#" class="tag">Tips</a></li>
										<li><a href="#" class="tag">Shedule</a></li>
										<li><a href="#" class="tag">Treatment</a></li>
										<li><a href="#" class="tag">Dr</a></li>
										<li><a href="#" class="tag">Clinic</a></li>
										<li><a href="#" class="tag">Online</a></li>
										<li><a href="#" class="tag">Health Care</a></li>
										<li><a href="#" class="tag">Consulting</a></li>
										<li><a href="#" class="tag">Doctors</a></li>
										<li><a href="#" class="tag">Neurology</a></li>
										<li><a href="#" class="tag">Dentists</a></li>
										<li><a href="#" class="tag">Specialist</a></li>
										<li><a href="#" class="tag">Doccure</a></li>
									</ul>
								</div>
							</div>
							<!-- /Tags -->
							
						</div>
</div>
</template>