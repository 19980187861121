import { createApp } from 'vue'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { fireBase } from '@/firebase'
import VueGeolocation from 'vue-browser-geolocation';

import './assets/css/bootstrap.min.css'
import './assets/js/bootstrap.bundle.min.js'
import './assets/fontawesome/css/fontawesome.min.css'
import './assets/fontawesome/css/all.min.css'
import './assets/css/feather.css' 

import LayoutHeader from './views/pages/frontend/Header'
import LayoutFooter from './views/pages/frontend/Footer'
import Doctorheader from './views/pages/frontend/doctor/header'
import Patientheader from './views/pages/frontend/patient/header'
import Patientsidebar from './views/pages/frontend/patient/sidebar'
import Doctorsidebar from './views/pages/frontend/doctor/sidebar'
import Checkoutsidebar from './views/pages/frontend/patient/checkoutsidebar'
import Adminheader from './views/pages/admin/header'
import Adminsidebar from './views/pages/admin/sidebar'
import Loader from './views/pages/loader'
/***********Home ***********/
import aboutus from './views/pages/frontend/dashboard/aboutus'
import blog from './views/pages/frontend/dashboard/blog'
import chooseus from './views/pages/frontend/dashboard/chooseus'
import feature from './views/pages/frontend/dashboard/feature'
import homebanner from './views/pages/frontend/dashboard/homebanner'
import popular from './views/pages/frontend/dashboard/popular'
import search from './views/pages/frontend/dashboard/search'
import testimonial from './views/pages/frontend/dashboard/testimonial'
/**********Pages **********/
import abouttestimonial from './views/pages/frontend/pages/about/testimonial'
import clinic from './views/pages/frontend/pages/about/clinic'
import category from './views/pages/frontend/pages/about/category'
import calendarmodal from './views/pages/frontend/pages/calendar/calendarmodal'
import calendarsidebar from './views/pages/frontend/pages/calendar/calendarsidebar'
import Typography from './views/pages/frontend/pages/component/typography'
import Tab from './views/pages/frontend/pages/component/tab'
import Alert from './views/pages/frontend/pages/component/alert'
import Button from './views/pages/frontend/pages/component/button'
import Card from './views/pages/frontend/pages/component/card'
import Dropdown from './views/pages/frontend/pages/component/dropdown'
import Pagination from './views/pages/frontend/pages/component/pagination'
import Avatar from './views/pages/frontend/pages/component/avatar'
import Contactform from './views/pages/frontend/pages/contact/contactform'
import Pagecontent from './views/pages/frontend/pages/contact/pagecontent'
import Invoiceitem from './views/pages/frontend/pages/invoice/invoiceitem'
import Videocallheader from './views/pages/frontend/pages/call/videocallheader'
import Voicecallheader from './views/pages/frontend/pages/call/voicecallheader'
/*********Doctor **********/
import billingsidebar from './views/pages/frontend/doctor/billing/billingsidebar'
import addprescriptiontable from './views/pages/frontend/doctor/billing/Addprescription/addprescriptiontable'
import addbillingtable from './views/pages/frontend/doctor/billing/Addbilling/addbillingtable'
import Doctorchatleft from './views/pages/frontend/doctor/chat/chatleft'
import Doctorchatright from './views/pages/frontend/doctor/chat/chatright'
import Today from './views/pages/frontend/doctor/dashboard/today'
import Upcoming from './views/pages/frontend/doctor/dashboard/upcoming'
import Award from './views/pages/frontend/doctor/profilesetting/award'
import Education from './views/pages/frontend/doctor/profilesetting/education'
import Pricing from './views/pages/frontend/doctor/profilesetting/pricing'
import Clinic1 from './views/pages/frontend/doctor/profilesetting/clinic1'
import Basic from './views/pages/frontend/doctor/profilesetting/basic'
import Schedulemodal from './views/pages/frontend/patient/schedule/schedulemodal'
import Scheduleheader from './views/pages/frontend/patient/schedule/scheduleheader'
import Schedulecontent from './views/pages/frontend/patient/schedule/schedulecontent'
import Reviewcontent from './views/pages/frontend/doctor/review/reviewcontent'
import Reviewcontent1 from './views/pages/frontend/doctor/review/reviewcontent1'
import Reviewcontent2 from './views/pages/frontend/doctor/review/reviewcontent2'
import Profilesidebar from './views/pages/frontend/doctor/profile/profilesidebar'
import Proileappointment from './views/pages/frontend/doctor/profile/appointments'
import Profileprescription from './views/pages/frontend/doctor/profile/prescription'
import Medicalprofile from './views/pages/frontend/doctor/profile/medicalprofile'
import Billingprofile from './views/pages/frontend/doctor/profile/billingprofile'
/*********Patient *********/
import Bookschedule from './views/pages/frontend/patient/booking/bookschedule'
import Chatleft from './views/pages/frontend/patient/chat/chatleft'
import Chatright from './views/pages/frontend/patient/chat/chatright'
import Personalinformation from './views/pages/frontend/patient/checkout/personalinformation'
import Paymentmethod from './views/pages/frontend/patient/checkout/paymentmethod'
import business from './views/pages/frontend/patient/profile/business'
import review from './views/pages/frontend/patient/profile/review'
import location from './views/pages/frontend/patient/profile/location'
import overview from './views/pages/frontend/patient/profile/overview'
import doctorwidget from './views/pages/frontend/patient/profile/doctorwidget'
import modalprofile from './views/pages/frontend/patient/profile/modalprofile'
import Searchsidebar from './views/pages/frontend/patient/search/searchdoctor'
import Billingdash from './views/pages/frontend/patient/dashboard/index'
import Medicaldash from './views/pages/frontend/patient/dashboard/medicaldash'
import Prescriptiondash from './views/pages/frontend/patient/dashboard/prescriptiondash'
import Appointmentdash from './views/pages/frontend/patient/dashboard/appointmentdash'
import Gridcontent from './views/pages/frontend/patient/map/grid/gridcontent'
/********Blog ***********/
import Blogsidebar from './views/pages/frontend/blog/blogsidebar'
import Blogdetailcontent from './views/pages/frontend/blog/blogdetail/blogdetailcontent'
import Bloglistcontent from './views/pages/frontend/blog/bloglist/bloglist'
/*********Breacrumb ********/
import breadcrumb1 from './views/pages/frontend/breadcrumb/about'
import breadcrumb2 from './views/pages/frontend/breadcrumb/addbilling'
import breadcrumb3 from './views/pages/frontend/breadcrumb/addprescription'
import breadcrumb4 from './views/pages/frontend/breadcrumb/appointmentdetail'
import breadcrumb5 from './views/pages/frontend/breadcrumb/appointment'
import breadcrumb6 from './views/pages/frontend/breadcrumb/blankpage'
import breadcrumb7 from './views/pages/frontend/breadcrumb/blogdetail'
import breadcrumb8 from './views/pages/frontend/breadcrumb/bloggrid'
import breadcrumb9 from './views/pages/frontend/breadcrumb/bloglist'
import breadcrumb10 from './views/pages/frontend/breadcrumb/booking'
import breadcrumb11 from './views/pages/frontend/breadcrumb/bookingsucces'
import breadcrumb12 from './views/pages/frontend/breadcrumb/calendar'
import breadcrumb13 from './views/pages/frontend/breadcrumb/changepassword'
import breadcrumb14 from './views/pages/frontend/breadcrumb/checkout'
import breadcrumb15 from './views/pages/frontend/breadcrumb/component'
import breadcrumb16 from './views/pages/frontend/breadcrumb/contactus'
import breadcrumb17 from './views/pages/frontend/breadcrumb/doctorchangepassword'
import breadcrumb18 from './views/pages/frontend/breadcrumb/doctordashboard'
import breadcrumb19 from './views/pages/frontend/patient/profile/doctorprofile'
import breadcrumb20 from './views/pages/frontend/doctor/profilesetting/doctorprofilesetting'
import breadcrumb21 from './views/pages/frontend/doctor/billing/Editbilling/editbilling'
import breadcrumb22 from './views/pages/frontend/doctor/billing/Editprescription/editprescription'
import breadcrumb23 from './views/pages/frontend/pages/invoice/invoices'
import breadcrumb24 from './views/pages/frontend/pages/invoice/invoiceview'
import breadcrumb25 from './views/pages/frontend/pages/termcondition'
import breadcrumb26 from './views/pages/frontend/breadcrumb/searchdoctor'
import breadcrumb27 from './views/pages/frontend/breadcrumb/scheduletiming'
import breadcrumb28 from './views/pages/frontend/breadcrumb/review'
import breadcrumb29 from './views/pages/frontend/breadcrumb/profilesetting'
import breadcrumb30 from './views/pages/frontend/breadcrumb/privacypolicy'
import breadcrumb31 from './views/pages/frontend/breadcrumb/patientprofile'
import breadcrumb32 from './views/pages/frontend/breadcrumb/patientdashboard'
import breadcrumb33 from './views/pages/frontend/breadcrumb/mypatient'
/********admin breadcrumb **********/
import breadcrumb35 from './views/pages/admin/breadcrumb/appointment'
import breadcrumb36 from './views/pages/admin/breadcrumb/blankpage'
import breadcrumb37 from './views/pages/admin/breadcrumb/component'
import breadcrumb38 from './views/pages/admin/breadcrumb/datatable'
import breadcrumb39 from './views/pages/admin/breadcrumb/doctorlist'
import breadcrumb40 from './views/pages/admin/breadcrumb/formbasic'
import breadcrumb41 from './views/pages/admin/breadcrumb/formhorizontal'
import breadcrumb42 from './views/pages/admin/breadcrumb/inputgroups'
import breadcrumb43 from './views/pages/admin/breadcrumb/formmask'
import breadcrumb44 from './views/pages/admin/breadcrumb/formvalidation'
import breadcrumb45 from './views/pages/admin/breadcrumb/formvertical'
import breadcrumb46 from './views/pages/admin/main/Dashboard/index'
import breadcrumb47 from './views/pages/admin/breadcrumb/invoicereport'
import breadcrumb48 from './views/pages/admin/breadcrumb/transactionlist'
import breadcrumb49 from './views/pages/admin/breadcrumb/tablesbasic'
import breadcrumb50 from './views/pages/admin/breadcrumb/specialities'
import breadcrumb51 from './views/pages/admin/breadcrumb/settings'
import breadcrumb52 from './views/pages/admin/breadcrumb/review'
import breadcrumb53 from './views/pages/admin/breadcrumb/profile'
import breadcrumb54 from './views/pages/admin/breadcrumb/patientlist'
/*********admin page *************/
import Adminalert from './views/pages/admin/uiinterface/components/alert'
import Adminavatar from './views/pages/admin/uiinterface/components/avatar'
import Adminbutton from './views/pages/admin/uiinterface/components/button'
import Admincard from './views/pages/admin/uiinterface/components/card'
import Admindropdown from './views/pages/admin/uiinterface/components/dropdown'
import Adminpagination from './views/pages/admin/uiinterface/components/pagination'
import Admintab from './views/pages/admin/uiinterface/components/tab'
import Admintypography from './views/pages/admin/uiinterface/components/typography'
import Inputsize from './views/pages/admin/uiinterface/form/basic/inputsize'
import Basicinput from './views/pages/admin/uiinterface/form/basic/basicinput'
import Horizontal2 from './views/pages/admin/uiinterface/form/horizontal/horizontal2'
import Horizontal1 from './views/pages/admin/uiinterface/form/horizontal/horizontal1'
import Addressform from './views/pages/admin/uiinterface/form/horizontal/addressform'
import Basicform from './views/pages/admin/uiinterface/form/horizontal/basicform'
import Buttondrop from './views/pages/admin/uiinterface/form/inputgroup/buttondrop'
import Checkbox from './views/pages/admin/uiinterface/form/inputgroup/checkbox'
import Basiceg from './views/pages/admin/uiinterface/form/inputgroup/basiceg'
import Tooltip from './views/pages/admin/uiinterface/form/validation/tooltip'
import Supported from './views/pages/admin/uiinterface/form/validation/supported'
import Server from './views/pages/admin/uiinterface/form/validation/server'
import Default from './views/pages/admin/uiinterface/form/validation/default'
import Custom from './views/pages/admin/uiinterface/form/validation/custom'
import Vertical2 from './views/pages/admin/uiinterface/form/vertical/vertical2'
import Vertical1 from './views/pages/admin/uiinterface/form/vertical/vertical1'
import Addressvertical from './views/pages/admin/uiinterface/form/vertical/addressvertical'
import Basicvertical from './views/pages/admin/uiinterface/form/vertical/basicvertical'
import Specialitymodal from './views/pages/admin/main/speciality/specialitymodal'
import Modalreview from './views/pages/admin/main/reviews/modalreview'
import Personaldetail from './views/pages/admin/page/profile/personaldetail'
import Changepasswordetail from './views/pages/admin/page/profile/changepasswordetail'
import Headerprofile from './views/pages/admin/page/profile/headerprofile'
import Reportmodal from './views/pages/admin/main/report/reportmodal'
import Invoicetable from './views/pages/admin/main/report/invoicetable'
import Invoicecontent from './views/pages/admin/main/report/invoicecontent'
import Invoicedue from './views/pages/admin/main/report/invoicedue'

import VueSelect from 'vue3-select2-component'
import DatePicker from 'vue3-datepicker'
import jquery from 'jquery';
window.$ = window.jQuery = jquery;

if(window.location.href.includes("cosmetics/admin")) {
  require('./assets/css/feathericon.min.css');
  require('./assets/css/adminstyle.css');
  require('./assets/css/bootstrap4.min.css')
  require('./assets/css/dataTables.bootstrap4.min.css')
  require('./assets/css/jquery.dataTables.min.js')
  require('./assets/css/dataTables.bootstrap4.min.js')
}else {
  require('./assets/js/fancybox/jquery.fancybox.min.css');
  require('./assets/js/fancybox/jquery.fancybox.min.js');
  require('./assets/js/daterangepicker/daterangepicker.css');
  require('./assets/js/daterangepicker/daterangepicker.js');
  require('./assets/js/slick.js');
  require('./assets/css/style.css');
}
import Raphael from 'raphael/raphael'
global.Raphael = Raphael

const app = createApp(App)
/*Global Components */
app.component('layout-header', LayoutHeader);
app.component('layout-footer', LayoutFooter);
app.component('layout-doctorheader', Doctorheader);
app.component('layout-patientheader', Patientheader);
app.component('layout-patientsidebar', Patientsidebar);
app.component('layout-doctorsidebar', Doctorsidebar);
app.component('layout-checkoutsidebar', Checkoutsidebar);
app.component('layout-adminheader', Adminheader);
app.component('layout-adminfooter', Adminsidebar);
/********home *********/
app.component('aboutus', aboutus);
app.component('blog', blog);
app.component('chooseus', chooseus);
app.component('feature', feature);
app.component('homebanner', homebanner);
app.component('popular', popular);
app.component('search', search);
app.component('testimonial', testimonial);
/********Page *********/
app.component('abouttestimonial', abouttestimonial);
app.component('clinic', clinic);
app.component('category', category);
app.component('calendarmodal', calendarmodal);
app.component('calendarsidebar', calendarsidebar);
app.component('typography', Typography);
app.component('tab', Tab);
app.component('alert', Alert);
app.component('button', Button);
app.component('card', Card);
app.component('dropdown', Dropdown);
app.component('pagination', Pagination);
app.component('avatar', Avatar);
app.component('contactform', Contactform);
app.component('pagecontent', Pagecontent);
app.component('invoiceitem', Invoiceitem);
app.component('videocallheader', Videocallheader);
app.component('voicecallheader', Voicecallheader);
/********doctor *********/
app.component('billingsidebar', billingsidebar);
app.component('addprescriptiontable', addprescriptiontable);
app.component('addbillingtable', addbillingtable);
app.component('doctorchatleft', Doctorchatleft);
app.component('doctorchatright', Doctorchatright);
app.component('today', Today);
app.component('upcoming', Upcoming);
app.component('award', Award);
app.component('education', Education);
app.component('priing', Pricing);
app.component('clinic1', Clinic1);
app.component('basic', Basic);
app.component('schedulemodal', Schedulemodal);
app.component('scheduleheader', Scheduleheader);
app.component('schedulecontent', Schedulecontent);
app.component('reviewcontent', Reviewcontent);
app.component('reviewcontent1', Reviewcontent1);
app.component('reviewcontent2', Reviewcontent2);
app.component('profilesidebar', Profilesidebar);
app.component('profileappointment', Proileappointment);
app.component('profileprescription', Profileprescription);
app.component('medicalprofile', Medicalprofile);
app.component('billingprofile', Billingprofile);

/********blog ************/
app.component('blogsidebar', Blogsidebar);
app.component('blogdetailcontent', Blogdetailcontent);
app.component('blogcontent', Bloglistcontent);
/********patient *********/
app.component('bookschedule', Bookschedule);
app.component('chatleft', Chatleft);
app.component('chatright', Chatright);
app.component('personalinformation', Personalinformation);
app.component('paymentmethod', Paymentmethod);
app.component('business', business);
app.component('review', review);
app.component('location', location);
app.component('overview', overview);
app.component('doctorwidget', doctorwidget);
app.component('modalprofile', modalprofile);
app.component('searchsidebar', Searchsidebar);
app.component('billingdash', Billingdash);
app.component('medicaldash', Medicaldash);
app.component('prescriptiondash', Prescriptiondash);
app.component('appointmentdash', Appointmentdash);
app.component('gridcontent', Gridcontent);
/*******Breadcrumb *****/
app.component('breadcrumb1', breadcrumb1);
app.component('breadcrumb2', breadcrumb2);
app.component('breadcrumb3', breadcrumb3);
app.component('breadcrumb4', breadcrumb4);
app.component('breadcrumb5', breadcrumb5);
app.component('breadcrumb6', breadcrumb6);
app.component('breadcrumb7', breadcrumb7);
app.component('breadcrumb8', breadcrumb8);
app.component('breadcrumb9', breadcrumb9);
app.component('breadcrumb10', breadcrumb10);
app.component('breadcrumb11', breadcrumb11);
app.component('breadcrumb12', breadcrumb12);
app.component('breadcrumb13', breadcrumb13);
app.component('breadcrumb14', breadcrumb14);
app.component('breadcrumb15', breadcrumb15);
app.component('breadcrumb16', breadcrumb16);
app.component('breadcrumb17', breadcrumb17);
app.component('breadcrumb18', breadcrumb18);
app.component('breadcrumb19', breadcrumb19);
app.component('breadcrumb20', breadcrumb20);
app.component('breadcrumb21', breadcrumb21);
app.component('breadcrumb22', breadcrumb22);
app.component('breadcrumb23', breadcrumb23);
app.component('breadcrumb24', breadcrumb24);
app.component('breadcrumb25', breadcrumb25);
app.component('breadcrumb26', breadcrumb26);
app.component('breadcrumb27', breadcrumb27);
app.component('breadcrumb28', breadcrumb28);
app.component('breadcrumb29', breadcrumb29);
app.component('breadcrumb30', breadcrumb30);
app.component('breadcrumb31', breadcrumb31);
app.component('breadcrumb32', breadcrumb32);
app.component('breadcrumb33', breadcrumb33);
app.component('breadcrumb35', breadcrumb35);
app.component('breadcrumb36', breadcrumb36);
app.component('breadcrumb37', breadcrumb37);
app.component('breadcrumb38', breadcrumb38);
app.component('breadcrumb39', breadcrumb39);
app.component('breadcrumb40', breadcrumb40);
app.component('breadcrumb41', breadcrumb41);
app.component('breadcrumb42', breadcrumb42);
app.component('breadcrumb43', breadcrumb43);
app.component('breadcrumb44', breadcrumb44);
app.component('breadcrumb45', breadcrumb45);
app.component('breadcrumb46', breadcrumb46);
app.component('breadcrumb47', breadcrumb47);
app.component('breadcrumb48', breadcrumb48);
app.component('breadcrumb49', breadcrumb49);
app.component('breadcrumb50', breadcrumb50);
app.component('breadcrumb51', breadcrumb51);
app.component('breadcrumb52', breadcrumb52);
app.component('breadcrumb53', breadcrumb53);
app.component('breadcrumb54', breadcrumb54);
/************Admin page**********/
app.component('adminalert', Adminalert);
app.component('adminavatar', Adminavatar);
app.component('adminbutton', Adminbutton);
app.component('admincard', Admincard);
app.component('admindropdown', Admindropdown);
app.component('adminpagination', Adminpagination);
app.component('admintab', Admintab);
app.component('admintypography', Admintypography);
app.component('inputsize', Inputsize);
app.component('basicinput', Basicinput);
app.component('horizontal2', Horizontal2);
app.component('horizontal1', Horizontal1);
app.component('addressform', Addressform);
app.component('basicform', Basicform);
app.component('buttondrop', Buttondrop);
app.component('checkbox', Checkbox);
app.component('basiceg', Basiceg);
app.component('tooltip', Tooltip);
app.component('supported', Supported);
app.component('server', Server);
app.component('default', Default);
app.component('custom', Custom);
app.component('vertical2', Vertical2);
app.component('vertical1', Vertical1);
app.component('addressvertical', Addressvertical);
app.component('basicvertical', Basicvertical);
app.component('specialitymodal', Specialitymodal);
app.component('modalreview', Modalreview);
app.component('personaldetail', Personaldetail);
app.component('changepasswordetail', Changepasswordetail);
app.component('headerprofile', Headerprofile);
app.component('reportmodal', Reportmodal);
app.component('invoicetable', Invoicetable);
app.component('invoicecontent', Invoicecontent);
app.component('invoicedue', Invoicedue);

/*******Global functions *******/
app.component('vue-select', VueSelect);
app.component('datepicker', DatePicker);
app.component('loader', Loader);
app.use(router)
.mount('#app');
//createApp(App).use(router).mount('#app')
