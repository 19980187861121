<template>
<table class="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>ITEM</th>
                                    <th class="d-none d-sm-table-cell">DESCRIPTION</th>
                                    <th class="text-nowrap">UNIT COST</th>
                                    <th>QTY</th>
                                    <th>TOTAL</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>medicine1</td>
                                    <td class="d-none d-sm-table-cell">Lorem ipsum dolor sit amet, consectetur adipiscing elit</td>
                                    <td>$10</td>
                                    <td>2</td>
                                    <td>$10</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>medicine2</td>
                                    <td class="d-none d-sm-table-cell">Lorem ipsum dolor sit amet, consectetur adipiscing elit</td>
                                    <td>$10</td>
                                    <td>1</td>
                                    <td>$10</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>medicine3</td>
                                    <td class="d-none d-sm-table-cell">Lorem ipsum dolor sit amet, consectetur adipiscing elit</td>
                                    <td>$90</td>
                                    <td>1</td>
                                    <td>$90</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>medicine4</td>
                                    <td class="d-none d-sm-table-cell">Lorem ipsum dolor sit amet, consectetur adipiscing elit</td>
                                    <td>$70</td>
                                    <td>1</td>
                                    <td>$70</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>medicine5</td>
                                    <td class="d-none d-sm-table-cell">Lorem ipsum dolor sit amet, consectetur adipiscing elit</td>
                                    <td>70</td>
                                    <td>1</td>
                                    <td>$70</td>
                                </tr>
                            </tbody>
                        </table>
</template>