<template>
    <div class="main-wrapper">

        <layout-doctorheader></layout-doctorheader>

        <!-- Breadcrumb -->
			<breadcrumb33></breadcrumb33>
			<!-- /Breadcrumb -->
			
			<!-- Page Content -->
			<div class="content">
				<div class="container-fluid">

					<div class="row">

						<layout-doctorsidebar></layout-doctorsidebar>

						<div class="col-md-7 col-lg-8 col-xl-9">
						
							<div class="row row-grid">
								<div class="col-md-6 col-lg-4 col-xl-3" v-for="item in mypatient" :key="item.id">
									<div class="card widget-profile pat-widget-profile">
										<div class="card-body">
											<div class="pro-widget-content">
												<div class="profile-info-widget">
													<router-link to="/patient-profile" class="booking-doc-img">
														<img :src="loadImg(item.img)" alt="User Image">
                                                    </router-link>
													<div class="profile-det-info">
														<h3><router-link to="/patient-profile">{{item.name}}</router-link></h3>
														
														<div class="patient-details">
															<h5><b>{{item.patientno}}</b> {{item.invoiceno}}</h5>
															<h5 class="mb-0"><i class="fas fa-map-marker-alt"></i> {{item.city}}</h5>
														</div>
													</div>
												</div>
											</div>
											<div class="patient-info">
												<ul>
													<li>{{item.detail}} <span>{{item.detail1}}</span></li>
													<li>{{item.detail2}} <span>{{item.detail3}}</span></li>
													<li>{{item.detail4}} <span>{{item.detail5}}</span></li>
												</ul>
											</div>
										</div>
									</div>
								</div>
								
							</div>

						</div>
					</div>

				</div>

			</div>		
			<!-- /Page Content -->

        <layout-footer></layout-footer>

    </div>
</template>
<script>
	import mypatient from '../../../../../assets/json/frontend/doctor/mypatient.json'
	const images = require.context('../../../../../assets/img/patients', false, /\.png$|\.jpg$/)
	export default {
	mounted() {
	},
	methods:{
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
    },
	data() {
        return {
            mypatient: mypatient
        }
    }
	}
	</script>