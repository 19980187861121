<template>
	<div class="main-wrapper">
		
        <layout-adminheader></layout-adminheader>
		
        <layout-adminfooter></layout-adminfooter>
        
        <!-- Page Wrapper -->
        <div class="page-wrapper">
            <div class="content container-fluid">
            
                <!-- Page Header -->
                <breadcrumb52></breadcrumb52>
                <!-- /Page Header -->
                
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="table-responsive">
                                    <table class="datatable table table-hover table-center mb-0" id="review">
                                        <thead>
                                            <tr>
                                                <th>Patient Name</th>
                                                <th>Doctor Name</th>
                                                <th>Ratings</th>
                                                <th>Description</th>
                                                <th>Date</th>
                                                <th class="text-end">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in reviews" :key="item.id">
                                                <td>
                                                    <h2 class="table-avatar">
                                                        <router-link to="/admin/profile" class="avatar avatar-sm me-2"><img class="avatar-img rounded-circle" :src="loadImg(item.image, 1)" alt="User Image"></router-link>
                                                        <router-link to="/admin/profile">{{item.patientname}} </router-link>
                                                    </h2>
                                                </td>
                                                <td>
                                                    <h2 class="table-avatar">
                                                        <router-link to="/admin/profile" class="avatar avatar-sm me-2"><img class="avatar-img rounded-circle" :src="loadImg(item.doctorimage, 2)" alt="User Image"></router-link>
                                                        <router-link to="/admin/profile">{{item.doctorname}}</router-link>
                                                    </h2>
                                                </td>
                                                
                                                <td class="table-rating">
                                                    <i class="fe fe-star text-warning"></i>
                                                    <i class="fe fe-star text-warning"></i>
                                                    <i class="fe fe-star text-warning"></i>
                                                    <i class="fe fe-star text-warning"></i>
                                                    <i class="fe fe-star-o text-secondary"></i>
                                                </td>
                                                
                                                <td>
                                                    {{item.description}}
                                                </td>
                                                <td>{{item.date}} <br><small>{{item.time}}</small></td>
                                                <td class="text-end">
                                                    <div class="actions">
                                                        <a class="btn btn-sm bg-danger-light" data-bs-toggle="modal" href="#delete_modal">
                                                            <i class="fe fe-trash"></i> Delete
                                                        </a>
                                                        
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>			
                </div>
                
            </div>			
        </div>
        <!-- /Page Wrapper -->
        
        <!-- Delete Modal -->
        <modalreview></modalreview>
        <!-- /Delete Modal -->
		
	</div>
</template>
<script>
    import reviews from '../../../../../assets/json/admin/Reviews/reviews.json'
    import util from '../../../../../assets/utils/util'
    const images = require.context('../../../../../assets/admin_img/patients', false, /\.png$|\.jpg$/)
    const doctorsimages = require.context('../../../../../assets/admin_img/doctors', false, /\.png$|\.jpg$/)
    export default {
        data() {
		return {
			reviews: reviews
		}
	},
    mounted() {  
        util.datatable('#review')
    },
    methods:{
        loadImg(imgPath, value) {
            if(value == 1) {
                return images('./' + imgPath).default
            } else {
                return doctorsimages('./' + imgPath).default
            }
            
        },
    }
    }
</script>