<template>

    <div>

    <div class="main-wrapper">

        <layout-doctorheader></layout-doctorheader>

        <!-- Breadcrumb -->
			<breadcrumb27></breadcrumb27>
			<!-- /Breadcrumb -->
			
			<!-- Page Content -->
			<div class="content">
				<div class="container-fluid">

					<div class="row">

						<layout-doctorsidebar></layout-doctorsidebar>
						
						<div class="col-md-7 col-lg-8 col-xl-9">
						 
							<div class="row">
								<div class="col-sm-12">
									<div class="card">
										<div class="card-body">
											<h4 class="card-title">Schedule Timings</h4>
											<div class="profile-box">
												<div class="row">

													<div class="col-lg-4">
														<div class="form-group">               
															<label>Timing Slot Duration</label>
															<select class="form-select form-control">
																<option>-</option>
																<option>15 mins</option>
																<option selected="selected">30 mins</option>  
																<option>45 mins</option>
																<option>1 Hour</option>
															</select>
														</div>
													</div>

												</div>     
												<div class="row">
													<div class="col-md-12">
														<div class="card schedule-widget mb-0">
														
															<!-- Schedule Header -->
															<scheduleheader></scheduleheader>
															<!-- /Schedule Header -->
															
															<!-- Schedule Content -->
															<schedulecontent></schedulecontent>
															<!-- /Schedule Content -->
															
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
								
						</div>
					</div>

				</div>

			</div>		
			<!-- /Page Content -->

        <layout-footer></layout-footer>

    </div>

    <schedulemodal></schedulemodal>

</div>
	
</template>