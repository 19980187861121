<template>

    <div class="main-wrapper">

        <layout-patientheader></layout-patientheader>

        	<!-- Page Content -->
			<div class="content">
				<div class="container-fluid">
				
					<!-- Call Wrapper -->
					<div class="call-wrapper">
						<div class="call-main-row">
							<div class="call-main-wrapper">
								<div class="call-view">
									<div class="call-window">
									
										<videocallheader></videocallheader>
										
										<!-- Call Footer -->
										<div class="call-footer">
											<div class="call-icons">
												<!-- <span class="call-duration">00:59</span> -->
												<div class="toggle-voice-video">
													<label class="switch"><input type="checkbox" id="togBtn"><div class="slider round"></div></label>
												</div>
												<ul class="call-items">
													<li class="call-item">
														<a href="" title="Mute Audio" data-placement="top" data-bs-toggle="tooltip">
															<i class="fa fa-microphone microphone"></i>
														</a>
													</li>
													<li class="end-call-new">
														<a href="javascript:void(0);" title="End Call" data-placement="top" data-bs-toggle="tooltip">
															<i class="material-icons">call_end</i>
														</a>
													</li>													
												</ul>
												<!-- <div class="end-call">
													<a href="javascript:void(0);">
														<i class="material-icons">call_end</i>
													</a>
												</div> -->
											</div>
										</div>
										<!-- /Call Footer -->
										
									</div>
								</div>
								
							</div>
						</div>
					</div>
					<!-- /Call Wrapper -->
					
				</div>

			</div>		
			<!-- /Page Content -->

    </div>
	
</template>

<script>
export default {
    data() {
        return {
        }
    }
}
</script>