<template>
	<div class="main-wrapper">
		
        <layout-adminheader></layout-adminheader>
		
        <layout-adminfooter></layout-adminfooter>
        
        <!-- Page Wrapper -->
        <div class="page-wrapper">
            <div class="content container-fluid">
            
                <!-- Page Header -->
                <breadcrumb47></breadcrumb47>
                <!-- /Page Header -->
                
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="table-responsive">
                                    <table class="datatable table table-hover table-center mb-0" id="invoicereport">
                                        <thead>
                                            <tr>
                                                <th>Invoice Number</th>
                                                <th>Patient ID</th>
                                                <th>Patient Name</th>
                                                <th>Total Amount</th>
                                                <th>Created Date</th>
                                                <th class="text-center">Status</th>
                                                <th class="text-end">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in invoicereport" :key="item.id">
                                                <td><router-link to="/admin/invoice">{{item.invoicenumber}}</router-link></td>
                                                <td>{{item.patientid}}</td>
                                                <td>
                                                    <h2 class="table-avatar">
                                                        <router-link to="/admin/profile" class="avatar avatar-sm me-2"><img class="avatar-img rounded-circle" :src="loadImg(item.image)" alt="User Image"></router-link>
                                                        <router-link to="/admin/profile">{{item.patientname}} </router-link>
                                                    </h2>
                                                </td>
                                                <td>{{item.totalamount}}</td>
                                                <td>{{item.createddate}}</td>
                                                <td class="text-center">
                                                    <span class="badge rounded-pill bg-success inv-badge">Paid</span>
                                                </td>
                                                <td class="text-end">
                                                    <div class="actions">
                                                        
                                                        <a data-bs-toggle="modal" href="#edit_invoice_report" class="btn btn-sm bg-success-light me-2">
                                                            <i class="fe fe-pencil"></i> Edit
                                                        </a>
                                                        <a class="btn btn-sm bg-danger-light" data-bs-toggle="modal" href="#delete_modal">
                                                            <i class="fe fe-trash"></i> Delete
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>			
                </div>
                
            </div>			
        </div>
        <!-- /Page Wrapper -->
        
        <reportmodal></reportmodal>
		
	</div>
</template>
<script>
    import invoicereport from '../../../../../assets/json/admin/Invoicereport/invoicereport.json'
    import util from '../../../../../assets/utils/util'
    const images = require.context('../../../../../assets/admin_img/patients', false, /\.png$|\.jpg$/)
    export default {
        data() {
		return {
			invoicereport: invoicereport
		}
	},
    mounted() {
        util.datatable('#invoicereport')
},
methods:{
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
    }
    }
</script>