<template>
<!-- Billing Item -->
									<div class="card card-table">
										<div class="card-body">
											<div class="table-responsive">
												<table class="table table-hover table-center">
													<thead>
														<tr>													
															<th style="min-width:200px;">Title</th>					
															<th style="min-width:200px;">Amount</th>		
															<th style="width:80px;"></th>
														</tr>
													</thead>
													<tbody>
														<tr>								
															<td>	
																<input type="text" class="form-control">
															</td>
															<td>
																<input type="text" class="form-control">
															</td>							
															<td>
																<a href="javascript:void(0);" class="btn bg-danger-light trash"><i class="far fa-trash-alt"></i></a>															
															</td>
														</tr>
														<tr>								
															<td>	
																<input type="text" class="form-control">
															</td>
															<td>
																<input type="text" class="form-control">
															</td>							
															<td>
																<a href="javascript:void(0);" class="btn bg-danger-light trash"><i class="far fa-trash-alt"></i></a>															
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
									<!-- /Billing Item -->
</template>