<template>
	<div class="main-wrapper">
		
        <layout-adminheader></layout-adminheader>
		
        <layout-adminfooter></layout-adminfooter>
        
        <!-- Page Wrapper -->
        <div class="page-wrapper">
            <div class="content container-fluid">
            
                <!-- Page Header -->
                <breadcrumb44></breadcrumb44>
                <!-- /Page Header -->
                
                <!-- Row -->
                <div class="row">
                    <div class="col-sm-12">
                    
                        <!-- Custom Boostrap Validation -->
                        <custom></custom>
                        <!-- /Custom Boostrap Validation -->
                        
                        <!-- Default Browser Validation -->
                        <default></default>
                        <!-- /Default Browser Validation -->

                        <!-- Server Side Validation -->
                        <server></server>
                        <!-- /Server Side Validation -->
                        
                        <!-- Supported Elements -->
                        <supported></supported>
                        <!-- /Supported Elements -->
                        
                        <!-- Validation Tooltips -->
                        <tooltip></tooltip>
                        <!-- /Validation Tooltips -->
                        
                    </div>
                </div>
                <!-- /Row -->
            
            </div>			
        </div>
        <!-- /Page Wrapper -->
		
	</div>
</template>