<template>

    <div class="main-wrapper">

        <layout-header></layout-header>

        <!-- Breadcrumb -->
		<breadcrumb14></breadcrumb14>
		<!-- /Breadcrumb -->
			
			<!-- Page Content -->
			<div class="content">
				<div class="container">

					<div class="row">
						<div class="col-md-7 col-lg-8">
							<div class="card">
								<div class="card-body">
								
									<!-- Checkout Form -->
									<form>
									
										<!-- Personal Information -->
										<personalinformation></personalinformation>
										<!-- /Personal Information -->
										
										<div class="payment-widget">
											<h4 class="card-title">Payment Method</h4>
											
											<paymentmethod></paymentmethod>
											
											<!-- Terms Accept -->
											<div class="terms-accept">
												<div class="custom-checkbox">
												   <input type="checkbox" id="terms_accept">
												   <label for="terms_accept" class="ms-1">I have read and accept <a href="javascript:void(0);">Terms &amp; Conditions</a></label>
												</div>
											</div>
											<!-- /Terms Accept -->
											
											<!-- Submit Section -->
                                            <router-link to="/booking-success">
											<div class="submit-section mt-4">
												<button type="submit" class="btn btn-primary submit-btn">Confirm and Pay</button>
											</div>
                                        </router-link>
											<!-- /Submit Section -->
											
										</div>
									</form>
									<!-- /Checkout Form -->
									
								</div>
							</div>
							
						</div>
						
						<layout-checkoutsidebar></layout-checkoutsidebar>

					</div>

				</div>

			</div>		
			<!-- /Page Content -->

        <layout-footer></layout-footer>

    </div>
	
</template>