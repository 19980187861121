<template>
<!-- Comment List -->
<li>
    <div class="comment">
        <img class="avatar rounded-circle" alt="User Image" src="../../../../../assets/img/patients/patient.jpg">
        <div class="comment-body">
            <div class="meta-data">
                <span class="comment-author">David Pope</span>
                <span class="comment-date">Reviewed 2 Days ago</span>
                <div class="review-count rating">
                    <i class="fas fa-star filled"></i>
                    <i class="fas fa-star filled"></i>
                    <i class="fas fa-star filled"></i>
                    <i class="fas fa-star filled"></i>
                    <i class="fas fa-star"></i>
                </div>
            </div>
            <p class="recommended"><i class="far fa-thumbs-up"></i> I recommend the doctor</p>
            <p class="comment-content">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation.
                Curabitur non nulla sit amet nisl tempus
            </p>
            <div class="comment-reply">
                <a class="comment-btn" href="javascript:void(0);">
                    <i class="fas fa-reply"></i> Reply
                </a>
               <p class="recommend-btn">
                <span>Recommend?</span>
                <a href="javascript:void(0);" class="like-btn">
                    <i class="far fa-thumbs-up"></i> Yes
                </a>
                <a href="javascript:void(0);" class="dislike-btn">
                    <i class="far fa-thumbs-down"></i> No
                </a>
            </p>
            </div>
        </div>
    </div>
    
    <!-- Comment Reply -->
    <ul class="comments-reply">
    
        <!-- Comment Reply List -->
        <li>
            <div class="comment">
                <img class="avatar rounded-circle" alt="User Image" src="../../../../../assets/img/doctors/doctor-thumb-02.jpg">
                <div class="comment-body">
                    <div class="meta-data">
                        <span class="comment-author">Dr. Eric Buckles</span>
                        <span class="comment-date">Reviewed 3 Days ago</span>
                    </div>
                    <p class="comment-content">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        Ut enim ad minim veniam.
                        Curabitur non nulla sit amet nisl tempus
                    </p>
                    <div class="comment-reply">
                        <a class="comment-btn" href="javascript:void(0);">
                            <i class="fas fa-reply"></i> Reply
                        </a>
                    </div>
                </div>
            </div>
        </li>
        <!-- /Comment Reply List -->
        
    </ul>
    <!-- /Comment Reply -->
    
</li>
<!-- /Comment List -->

<!-- Comment List -->
<li>
    <div class="comment">
        <img class="avatar rounded-circle" alt="User Image" src="../../../../../assets/img/patients/patient2.jpg">
        <div class="comment-body">
            <div class="meta-data">
                <span class="comment-author">Joshua Miller</span>
                <span class="comment-date">Reviewed 4 Days ago</span>
                <div class="review-count rating">
                    <i class="fas fa-star filled"></i>
                    <i class="fas fa-star filled"></i>
                    <i class="fas fa-star filled"></i>
                    <i class="fas fa-star filled"></i>
                    <i class="fas fa-star filled"></i>
                </div>
            </div>
            <p class="comment-content">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation.
                Curabitur non nulla sit amet nisl tempus
            </p>
            <div class="comment-reply">
                <a class="comment-btn" href="javascript:void(0);">
                    <i class="fas fa-reply"></i> Reply
                </a>
                <p class="recommend-btn">
                    <span>Recommend?</span>
                    <a href="javascript:void(0);" class="like-btn">
                        <i class="far fa-thumbs-up"></i> Yes
                    </a>
                    <a href="javascript:void(0);" class="dislike-btn">
                        <i class="far fa-thumbs-down"></i> No
                    </a>
                </p>
            </div>
        </div>
    </div>
</li>
<!-- /Comment List -->
</template>