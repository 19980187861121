<template>
	<!-- News Letter -->
			<section class="news-letter">
				<div class="container">
					<div class="row">
						<div class="col-12">
							<div class="news-letter-col">
								<div class="section-title pb-2">
									<h2>Grab Our Newsletter</h2>
									<p>To receive latest offers and discounts from the shop.</p>
								</div>
								<form>
									<div class="d-flex align-items-center">
										<div>
											<input type="email" name="email" placeholder="Enter Your Email Address">
										</div>
										<div>
											<input type="submit" value="subscribe">
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- /News Letter -->
</template>