<template>
<div class="row invoice-payment">
                            <div class="col-sm-7">
                            </div>
                            <div class="col-sm-5">
                                <div class="m-b-20">
                                    <h6 class="my-3">Total due</h6>
                                    <div class="table-responsive no-border">
                                        <table class="table mb-0">
                                            <tbody>
                                                <tr>
                                                    <th>Subtotal:</th>
                                                    <td class="text-end">$250</td>
                                                </tr>
                                                <tr>
                                                    <th>Tax: <span class="text-regular">(25%)</span></th>
                                                    <td class="text-end">$50</td>
                                                </tr>
                                                <tr>
                                                    <th>Total:</th>
                                                    <td class="text-end text-primary"><h5>$200</h5></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
</template>