<template>
	<div class="main-wrapper">
		
        <layout-adminheader></layout-adminheader>
		
        <layout-adminfooter></layout-adminfooter>
        
        <!-- Page Wrapper -->
        <div class="page-wrapper">
			
            <div class="content container-fluid">

                <!-- Page Header -->
                <breadcrumb45></breadcrumb45>
                <!-- /Page Header -->

                <div class="row">
                    <div class="col-md-6">
                        <basicvertical></basicvertical>
                    </div>
                    <div class="col-md-6">
                        <addressvertical></addressvertical>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Two Column Vertical Form</h4>
                            </div>
                            <vertical1></vertical1>
                        </div>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Two Column Vertical Form</h4>
                            </div>
                            <vertical2></vertical2>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <!-- /Page Wrapper -->
		
	</div>
</template>