<template>
<section class="contact-form">
				<div class="container">
					<div class="section-header text-center">
						<h2>Get in touch!</h2>
					</div>
					<div class="row">
						<div class="col-md-12">
							<form action="">
								<div class="row">
									<div class="col-md-6"> 
										<div class="form-group">
											<label>Your Name <span>*</span></label>
											<input type="text" class="form-control">
										</div>
									</div>
									<div class="col-md-6"> 
										<div class="form-group">
											<label>Your Email <span>*</span></label>
											<input type="text" class="form-control">
										</div>
									</div>
									<div class="col-md-12"> 
										<div class="form-group">
											<label>Subject</label>
											<input type="text" class="form-control">
										</div>
									</div>
									<div class="col-md-12"> 
										<div class="form-group">
											<label>Comments <span>*</span></label>
											<textarea class="form-control">

											</textarea>
										</div>
									</div>
								</div>		
								<button class="btn bg-primary">Send Message</button>					
							</form>
						</div>
					</div>
				</div>
			</section>
</template>