<template>

    <div>

    <div class="main-wrapper">

        <layout-header></layout-header>

        <!-- Breadcrumb -->
		<breadcrumb12></breadcrumb12>
		<!-- /Breadcrumb -->
			
			<!-- Page Content -->
			<div class="content">
				<div class="container-fluid">

					<div class="row">
						
						<!-- Calendar Events -->
						<calendarsidebar></calendarsidebar>
						<!-- /Calendar Events -->
						
						<!-- Calendar -->
						<div class="col-md-7 col-lg-8 col-xl-9">
							<div class="card">
								<div class="card-body">
									<FullCalendar :options="calendarOptions" :events="events"></FullCalendar>
								</div>
							</div>
						</div>
						<!-- /Calendar -->
						
					</div>

				</div>

			</div>		
			<!-- /Page Content -->

        <layout-footer></layout-footer>

    </div>

    <calendarmodal></calendarmodal>
        
    </div>
	
</template>
<script>
    import Vue from 'vue'
    import { ref } from 'vue'
    const currentDate = ref(new Date())
    import FullCalendar from "@fullcalendar/vue3";
    import {
    CalendarOptions,
    EventApi,
    DateSelectArg,
    EventClickArg,
  } from "@fullcalendar/vue3";
  import dayGridPlugin from "@fullcalendar/daygrid";
  import timeGridPlugin from "@fullcalendar/timegrid";
  import interactionPlugin from "@fullcalendar/interaction";
    export default {
      components: {
      FullCalendar,
      },
      data() {
      return {
          startdate: currentDate,
        calendarOptions: {
          plugins: [
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin, // needed for dateClick
          ],
          headerToolbar: {
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          },
          events: [
          {
              title  : '10.02p Test Event 1',
              start  : '2020-08-29',
          },
          {
              title  : '7.55p Test Event 3',
              start  : '2020-09-02',
          },
        ],
  
          initialView: "dayGridMonth",
          editable: true,
          selectable: true,
          selectMirror: true,
          dayMaxEvents: true,
          weekends: true,
          /* you can update a remote database when these fire:
          eventAdd:
          eventChange:
          eventRemove:
          */
        },
      }
    },
    props: {
      msg: String,
    },
      mounted() {
  
      }
    }
  </Script>
  