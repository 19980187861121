<template>
<!-- Testimonial Section -->
			<section class="section section-testimonial">
				<div class="container">
					<div class="section-header text-center mb-4">
						<h2>Testimonials</h2>
						<p class="sub-title">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
					</div>
					<div class="row justify-content-center">
						<div class="col-lg-12">
							<!-- Slider -->
							<div class="testimonial-slider slider">
							
								<!-- Slider Item -->
								<div class="testimonial-item text-center" v-for="item in abouttestimonial" :key="item.id">
									<div class="testimonial-img">
										<img :src="loadImg(item.img)" class="img-fluid" alt="Speciality">
									</div>
                                    <div class="testimonial-content">                                        
                                        <p>{{item.title}}</p>
                                        <p class="user-name">{{item.title1}}</p>
                                        <p class="user-location mb-0">{{item.city}}</p>
                                    </div>
								</div>	
								<!-- /Slider Item -->
								
							</div>
							<!-- /Slider -->
							
						</div>
					</div>
				</div>   
			</section>	 
			<!-- Testimonial Section -->
</template>
<script>
	import abouttestimonial from '../../../../../assets/json/frontend/page/about/abouttestimonial.json'
	const images = require.context('../../../../../assets/img/patients', false, /\.png$|\.jpg$/)
	export default {
	mounted() {
	},
	methods:{
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
    },
	data() {
        return {
            abouttestimonial: abouttestimonial
        }
    },
	mounted() {
		if($('.testimonial-slider').length > 0) {
		$('.testimonial-slider').slick({
			dots: true,
			autoplay:false,
			infinite: true,
			prevArrow: false,
			nextArrow: false,
			slidesToShow: 3,
			slidesToScroll: 1,
			responsive: [{
				breakpoint: 992,
					settings: {
						slidesToShow: 3
				  	}
			},
			{
				breakpoint: 776,
					settings: {
						slidesToShow: 2
				  	}
			},
			{
				breakpoint: 567,
					settings: {
						slidesToShow: 1
					}
			}]
		});
	}
	}
	}
	</script>