<template>

    <div class="main-wrapper">
        <layout-header></layout-header>

            <homebanner></homebanner>

            <section class="specialities">
				<div class="container">
					<div class="section-title text-center">
						
						<h2>Find Professionals in Your Area</h2>
						<!--
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
						-->
					</div>
					<popular></popular>
				</div>   
			</section>	

			<!--
			<search></search>
			<feature></feature>
			<chooseus></chooseus>
			-->

			<blog></blog>

        <layout-footer></layout-footer>

    </div>
	
</template>

<script>
export default {
    data() {
        return {
        }
    }
}
</script>