<template>
<!-- Our Service -->
			<section class="our-service">
				<div class="container">
					<div class="row align-items-center">
						<div class="col-12 col-lg-5">
							<div class="left">
								<h2>our services range</h2>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
								<a href="#" class="btn">View All</a>
							</div>
						</div>
						<div class="col-12 col-lg-7">
							<div class="right">
								<div class="price-box">
									<ul>
										<li class="d-flex align-items-center">
											<div class="left-col">
												<h5>Makeup & Cosmetics</h5>
												<p>Lorem ipsum dolor sit dolor amet, consectetur adipiscing elit eiusmod tempor incididunt ut. labore et dolore magna. </p>
											</div>
											<div class="right-col">
												<h2>$35.00</h2>
											</div>
										</li>
										<li class="d-flex align-items-center">
											<div class="left-col">
												<h5>perfumes</h5>
												<p>Lorem ipsum dolor sit dolor amet, consectetur adipiscing elit eiusmod tempor incididunt ut. labore et dolore magna. </p>
											</div>
											<div class="right-col">
												<h2>$42.00</h2>
											</div>
										</li>
										<li class="d-flex align-items-center">
											<div class="left-col">
												<h5>Skincare</h5>
												<p>Lorem ipsum dolor sit dolor amet, consectetur adipiscing elit eiusmod tempor incididunt ut. labore et dolore magna. </p>
											</div>
											<div class="right-col">
												<h2>$18.00</h2>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- /Our Service -->
</template>