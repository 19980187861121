<template>

    <div class="about-page">

    <div class="main-wrapper">

        <layout-header></layout-header>

        <!-- Breadcrumb -->
			<breadcrumb1></breadcrumb1>
			<!-- /Breadcrumb -->
			
			<!-- Page Content -->
            <section class="about-section">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <a href="javascript:voide(0);" class="about-titile mb-4">About Doccure</a>
                            <h3 class="mb-4">Company Profile</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In porta luctus est interdum pretium. Fusce id tortor fringilla, suscipit turpis ac, varius ex. Cras vel metus ligula. Nam enim ligula, bibendum a iaculis ut, cursus id augue. Proin vitae purus id tortor vehicula scelerisque non in libero.</p>
                            <p class="mb-0">Nulla non turpis sit amet purus pharetra sollicitudin. Proin rutrum urna ut suscipit ullamcorper. Proin sapien felis, dignissim non finibus eget, luctus vel purus. Pellentesque efficitur congue orci ornare accumsan. Nullam ultrices libero vel imperdiet scelerisque. Donec vel mauris eros.</p>
                        </div>
                        <div class="col-md-6">                            
                        </div>
                    </div>
                </div>
            </section>

            <category></category>
            
            <clinic></clinic>

            <section class="section section-specialities">
				<div class="container">
					<div class="section-header text-center">
						<h2>Clinic and Specialities</h2>
						<p class="sub-title">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
					</div>
					<popular></popular>
				</div>   
			</section>	 

            <abouttestimonial></abouttestimonial>

			<!-- /Page Content -->

        <layout-footer></layout-footer>

    </div>

    </div>
	
</template>

<script>
export default {
    data() {
        return {
        }
    }
}
</script>