<template>
<!-- Call Header -->
										<div class="fixed-header">
											<div class="navbar">
												<div class="user-details me-auto">
													<div class="float-start user-img">
														<router-link class="avatar avatar-sm me-2" to="/patient-profile" title="Megan Bird">
															<img src="../../../../../assets/img/patients/patient1.jpg" alt="User Image" class="rounded-circle">
															<span class="status online"></span>
                                                        </router-link>
													</div>
													<div class="user-info float-start">
														<router-link to="/patient-profile"><span>Bernice Hood</span></router-link>
														<span class="last-seen">Online</span>
													</div>
												</div>
												<ul class="nav float-end custom-menu">
													<li class="nav-item dropdown dropdown-action">
														<a href="javascript:void(0);" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-cog"></i></a>
														<div class="dropdown-menu dropdown-menu-end">
															<a href="javascript:void(0)" class="dropdown-item">Settings</a>
														</div>
													</li>
												</ul>
											</div>
										</div>
										<!-- /Call Header -->
										
										<!-- Call Contents -->
										<div class="call-contents">
											<div class="call-content-wrap">
												<div class="voice-call-avatar">
													<img src="../../../../../assets/img/doctors/doctor-thumb-02.jpg" alt="User Image" class="call-avatar">
													<span class="username">Dr. Eric Buckles</span>
													<span class="call-timing-count">00:59</span>
												</div>
												<div class="call-users">
													<ul>
														<li>
															<a href="javascript:void(0);">
																<img src="../../../../../assets/img/patients/patient1.jpg" class="img-fluid" alt="User Image">
																<span class="call-mute"><i class="fa fa-microphone-slash"></i></span>
															</a>
														</li>
													</ul>
												</div>
											</div>
										</div>
										<!-- /Call Contents -->
</template>