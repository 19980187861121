<template>
	<div class="main-wrapper">
		
        <layout-adminheader></layout-adminheader>
		
        <layout-adminfooter></layout-adminfooter>
        
        <!-- Page Wrapper -->
        <div class="page-wrapper">
            <div class="content container-fluid">
            
                <!-- Page Header -->
                <breadcrumb37></breadcrumb37>
                <!-- /Page Header -->
                
                <div class="comp-sec-wrapper">
                
                    <!-- Avatar -->
                    <adminavatar></adminavatar>
                    <!-- /Avatar -->
                    
                    <adminalert></adminalert>
                    
                    <!-- Buttons -->
                    <section class="comp-section comp-buttons">
                        <div class="section-header">
                            <h3 class="section-title">Buttons</h3>
                            <div class="line"></div>
                        </div>
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title">Default Button</h4>
                                <button type="button" class="btn btn-primary me-1">Primary</button>
                                <button type="button" class="btn btn-secondary me-1">Secondary</button>
                                <button type="button" class="btn btn-success me-1">Success</button>
                                <button type="button" class="btn btn-danger me-1">Danger</button>
                                <button type="button" class="btn btn-warning me-1">Warning</button>
                                <button type="button" class="btn btn-info me-1">Info</button>
                                <button type="button" class="btn btn-light me-1">Light</button>
                                <button type="button" class="btn btn-dark me-1">Dark</button>
                                <button type="button" class="btn btn-link">Link</button>
                                <hr>
                                <h4 class="card-title">Button Sizes</h4>
                                <p>
                                    <button type="button" class="btn btn-primary btn-lg me-1">Primary</button>
                                    <button type="button" class="btn btn-secondary btn-lg me-1">Secondary</button>
                                    <button type="button" class="btn btn-success btn-lg me-1">Success</button>
                                    <button type="button" class="btn btn-danger btn-lg me-1">Danger</button>
                                    <button type="button" class="btn btn-warning btn-lg me-1">Warning</button>
                                    <button type="button" class="btn btn-info btn-lg me-1">Info</button>
                                    <button type="button" class="btn btn-light btn-lg me-1">Light</button>
                                    <button type="button" class="btn btn-dark btn-lg">Dark</button>
                                </p>
                                <p>
                                    <button type="button" class="btn btn-primary me-1">Primary</button>
                                    <button type="button" class="btn btn-secondary me-1">Secondary</button>
                                    <button type="button" class="btn btn-success me-1">Success</button>
                                    <button type="button" class="btn btn-danger me-1">Danger</button>
                                    <button type="button" class="btn btn-warning me-1">Warning</button>
                                    <button type="button" class="btn btn-info me-1">Info</button>
                                    <button type="button" class="btn btn-light me-1">Light</button>
                                    <button type="button" class="btn btn-dark">Dark</button>
                                </p>
                                <p>
                                    <button type="button" class="btn btn-primary btn-sm me-1">Primary</button>
                                    <button type="button" class="btn btn-secondary btn-sm me-1">Secondary</button>
                                    <button type="button" class="btn btn-success btn-sm me-1">Success</button>
                                    <button type="button" class="btn btn-danger btn-sm me-1">Danger</button>
                                    <button type="button" class="btn btn-warning btn-sm me-1">Warning</button>
                                    <button type="button" class="btn btn-info btn-sm me-1">Info</button>
                                    <button type="button" class="btn btn-light btn-sm me-1">Light</button>
                                    <button type="button" class="btn btn-dark btn-sm">Dark</button>
                                </p>
                                <hr>
                                <h4 class="card-title">Button Groups</h4>
                                <div class="btn-toolbar">
                                    <div class="btn-group btn-group-lg">
                                        <button type="button" class="btn btn-primary">Left</button>
                                        <button type="button" class="btn btn-primary">Middle</button>
                                        <button type="button" class="btn btn-primary">Right</button>
                                    </div>
                                </div>
                                <br>
                                <div class="btn-toolbar">
                                    <div class="btn-group">
                                        <button type="button" class="btn btn-primary">Left</button>
                                        <button type="button" class="btn btn-primary">Middle</button>
                                        <button type="button" class="btn btn-primary">Right</button>
                                    </div>
                                </div>
                                <br>
                                <div class="btn-toolbar">
                                    <div class="btn-group btn-group-sm">
                                        <button type="button" class="btn btn-primary">Left</button>
                                        <button type="button" class="btn btn-primary">Middle</button>
                                        <button type="button" class="btn btn-primary">Right</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <adminbutton></adminbutton>

                        <!-- Progress Button -->
                        <div class="card mb-4">
                            <div class="card-header">
                                <h4 class="card-title">Progress Button</h4>
                            </div>
                            <div class="card-body">
                                <button type="button" class="btn btn-primary me-1"><span class="spinner-border spinner-border-sm me-2" role="status"></span>Primary</button>
                                <button type="button" class="btn btn-secondary me-1"><span class="spinner-border spinner-border-sm me-2" role="status"></span>Secondary</button>
                                <button type="button" class="btn btn-success me-1"><span class="spinner-border spinner-border-sm me-2" role="status"></span>Success</button>
                                <button type="button" class="btn btn-danger me-1"><span class="spinner-border spinner-border-sm me-2" role="status"></span>Danger</button>
                                <button type="button" class="btn btn-warning me-1"><span class="spinner-border spinner-border-sm me-2" role="status"></span>Warning</button>
                                <button type="button" class="btn btn-info me-1"><span class="spinner-border spinner-border-sm me-2" role="status"></span>Info</button>
                                <button type="button" class="btn btn-dark"><span class="spinner-border spinner-border-sm me-2" role="status"></span>Dark</button>
                            </div>
                        </div>
                        <!-- /Progress Button -->
                    </section>
                    <!-- Buttons -->
                    
                    <admincard></admincard>

                    <admindropdown></admindropdown>

                    <pagination></pagination>
                    
                    <!-- Progress -->
                    <section class="comp-section">
                        <div class="section-header">
                            <h3 class="section-title">Progress</h3>
                            <div class="line"></div>
                        </div>
                        <div class="progress-example card">
                            <div class="card-header">
                                <h4 class="card-title">Large Progress Bars</h4>
                            </div>
                            <div class="card-body pb-0">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div>
                                            <div class="progress progress-lg">
                                                <div class="progress-bar" role="progressbar" style="width: 10%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <div class="progress progress-lg">
                                                <div class="progress-bar bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <div class="progress progress-lg">
                                                <div class="progress-bar bg-info" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <div class="progress progress-lg">
                                                <div class="progress-bar bg-warning" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <div class="progress progress-lg">
                                                <div class="progress-bar bg-danger" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="progress progress-lg">
                                            <div class="progress-bar progress-bar-striped" role="progressbar" style="width: 10%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div class="progress progress-lg">
                                            <div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div class="progress progress-lg">
                                            <div class="progress-bar progress-bar-striped bg-info" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div class="progress progress-lg">
                                            <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div class="progress progress-lg">
                                            <div class="progress-bar progress-bar-striped bg-danger" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="progress-example card">
                            <div class="card-header">
                                <h4 class="card-title">Default Progress Bars</h4>
                            </div>
                            <div class="card-body pb-0">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" style="width: 10%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar bg-info" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar bg-warning" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar bg-danger" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="progress">
                                            <div class="progress-bar progress-bar-striped" role="progressbar" style="width: 10%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar progress-bar-striped bg-info" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar progress-bar-striped bg-danger" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="progress-example card">
                            <div class="card-header">
                                <h4 class="card-title">Medium Progress Bars</h4>
                            </div>
                            <div class="card-body pb-0">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div>
                                            <div class="progress progress-md">
                                                <div class="progress-bar" role="progressbar" style="width: 10%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <div class="progress progress-md">
                                                <div class="progress-bar bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <div class="progress progress-md">
                                                <div class="progress-bar bg-info" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <div class="progress progress-md">
                                                <div class="progress-bar bg-warning" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <div class="progress progress-md">
                                                <div class="progress-bar bg-danger" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="progress progress-md">
                                            <div class="progress-bar progress-bar-striped" role="progressbar" style="width: 10%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div class="progress progress-md">
                                            <div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div class="progress progress-md">
                                            <div class="progress-bar progress-bar-striped bg-info" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div class="progress progress-md">
                                            <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div class="progress progress-md">
                                            <div class="progress-bar progress-bar-striped bg-danger" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="progress-example card">
                            <div class="card-header">
                                <h4 class="card-title">Small Progress Bars</h4>
                            </div>
                            <div class="card-body pb-0">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div>
                                            <div class="progress progress-sm">
                                                <div class="progress-bar" role="progressbar" style="width: 10%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <div class="progress progress-sm">
                                                <div class="progress-bar bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <div class="progress progress-sm">
                                                <div class="progress-bar bg-info" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <div class="progress progress-sm">
                                                <div class="progress-bar bg-warning" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <div class="progress progress-sm">
                                                <div class="progress-bar bg-danger" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="progress progress-sm">
                                            <div class="progress-bar progress-bar-striped" role="progressbar" style="width: 10%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div class="progress progress-sm">
                                            <div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div class="progress progress-sm">
                                            <div class="progress-bar progress-bar-striped bg-info" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div class="progress progress-sm">
                                            <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div class="progress progress-sm">
                                            <div class="progress-bar progress-bar-striped bg-danger" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="progress-example card">
                            <div class="card-header">
                                <h4 class="card-title">Extra Small Progress Bars</h4>
                            </div>
                            <div class="card-body pb-0">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div>
                                            <div class="progress progress-xs">
                                                <div class="progress-bar w-75" role="progressbar" style="width: 10%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <div class="progress progress-xs">
                                                <div class="progress-bar bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <div class="progress progress-xs">
                                                <div class="progress-bar bg-info" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <div class="progress progress-xs">
                                                <div class="progress-bar bg-warning" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <div class="progress progress-xs">
                                                <div class="progress-bar bg-danger" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="progress progress-xs">
                                            <div class="progress-bar progress-bar-striped" role="progressbar" style="width: 10%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div class="progress progress-xs">
                                            <div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div class="progress progress-xs">
                                            <div class="progress-bar progress-bar-striped bg-info" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div class="progress progress-xs">
                                            <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div class="progress progress-xs">
                                            <div class="progress-bar progress-bar-striped bg-danger" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <!-- /Progress -->
                    
                    <!-- Tabs -->
                    <admintab></admintab>
                    <!-- /Tabs -->

                    <!-- Typography -->
                    <admintypography></admintypography>
                    <!-- /Typography -->
                </div>	
            
            </div>			
        </div>
        <!-- /Page Wrapper -->
		
	</div>
</template>