<template>
  <div id="app">
    <loader></loader>
   <router-view :key="$route.fullPath"> </router-view>
  
  </div>
</template>

<script>
import { useRouter, useRoute } from 'vue-router'
import { collection, getDocs } from "firebase/firestore";
import { db } from '@/firebase';



export default {
  name: 'App',
  setup(){
    const router = useRouter();
    const route = useRoute();

  }

}
</script>


