<template>
<!-- Call Header -->
										<div class="fixed-header">
											<div class="navbar">
												<div class="user-details">
													<div class="float-start user-img">
														<router-link class="avatar avatar-sm me-2" to="patient-profile" title="Megan Bird">
															<img src="../../../../../assets/img/patients/patient1.jpg" alt="User Image" class="rounded-circle">
															<span class="status online"></span>
                                                        </router-link>
													</div>
													<div class="user-info float-start">
														<router-link  to="patient-profile"><span>Bernice Hood</span></router-link>
														<span class="last-seen">Online</span>
													</div>
												</div>
												<span class="call-duration float-end">00:59</span>
											</div>
										</div>
										<!-- /Call Header -->
										
										<!-- Call Contents -->
										<div class="call-contents">
											<div class="call-content-wrap">
												<div class="user-video">
													<img src="../../../../../assets/img/video-call.jpg" alt="User Image">
												</div>
												<div class="my-video">
													<ul>
														<li>
															<img src="../../../../../assets/img/patients/patient1.jpg" class="img-fluid" alt="User Image">
														</li>
													</ul>
												</div>
											</div>
										</div>
										<!-- Call Contents -->
</template>