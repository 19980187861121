<template>
	<div class="main-wrapper">
		
        <layout-adminheader></layout-adminheader>
		
        <layout-adminfooter></layout-adminfooter>
        
        <!-- Page Wrapper -->
        <div class="page-wrapper">
            <div class="content container-fluid">
            
                <!-- Page Header -->
                <breadcrumb41></breadcrumb41>
                <!-- /Page Header -->
                
                <div class="row">
                    <basicform></basicform>
                    <addressform></addressform>
                </div>
                <horizontal1></horizontal1>
                
                <horizontal2></horizontal2>
            
            </div>			
        </div>
        <!-- /Main Wrapper -->
		
	</div>
</template>