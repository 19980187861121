import { createRouter, createWebHistory } from 'vue-router'
import {getAuth, onAuthStateChanged} from "firebase/auth";
/** For Home Pages **/
import Index from '../views/pages/frontend/dashboard/index'
import About from '../views/pages/frontend/pages/about/about'
import Addbilling from '../views/pages/frontend/doctor/billing/Addbilling/addbilling'
import Addprescription from '../views/pages/frontend/doctor/billing/Addprescription/addprescription'
import Appointmentdetail from '../views/pages/frontend/patient/appointment/appointmentdetail/appointmentdetail'
import Appointment from '../views/pages/frontend/doctor/appointment/appointment'
import Blankpage from '../views/pages/frontend/pages/blankpage'
import Blogdetail from '../views/pages/frontend/blog/blogdetail/blogdetail'
import Bloggrid from '../views/pages/frontend/blog/bloggrid/bloggrid'
import Bloglist from '../views/pages/frontend/blog/bloglist/bloglist'
import Booking from '../views/pages/frontend/patient/booking/booking'
import Bookingsuccess from '../views/pages/frontend/patient/booking/bookingsuccess'
import Calendar from '../views/pages/frontend/pages/calendar/calendar'
import Changepassword from '../views/pages/frontend/patient/password/changepassword'
import Chat from '../views/pages/frontend/patient/chat/chat'
import Doctorchat from '../views/pages/frontend/doctor/chat/chatdoctor'
import Checkout from '../views/pages/frontend/patient/checkout/checkout'
import Component from '../views/pages/frontend/pages/component/component'
import Contactus from '../views/pages/frontend/pages/contact/contactus'
import Doctorchangepassword from '../views/pages/frontend/doctor/password/changepassword'
import Doctorindex from '../views/pages/frontend/doctor/dashboard/index'
import Doctorprofile from '../views/pages/frontend/patient/profile/doctorprofile'
import Doctorprofilesetting from '../views/pages/frontend/doctor/profilesetting/doctorprofilesetting'
import Doctorregister from '../views/pages/frontend/doctor/doctorregister/register'
import Editbilling from '../views/pages/frontend/doctor/billing/Editbilling/editbilling'
import Editprescription from '../views/pages/frontend/doctor/billing/Editprescription/editprescription'
import Favourites from '../views/pages/frontend/patient/favourite/favourites'
import Forgotpassword from '../views/pages/frontend/pages/password/forgotpassword'
import Invoices from '../views/pages/frontend/pages/invoice/invoices'
import Invoiceview from '../views/pages/frontend/pages/invoice/invoiceview'
import Login from '../views/pages/frontend/pages/login'
import Voicecall from '../views/pages/frontend/pages/call/voicecall'
import Videocall from '../views/pages/frontend/pages/call/videocall'
import Termcondition from '../views/pages/frontend/pages/termcondition'
import Socialmedia from '../views/pages/frontend/doctor/social/socialmedia'
import Searchdoctor from '../views/pages/frontend/patient/search/searchdoctor'
import Scheduletiming from '../views/pages/frontend/patient/schedule/scheduletiming'
import Reviews from '../views/pages/frontend/doctor/review/reviews'
import Register from '../views/pages/frontend/pages/register'
import Profilesetting from '../views/pages/frontend/patient/setting/profilesetting'
import Privacypolicy from '../views/pages/frontend/pages/privacypolicy'
import Patientprofile from '../views/pages/frontend/doctor/profile/patientprofile'
import Patientdashboard from '../views/pages/frontend/patient/dashboard/index'
import Mypatients from '../views/pages/frontend/doctor/mypatient/mypatients'
import Mapgrid from '../views/pages/frontend/patient/map/grid/mapgrid'
import Maplist from '../views/pages/frontend/patient/map/list/maplist'
/***********Admin page ***********/
import Adminappointment from '../views/pages/admin/main/appointment'
import Adminblankpage from '../views/pages/admin/page/blankpage'
import Admincomponents from '../views/pages/admin/uiinterface/components/component'
import Admindatatable from '../views/pages/admin/uiinterface/table/datatable'
import Admindoctorlist from '../views/pages/admin/main/doctorlist'
import Adminerror404 from '../views/pages/admin/page/error/error404'
import Adminerror500 from '../views/pages/admin/page/error/error500'
import Adminforgot from '../views/pages/admin/page/authentication/forgotpassword'
import Formbasic from '../views/pages/admin/uiinterface/form/basic/formbasic'
import Horizontal from '../views/pages/admin/uiinterface/form/horizontal/horizontal'
import Inputgroup from '../views/pages/admin/uiinterface/form/inputgroup/inputgroup'
import Formmask from '../views/pages/admin/uiinterface/form/formmask/formmask'
import Formvalidation from '../views/pages/admin/uiinterface/form/validation/formvalidation'
import Formvertical from '../views/pages/admin/uiinterface/form/vertical/vertical'
import Adminindex from '../views/pages/admin/main/Dashboard/index'
import Invoicereport from '../views/pages/admin/main/report/invoicereport'
import Admintransaction from '../views/pages/admin/main/transaction/transactionlist'
import Admintablebasic from '../views/pages/admin/uiinterface/table/tablesbasic'
import Adminspecialities from '../views/pages/admin/main/speciality/specialities'
import Adminsettings from '../views/pages/admin/main/settings'
import Adminreview from '../views/pages/admin/main/reviews/review'
import Adminregister from '../views/pages/admin/page/authentication/register'
import Adminprofile from '../views/pages/admin/page/profile/profile'
import Adminpatientlist from '../views/pages/admin/main/patient/patientlist'
import Adminlogin from '../views/pages/admin/page/authentication/login'
import Adminlockscreen from '../views/pages/admin/page/authentication/lockscreen'
import Admininvoice from '../views/pages/admin/main/report/invoice'
const routes = [
  {
    path: '/',
    name: 'index-page',
    component: Index
  },
  {
    path: '/index',
    name: 'index',
    component: Index
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: About
  },
  {
    path: '/add-billing',
    name: 'add-billing',
    component: Addbilling
  },
  {
    path: '/add-prescription',
    name: 'add-prescription',
    component: Addprescription
  },
  {
    path: '/appointment-detail',
    name: 'appointment-detail',
    component: Appointmentdetail
  },
  {
    path: '/appointments',
    name: 'appointments',
    component: Appointment
  },
  {
    path: '/blank-page',
    name: 'blank-page',
    component: Blankpage
  },
  {
    path: '/blog-details',
    name: 'blog-details',
    component: Blogdetail
  },
  {
    path: '/blog-grid',
    name: 'blog-grid',
    component: Bloggrid
  },
  {
    path: '/blog-list',
    name: 'blog-list',
    component: Bloglist
  },
  {
    path: '/booking',
    name: 'booking',
    component: Booking
  },
  {
    path: '/booking-success',
    name: 'booking-success',
    component: Bookingsuccess
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: Calendar
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: Changepassword
  },
  {
    path: '/chat',
    name: 'chat',
    component: Chat
  },
  {
    path: '/chat-doctor',
    name: 'chat-doctor',
    component: Doctorchat
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: Checkout
  },
  {
    path: '/components',
    name: 'components',
    component: Component
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: Contactus
  },
  {
    path: '/professional-change-password',
    name: 'professional-change-password',
    component: Doctorchangepassword
  },
  {
    path: '/professional-dashboard',
    name: 'professional-dashboard',
    component: Doctorindex
  },
  {
    path: '/professional-profile',
    name: 'professional-profile',
    component: Doctorprofile
  },
  {
    path: '/professional-profile-settings',
    name: 'professional-profile-settings',
    component: Doctorprofilesetting
  },
  {
    path: '/professional-register',
    name: 'professional-register',
    component: Doctorregister
  },
  {
    path: '/edit-billing',
    name: 'edit-billing',
    component: Editbilling
  },
  {
    path: '/edit-prescription',
    name: 'edit-prescription',
    component: Editprescription
  },
  {
    path: '/favourites',
    name: 'favourites',
    component: Favourites
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: Forgotpassword
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: Invoices
  },
  {
    path: '/invoice-view',
    name: 'invoice-view',
    component: Invoiceview
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/voice-call',
    name: 'voice-call',
    component: Voicecall
  },
  {
    path: '/video-call',
    name: 'video-call',
    component: Videocall
  },
  {
    path: '/term-condition',
    name: 'term-condition',
    component: Termcondition
  },
  {
    path: '/social-media',
    name: 'social-media',
    component: Socialmedia
  },
  {
    path: '/search',
    name: 'search',
    component: Searchdoctor
  },
  {
    path: '/schedule-timings',
    name: 'schedule-timings',
    component: Scheduletiming
  },
  {
    path: '/reviews',
    name: 'reviews',
    component: Reviews
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/profile-settings',
    name: 'profile-settings',
    component: Profilesetting
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: Privacypolicy
  },  
  {
    path: '/patient-profile',
    name: 'patient-profile',
    component: Patientprofile
  },
  {
    path: '/patient-dashboard',
    name: 'patient-dashboard',
    component: Patientdashboard
  },
  {
    path: '/my-patients',
    name: 'my-patients',
    component: Mypatients
  },  
  {
    path: '/map-grid',
    name: 'map-grid',
    component: Mapgrid,
    meta:{
      requiiresAuth:true,
    }
  },
  {
    path: '/map-list',
    name: 'map-list',
    component: Maplist,
    meta:{
      requiiresAuth:true,
    }
  }, 
/***********Admin page ********/
  {
    path: '/admin/appointment-list',
    name: '/admin/appointment-list',
    component: Adminappointment
  },
  {
    path: '/admin/blank-page',
    name: '/admin/blank-page',
    component: Adminblankpage
  },
  {
    path: '/admin/components',
    name: '/admin/components',
    component: Admincomponents
  }, 
  {
    path: '/admin/data-tables',
    name: '/admin/data-tables',
    component: Admindatatable
  },  
  {
    path: '/admin/professional-list',
    name: '/admin/professional-list',
    component: Admindoctorlist
  },
  {
    path: '/admin/error-404',
    name: '/admin/error-404',
    component: Adminerror404
  }, 
  {
    path: '/admin/error-500',
    name: '/admin/error-500',
    component: Adminerror500
  },  
  {
    path: '/admin/forgot-password',
    name: '/admin/forgot-password',
    component: Adminforgot
  }, 
  {
    path: '/admin/form-basic-inputs',
    name: '/admin/form-basic-inputs',
    component: Formbasic
  }, 
  {
    path: '/admin/form-horizontal',
    name: '/admin/form-horizontal',
    component: Horizontal
  }, 
  {
    path: '/admin/form-input-groups',
    name: '/admin/form-input-groups',
    component: Inputgroup
  }, 
  {
    path: '/admin/form-mask',
    name: '/admin/form-mask',
    component: Formmask
  }, 
  {
    path: '/admin/form-validation',
    name: '/admin/form-validation',
    component: Formvalidation
  }, 
  {
    path: '/admin/form-vertical',
    name: '/admin/form-vertical',
    component: Formvertical
  },  
  {
    path: '/admin/index',
    name: '/admin/index',
    component: Adminindex
  }, 
  {
    path: '/admin/invoice-report',
    name: '/admin/invoice-report',
    component: Invoicereport
  },
  {
    path: '/admin/transactions-list',
    name: '/admin/transactions-list',
    component: Admintransaction
  },
  {
    path: '/admin/tables-basic',
    name: '/admin/tables-basic',
    component: Admintablebasic
  },      
  {
    path: '/admin/specialities',
    name: '/admin/specialities',
    component: Adminspecialities
  }, 
  {
    path: '/admin/settings',
    name: '/admin/settings',
    component: Adminsettings
  },
  {
    path: '/admin/reviews',
    name: '/admin/reviews',
    component: Adminreview
  },
  {
    path: '/admin/register',
    name: '/admin/register',
    component: Adminregister
  },
  {
    path: '/admin/profile',
    name: '/admin/profile',
    component: Adminprofile
  },
  {
    path: '/admin/patient-list',
    name: '/admin/patient-list',
    component: Adminpatientlist
  },
  {
    path: '/admin/login',
    name: '/admin/login',
    component: Adminlogin
  },
  {
    path: '/admin/lock-screen',
    name: '/admin/lock-screen',
    component: Adminlockscreen
  },
  {
    path: '/admin/invoice',
    name: '/admin/invoice',
    component: Admininvoice
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: 'active',
  routes
})

const getCurrentUser = () =>{
  return new Promise((resolve,reject) => {
    const removeListener = onAuthStateChanged(getAuth(), (user) => {
      removeListener();
      resolve(user);
    },reject)
  });
}

router.beforeEach(async (to,from,next) => {
  if(to.matched.some((record) => record.meta.requiiresAuth)){
    if(await getCurrentUser()){
      next();
    }else{
      next("/");
    }
  }else{
    next();
  }
});


export default router
