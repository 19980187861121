<template>
<!-- Rounded Button -->
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Rounded Button</h4>
                                <p class="card-text">use <code>.btn-rounded</code> in class <code>.btn</code> class to get Rounded button</p>
                            </div>
                            <div class="card-body">
                                <button type="button" class="btn btn-rounded btn-primary me-1">Primary</button>
                                <button type="button" class="btn btn-rounded btn-secondary me-1">Secondary</button>
                                <button type="button" class="btn btn-rounded btn-success me-1">Success</button>
                                <button type="button" class="btn btn-rounded btn-danger me-1">Danger</button>
                                <button type="button" class="btn btn-rounded btn-warning me-1">Warning</button>
                                <button type="button" class="btn btn-rounded btn-info me-1">Info</button>
                                <button type="button" class="btn btn-rounded btn-light me-1">Light</button>
                                <button type="button" class="btn btn-rounded btn-dark">Dark</button>
                                <hr>
                                <p>use <code>.btn-rounded</code> in class <code>.btn-outline-*</code> class to get Rounded Outline button</p>
                                <button type="button" class="btn btn-rounded btn-outline-primary me-1">Primary</button>
                                <button type="button" class="btn btn-rounded btn-outline-secondary me-1">Secondary</button>
                                <button type="button" class="btn btn-rounded btn-outline-success me-1">Success</button>
                                <button type="button" class="btn btn-rounded btn-outline-danger me-1">Danger</button>
                                <button type="button" class="btn btn-rounded btn-outline-warning me-1">Warning</button>
                                <button type="button" class="btn btn-rounded btn-outline-info me-1">Info</button>
                                <button type="button" class="btn btn-rounded btn-outline-light me-1">Light</button>
                                <button type="button" class="btn btn-rounded btn-outline-dark">Dark</button>
                            </div>
                        </div>
                        <!-- /Rounded Button -->
                        
                        <!-- Outline Buttons -->
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Outline Buttons</h4>
                                <p class="card-text">Use <code>.btn-outline-*</code> class for outline buttons.</p>
                            </div>
                            <div class="card-body">
                                <div class="row row-sm align-items-center">
                                    <div class="col-12 col-xl mb-3 mb-xl-0">Normal</div>
                                    <div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
                                        <button type="button" class="btn w-100 btn-outline-primary">Primary</button>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
                                        <button type="button" class="btn w-100 btn-outline-secondary">Secondary</button>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
                                        <button type="button" class="btn w-100 btn-outline-success">Success</button>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
                                        <button type="button" class="btn w-100 btn-outline-warning">Warning</button>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
                                        <button type="button" class="btn w-100 btn-outline-danger">Danger</button>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
                                        <button type="button" class="btn w-100 btn-outline-info">Info</button>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
                                        <button type="button" class="btn w-100 btn-outline-light">Light</button>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
                                        <button type="button" class="btn w-100 btn-outline-dark">Dark</button>
                                    </div>
                                </div>
                                <div class="row row-sm align-items-center mt-3">
                                    <div class="col-12 col-xl mb-3 mb-xl-0">Active</div>
                                    <div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
                                        <button type="button" class="btn w-100 btn-outline-primary active">Primary</button>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
                                        <button type="button" class="btn w-100 btn-outline-secondary active">Secondary</button>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
                                        <button type="button" class="btn w-100 btn-outline-success active">Success</button>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
                                        <button type="button" class="btn w-100 btn-outline-warning active">Warning</button>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
                                        <button type="button" class="btn w-100 btn-outline-danger active">Danger</button>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
                                        <button type="button" class="btn w-100 btn-outline-info active">Info</button>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
                                        <button type="button" class="btn w-100 btn-outline-light active">Light</button>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
                                        <button type="button" class="btn w-100 btn-outline-dark active">Dark</button>
                                    </div>
                                </div>
                                <div class="row row-sm align-items-center mt-3">
                                    <div class="col-12 col-xl mb-3 mb-xl-0">Disabled</div>
                                    <div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
                                        <button disabled="" type="button" class="btn w-100 btn-outline-primary">Primary</button>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
                                        <button disabled="" type="button" class="btn w-100 btn-outline-secondary">Secondary</button>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
                                        <button disabled="" type="button" class="btn w-100 btn-outline-success">Success</button>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
                                        <button disabled="" type="button" class="btn w-100 btn-outline-warning">Warning</button>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
                                        <button disabled="" type="button" class="btn w-100 btn-outline-danger">Danger</button>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
                                        <button disabled="" type="button" class="btn w-100 btn-outline-info">Info</button>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
                                        <button disabled="" type="button" class="btn w-100 btn-outline-light">Light</button>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
                                        <button disabled="" type="button" class="btn w-100 btn-outline-dark">Dark</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /Outline Buttons -->
</template>