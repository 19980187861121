<template>
<!-- Home Banner -->
<section class="section section-search">
	<div class="container">
		<div class="banner-wrapper">
			<div class="banner-header">
				<h1>The Perfect Look is an Appointment Away</h1>
			</div>
			 
			<!-- Search -->
			<div class="search-box">
				<form>
					<div class="form-group search-location">
						 <input
								class="form-control"
								type="text"
								@input="$emit('update:street', $event.target.value)"
								:value="street"
								ref="streetRef"
								placeholder="Location"/>
					</div>
					<div class="form-group search-info">
						<input type="text" class="form-control" placeholder="Search Services">
					</div>
					<router-link to="/search">
					<button type="submit" class="btn btn-primary search-btn"><span>Search</span></button>
					</router-link>
				</form>
			</div>
			<!-- /Search -->
			
		</div>
	</div>
</section>
<!-- /Home Banner -->	
</template>
<script>
import {onMounted, onUnmounted, ref} from "vue";
//import { useGeolocation } from "@/useGeolocation";
//import {Loader} from '@googlemaps/js-api-loader'

//const GOOGLE_MAPS_API_KEY = 'AIzaSyByAz5YTA8NgLl317a8E5HSk-i9RS_mDGM';

export default {
  name: 'App',
  props: {
    label: {
      type: String,
      default: '',
    },
    street: {
      type: String,
      default: '',
    },
    streetNumber: {
      type: String,
      default: '',
    },
    postcode: {
      type: String,
      default: '',
    },
    city: {
      type: String,
      default: '',
    },
    country: {
      type: String,
      default: '',
    },
  },
  setup(props, context){
	const streetRef = ref();
    let autocomplete;

	    onMounted(() => {
      autocomplete = new google.maps.places.Autocomplete(streetRef.value, {
        types: ["address"],
        fields: ["address_components"]
      });
 
      google.maps.event.addListener(autocomplete, "place_changed", () => {
        const mapping = {
          route: "update:street",
        }
 
        for(const type in mapping) {
          context.emit(mapping[type], "");
        }
 
        let place = {
          address_components: [],
          ...autocomplete.getPlace()
        }
 
        place.address_components.forEach((component) => {
          component.types.forEach((type) => {
              if(mapping.hasOwnProperty(type)){
                context.emit(mapping[type], component.long_name);
              }
          });
        });
      });
    });
 
    onUnmounted(() => {
      if(autocomplete) {
        google.maps.event.clearInstanceListeners(autocomplete);
      }
    });
 
    return {streetRef};
    //const {coords} = useGeolocation();

    /*const currPos = computed(() => ({
      lat: coords.value.latitude,
      lng: coords.value.longitude
    }))*/

	/*const loader = new Loader({apiKey:GOOGLE_MAPS_API_KEY})
	const mapDiv = ref(null)
	onMounted(async () => {
		await loader.load()
		new google.maps.Map(mapDiv.value,{
			center:currPos.value,
			zoom:7
		});

	})*/


  }
}

</script>