<template>

    <div class="main-wrapper">

        <layout-doctorheader></layout-doctorheader>

        <!-- Breadcrumb -->
        <div class="breadcrumb-bar">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-md-12 col-12">
                        <nav aria-label="breadcrumb" class="page-breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link to="/index">Home</router-link></li>
                                <li class="breadcrumb-item active" aria-current="page">Edit Billing</li>
                            </ol>
                        </nav>
                        <h2 class="breadcrumb-title">Edit Billing</h2>
                    </div>
                </div>
            </div>
        </div>
			<!-- /Breadcrumb -->
			
			<!-- Page Content -->
			<div class="content">
				<div class="container-fluid">

					<div class="row">

                        <billingsidebar></billingsidebar>

						<div class="col-md-7 col-lg-8 col-xl-9">
							<div class="card">
								<div class="card-header">
									<h4 class="card-title mb-0">Edit Billing</h4>
								</div>
								<div class="card-body">
									<div class="row">
										<div class="col-sm-6">
											<div class="biller-info">
												<h4 class="d-block">Dr. Eric Buckles</h4>
												<span class="d-block text-sm text-muted">Dentist</span>
												<span class="d-block text-sm text-muted">Newyork, United States</span>
											</div>
										</div>
										<div class="col-sm-6 text-sm-end">
											<div class="billing-info">
												<h4 class="d-block">1 November 2019</h4>
												<span class="d-block text-muted">#INV0001</span>
											</div>
										</div>
									</div>
									
									<!-- Add Item -->
									<div class="add-more-item text-end">
										<a href="javascript:void(0);"><i class="fa fa-plus-circle"></i> Add Item</a>
									</div>
									<!-- /Add Item -->
									
									<!-- Billing Item -->
									<div class="card card-table">
										<div class="card-body">
											<div class="table-responsive">
												<table class="table table-hover table-center">
													<thead>
														<tr>													
															<th>Title</th>					
															<th>Amount</th>		
															<th style="width:80px;"></th>
														</tr>
													</thead>
													<tbody>
														<tr>								
															<td>	
																<input type="text" class="form-control" value="Consulting Fee">
															</td>
															<td>
																<input type="text" class="form-control" value="$330">
															</td>							
															<td>
																<a href="javascript:void(0);" class="btn bg-danger-light trash"><i class="far fa-trash-alt"></i></a>															
															</td>
														</tr>
														<tr>								
															<td>	
																<input type="text" class="form-control" value="Video Calling Appointment">
															</td>
															<td>
																<input type="text" class="form-control" value="$100">
															</td>							
															<td>
																<a href="javascript:void(0);" class="btn bg-danger-light trash"><i class="far fa-trash-alt"></i></a>															
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
									<!-- /Billing Item -->
									
									<!-- Signature -->
									<div class="row">
										<div class="col-md-12 text-end">
											<div class="signature-wrap">
												<div class="signature">
													Click here to sign
												</div>
												<div class="sign-name">
													<p class="mb-0">( Dr. Eric Buckles )</p>
													<span class="text-muted">Signature</span>
												</div>
											</div>
										</div>
									</div>
									<!-- /Signature -->
									
									<!-- Submit Section -->
									<div class="row">
										<div class="col-md-12">
											<div class="submit-section">
												<button type="submit" class="btn btn-primary submit-btn">Save</button>
												<button type="reset" class="btn btn-secondary submit-btn">Clear</button>
											</div>
										</div>
									</div>
									<!-- /Submit Section -->
									
								</div>
							</div>
						</div>
					</div>

				</div>

			</div>		
			<!-- /Page Content -->

        <layout-footer></layout-footer>

    </div>
	
</template>

<script>
export default {
    data() {
        return {
        }
    }
}
</script>