<template>
	<div class="main-wrapper">
		
        <layout-adminheader></layout-adminheader>
		
        <layout-adminfooter></layout-adminfooter>
        
        <!-- Page Wrapper -->
        <div class="page-wrapper">
            <div class="content container-fluid">
            
                <!-- Page Header -->
                <breadcrumb51></breadcrumb51>
                <!-- /Page Header -->
                
                <div class="row">
                    
                    <div class="col-12">
                        
                        <!-- General -->
                        
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">General</h4>
                                </div>
                                <div class="card-body">
                                    <form action="#">
                                
                                        <div class="form-group">
                                            <label>Website Name</label>
                                            <input type="text" class="form-control">
                                        </div>
                                        <div class="form-group">
                                            <label>Website Logo</label>
                                            <input type="file" class="form-control">
                                            <small class="text-secondary">Recommended image size is <b>150px x 150px</b></small>
                                        </div>
                                        <div class="form-group mb-0">
                                            <label>Favicon</label>
                                            <input type="file" class="form-control">
                                            <small class="text-secondary">Recommended image size is <b>16px x 16px</b> or <b>32px x 32px</b></small><br>
                                            <small class="text-secondary">Accepted formats : only png and ico</small>
                                        </div>
                                        
                                    </form>
                                </div>
                            </div>
                        
                        <!-- /General -->
                            
                    </div>
                </div>
                
            </div>			
        </div>
        <!-- /Page Wrapper -->
		
	</div>
</template>