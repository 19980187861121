<template>
<section class="comp-section">
                        <div class="section-header">
                            <h3 class="section-title">Avatar</h3>
                            <div class="line"></div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card">
                                    <div class="card-header">
                                        <h4 class="card-title">Sizing</h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="avatar avatar-xxl me-1">
                                            <img class="avatar-img rounded-circle" alt="User Image" src="../../../../../assets/admin_img/profiles/avatar-02.jpg">
                                        </div>
                                        <div class="avatar avatar-xl me-1">
                                            <img class="avatar-img rounded-circle" alt="User Image" src="../../../../../assets/admin_img/profiles/avatar-02.jpg">
                                        </div>
                                        <div class="avatar avatar-lg me-1">
                                            <img class="avatar-img rounded-circle" alt="User Image" src="../../../../../assets/admin_img/profiles/avatar-02.jpg">
                                        </div>
                                        <div class="avatar me-1">
                                            <img class="avatar-img rounded-circle" alt="User Image" src="../../../../../assets/admin_img/profiles/avatar-02.jpg">
                                        </div>
                                        <div class="avatar avatar-sm me-1">
                                            <img class="avatar-img rounded-circle" alt="User Image" src="../../../../../assets/admin_img/profiles/avatar-02.jpg">
                                        </div>
                                        <div class="avatar avatar-xs">
                                            <img class="avatar-img rounded-circle" alt="User Image" src="../../../../../assets/admin_img/profiles/avatar-02.jpg">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="card">
                                    <div class="card-header">
                                        <h4 class="card-title">Avatar With Status</h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="avatar avatar-online me-1">
                                            <img class="avatar-img rounded-circle" alt="User Image" src="../../../../../assets/admin_img/profiles/avatar-02.jpg">
                                        </div>
                                        <div class="avatar avatar-offline me-1">
                                            <img class="avatar-img rounded-circle" alt="User Image" src="../../../../../assets/admin_img/profiles/avatar-02.jpg">
                                        </div>
                                        <div class="avatar avatar-away">
                                            <img class="avatar-img rounded-circle" alt="User Image" src="../../../../../assets/admin_img/profiles/avatar-02.jpg">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="card">
                                    <div class="card-header">
                                        <h4 class="card-title">Shape</h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="avatar me-1">
                                            <img class="avatar-img rounded" alt="User Image" src="../../../../../assets/admin_img/profiles/avatar-02.jpg">
                                        </div>
                                        <div class="avatar">
                                            <img class="avatar-img rounded-circle" alt="User Image" src="../../../../../assets/admin_img/profiles/avatar-02.jpg">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="card">
                                    <div class="card-header">
                                        <h4 class="card-title">Group</h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="avatar-group">
                                            <div class="avatar">
                                                <img class="avatar-img rounded-circle border border-white" alt="User Image" src="../../../../../assets/admin_img/profiles/avatar-02.jpg">
                                            </div>
                                            <div class="avatar">
                                                <img class="avatar-img rounded-circle border border-white" alt="User Image" src="../../../../../assets/admin_img/profiles/avatar-03.jpg">
                                            </div>
                                            <div class="avatar">
                                                <img class="avatar-img rounded-circle border border-white" alt="User Image" src="../../../../../assets/admin_img/profiles/avatar-04.jpg">
                                            </div>
                                            <div class="avatar">
                                                <span class="avatar-title rounded-circle border border-white">CF</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
</template>