<template>
	<div class="main-wrapper">
		
        <layout-adminheader></layout-adminheader>
		
        <layout-adminfooter></layout-adminfooter>
        
        <!-- Page Wrapper -->
        <div class="page-wrapper">
            <div class="content container-fluid">
                
                <!-- Page Header -->
                <breadcrumb42></breadcrumb42>
                <!-- /Page Header -->
                
                <div class="row">
                    <div class="col-lg-12">
                        <basiceg></basiceg>
                        <checkbox></checkbox>
                        <buttondrop></buttondrop>
                    </div>
                </div>
            
            </div>			
        </div>
        <!-- /Main Wrapper -->
		
	</div>
</template>