<template>

    <div class="account-page">

    <div class="main-wrapper">

        <layout-header></layout-header>

        <!-- Page Content -->
			<div class="content">
				<div class="container-fluid">
					<div class="row">
						<div class="col-md-8 offset-md-2">
						
							<!-- Account Content -->
							<div class="account-content">
								<div class="row align-items-center justify-content-center">
									<div class="wave col-md-7 col-lg-6 login-left">
										<img src="../../../../../assets/img/professional-logo.jpg" class="img-fluid" alt="Login Banner">	
									</div>
									<div class="col-md-12 col-lg-6 login-right">
										<div class="login-header">
											<h3>Professional Register <router-link to="/register">Not a Professional?</router-link></h3>
										</div>
										
										<!-- Register Form -->
										<div>
											<div class="validation-error" v-if="userNameError">Please Enter Name</div>
											<div class="form-group form-focus">
												<input type="text" class="form-control floating" v-model = "username" @input="updateName" required>
												<label class="focus-label">Name</label>
											</div>
											<div class="validation-error" v-if="phoneError">Please Enter Valid Phone Number</div>
											<div class="form-group form-focus">
												<input type="text" class="form-control floating" v-model = "phone" @input="updatePhone" required>
												<label class="focus-label">Mobile Number</label>
											</div>
											<div class="validation-error" v-if="emailError">Please Enter Valid Email Address</div>
											<div class="form-group form-focus">
												<input type="text" class="form-control floating" v-model = "email" @input="updateEmail" required>
												<label class="focus-label">Email</label>
											</div>
											<div class="validation-error" v-if="locationdError">Please Enter Valid Location</div>
											<div class="form-group form-focus">
												 <input
													class="form-control floating"
													type="text"
													@input="$emit('update:street', $event.target.value)"
													:value="street"
													ref="streetRef"
													placeholder="Location"/>
												<label class="focus-label">Location</label>
											</div>
											<div class="validation-error" v-if="passwordError">Please Enter Password (At least 6 characters)</div>
											<div class="form-group form-focus">
												<input type="password" class="form-control floating" v-model = "password" @input="updatePassword" required>
												<label class="focus-label">Create Password</label>
											</div>
											<div class="validation-error" v-if="specialityError">Please Select at least one Speciality</div>
											<div class="form-group specialities-selection">
												<label>Specialities (To Select Multiple hold control key on Your Keyboard)</label>
												<select class="form-select form-control"  @change="onChange($event)" v-model="mySpecialities"  multiple>
													<!--
													<option></option>
													-->
													<option>Adult Haircut</option>
													<option selected="selected">Facial Hair</option>
													<option>Razor Lining</option>
													<option>Kids Haircut</option>
												</select>
												<div class ="tag" v-for="(tag,index) in mySpecialities" :key="'tag'+index">
													{{tag}}
												</div>
											</div>

											<!--
											<div class="form-group form-focus specialities-selection">
												<label>Upload Picture</label>
												<input type="file" class="form-control floating" @change="onSelect($event)" required>
												<label class="focus-label"></label>
											</div>
											<br>
											-->

											

											<div class="validation-error" v-if="agreementError">Please Read & Accept Terms of Use & Privacy Policy</div>
											<div class="terms-and-policy pt-2 pb-2">
												<input type="checkbox" required name="checkbox" value="check" id="agree" v-model = "agreement" @input="updateAgreement" /><span class="agree">I agree to these <span class="terms"><router-link to="/term-condition" target="_blank">Terms of Use</router-link> and <router-link to="/privacy-policy" target="_blank">Privacy Policy</router-link></span></span>
											</div>
											<div class="text-end">
												<router-link class="forgot-link" to="/logn">Already have an account?</router-link>
											</div>
                                            <!-- <router-link to="/doctor-dashboard">-->
											<button class="btn btn-primary w-100 btn-lg login-btn" @click = "register" >Signup</button>
                                            <!--</router-link>-->

											<!--
											<div class="login-or">
												<span class="or-line"></span>
												<span class="span-or">or</span>
											</div>
											-->
											<div class="row form-row social-login">
												<!--
												<div class="col-6">
													<a href="javascript:void(0);" class="btn btn-facebook w-100"><i class="fab fa-facebook-f me-1"></i> Login</a>
												</div>
												-->
												<!--
												<div class="col-6">
													-->
													<!--
												<div>
													<a href="javascript:void(0);" class="btn btn-google w-100"><i class="fab fa-google me-1"></i> Login</a>
												</div>
												-->
											</div>
										</div>
										<!-- /Register Form -->
										
									</div>
								</div>
							</div>
							<!-- /Account Content -->
								
						</div>
					</div>

				</div>

			</div>		
			<!-- /Page Content -->

        <layout-footer></layout-footer>

    </div>

</div>
	
</template>
<script setup>
	import {ref, onMounted, onUnmounted} from "vue";
	import {getAuth, createUserWithEmailAndPassword} from "firebase/auth";
	import { useRouter } from "vue-router";
	import { getDatabase, set } from "firebase/database";
	//import { collection, getDocs } from "firebase/firestore";
	import { doc, setDoc } from "firebase/firestore"; 
	import { collection, getDocs } from "firebase/firestore";
	import { db } from '@/firebase';

	const streetRef = ref();
	let autocomplete;

	const street = ref();
	const emit = defineEmits(['update:street'])

	const username = ref("");
	const userNameError = ref("");
	const phone = ref("");
	const phoneError = ref("");
	const email = ref("");
	const emailError = ref(""); 
	const password = ref("");
	const passwordError = ref("");
	const locationdError = ref("");
	const mySpecialities = ref([]);
	const specialityError = ref("")

	const agreement = ref("");
	const agreementError = ref("");
	const router = useRouter()//get a reference to our vue router
	var errors = [];

	var addressData = "";
	var latitudeData = "";
	var longitudeData = "";

	var specialitiesTags = "";
	var specialitiesHeaders = "";

	let file = {};

	/*onMounted(async () =>{
		const querySnapshot = await getDocs(collection(db, "Clients"));
		querySnapshot.forEach((doc) => {
		// doc.data() is never undefined for query doc snapshots
		console.log(doc.id, " => ", doc.data());
		});
	})*/
	
	//mySpecialities.value.push("");

	function validEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
	function phoneNumber(phone) {
		var re = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
		return re.test(phone);
	}
	function updateName(event) {
		userNameError.value = false; 
    }
	function updatePhone(event) {
		phoneError.value = false; 
    }
	function updateEmail(event){
		emailError.value = false;
	}
	function updatePassword(event){
		passwordError.value = false;
	}
	function updateAgreement(event){
		agreementError.value = false;
	}

	function onChange(event) {
		//console.log(event.target.value)
		specialityError.value = false;
		specialitiesTags = "";

		var arrayLength = mySpecialities.value.length;
		for (var i = 0; i < arrayLength; i++) {
			//specialitiesHeaders = mySpecialities.value[i];
			//specialitiesHeaders =   mySpecialities.value[i].replace(/\s+/g, ''); 
			console.log(specialitiesHeaders);
			//console.log(mySpecialities.value[i]);
			specialitiesTags = specialitiesTags +", "+mySpecialities.value[i];
			//console.log(specialitiesTags);
			//Do something
		}
	}

	function onSelect(event){
		file = event.target.files[0];
		console.log(file);
	}

	const register = () =>{
		console.log("agreement",agreement.value);
		if (agreement.value === '' || agreement.value === null || agreement.value === 0){
			errors.push("agreement required.");
			agreementError.value = true; 
		}
		if (username.value === '' || username.value === null || username.value === 0){
			errors.push("Name required.");
			userNameError.value = true; 
		}

		if (phone.value === '' || phone.value === null || phone.value === 0) {
			errors.push('phone required.');
			phoneError.value = true; 
		} else if (!phoneNumber(phone.value)) {
			errors.push('Valid phone required.');
			phoneError.value = true; 
		}

		if (email.value === '' || email.value === null || email.value === 0) {
			errors.push('Email required.');
			emailError.value = true; 
		} else if (!validEmail(email.value)) {
			errors.push('Valid email required.');
			emailError.value = true; 
		}

		if (password.value === '' || password.value === null || password.value === 0) {
			errors.push('Password required.');
			passwordError.value = true; 
		} else if (password.value.length < 6) {
			errors.push('Valid Password required.');
			passwordError.value = true; 
		}

		if (addressData === '' || addressData === null || addressData === 0) {
			errors.push('Location required.');
			locationdError.value = true; 
		}

		if (latitudeData === '' || latitudeData === null || latitudeData === 0) {
			errors.push('Location required.');
			locationdError.value = true; 
		}

		if (longitudeData === '' || longitudeData === null || longitudeData === 0) {
			errors.push('Location required.');
			locationdError.value = true; 
		}

		if (mySpecialities.value.length < 1) {
			errors.push('Specialities required.');
			specialityError.value = true; 
		}

		// need .value because ref()
		console.log(errors.length);
		if(!errors.length){
			const auth = getAuth(); //fromm firebase/auth
			createUserWithEmailAndPassword(auth, email.value, password.value).then((data) => {
				console.log("Successfully registered");
				var coordinates = {
					latitude:latitudeData,
					longitude:longitudeData 
				}

				//console.log(auth.currentUser);
				// Add a new document in collection "cities"
				setDoc(doc(db, "Professionals", auth.currentUser.uid), {
					username: username.value,
					phone: phone.value,
					email: email.value,
					address: addressData,
					location: coordinates,
					specialities:specialitiesTags,
					profilePic:"https://i.ibb.co/DPB50jD/9227132-barber-cutting-hair-hairdressing-hairstylist-icon.png"
				});

				var arrayLength = mySpecialities.value.length;
				for (var i = 0; i < arrayLength; i++) {
					specialitiesHeaders =   mySpecialities.value[i].replace(/\s+/g, ''); 
					console.log(specialitiesHeaders);
					setDoc(doc(db, specialitiesHeaders, auth.currentUser.uid), {
						location: coordinates
					});
				}

				router.push('/professional-dashboard')
			}).catch((error) => {
				console.log(error.code);
				//alert(error.message);
				errors = [];
			})
		}
		errors = [];
		/**/
	};

	const signInWithGoogle = () =>{

	};

	onMounted(() =>{
		 autocomplete = new google.maps.places.Autocomplete(streetRef.value, {
			types: ["address"],
			fields: ["address_components", "geometry", "icon","formatted_address"]
		});

		google.maps.event.addListener(autocomplete, "place_changed", () => {
			const mapping = {
			route: "update:street",
			}

			const places = autocomplete.getPlace();
			addressData = places.formatted_address;
			latitudeData = places.geometry.location.lat();
			longitudeData = places.geometry.location.lng();

			console.log(latitudeData);
			console.log(longitudeData);
			console.log(addressData);

			locationdError.value = false; 

			street.value = addressData;
	
			for(const type in mapping) {
				emit(mapping[type], "");
			}
	
			let place = {
			address_components: [],
			...autocomplete.getPlace()
			}
	
			place.address_components.forEach((component) => {
				component.types.forEach((type) => {
					if(mapping.hasOwnProperty(type)){
						emit(mapping[type], component.long_name);
					}
				});
			});
		});
    })

</script>