<template>
<!-- Sidebar -->
<div class="sidebar" id="sidebar">
    <div class="sidebar-inner">
        <perfect-scrollbar class="scroll-area"  :settings="settings" @ps-scroll-y="scrollHanle">
        <div id="sidebar-menu" class="sidebar-menu">
            <ul>
                <li class="menu-title"> 
                    <span>Main</span>
                </li>
                <li> 
                    <router-link to="/admin/index"><i class="fe fe-home"></i> <span>Dashboard</span></router-link>
                </li>
                <li> 
                    <router-link to="/admin/appointment-list"><i class="fe fe-layout"></i> <span>Appointments</span></router-link>
                </li>
                <li> 
                    <router-link to="/admin/specialities"><i class="fe fe-users"></i> <span>Specialities</span></router-link>
                </li>
                <li> 
                    <router-link to="/admin/doctor-list"><i class="fe fe-user-plus"></i> <span>Doctors</span></router-link>
                </li>
                <li> 
                    <router-link to="/admin/patient-list"><i class="fe fe-user"></i> <span>Patients</span></router-link>
                </li>
                <li> 
                    <router-link to="/admin/reviews"><i class="fe fe-star-o"></i> <span>Reviews</span></router-link>
                </li>
                <li> 
                    <router-link to="/admin/transactions-list"><i class="fe fe-activity"></i> <span>Transactions</span></router-link>
                </li>
                <li> 
                    <router-link to="/admin/settings"><i class="fe fe-vector"></i> <span>Settings</span></router-link>
                </li>

                <li class="submenu" :class="report ? 'active' : 'notactive'">
                    <a href="javascript:void(0);"><i class="fe fe-document"></i> <span> Reports</span> <span class="menu-arrow"></span></a>
                    <ul style="display: none;">
                        <li :class="currentPath == '/admin/invoice-report' ? 'active' : 'notactive'"><router-link to="/admin/invoice-report">Invoice Reports</router-link></li>
                    </ul>
                </li>
                <li class="menu-title"> 
                    <span>Pages</span>
                </li>
                <li> 
                    <router-link to="/admin/profile"><i class="fe fe-user-plus"></i> <span>Profile</span></router-link>
                </li>
                <li class="submenu">
                    <a href="javascript:void(0);"><i class="fe fe-document"></i> <span> Authentication </span> <span class="menu-arrow"></span></a>
                    <ul style="display: none;">
                        <li><router-link to="/admin/login"> Login </router-link></li>
                        <li><router-link to="/admin/register"> Register </router-link></li>
                        <li><router-link to="/admin/forgot-password"> Forgot Password </router-link></li>
                        <li><router-link to="/admin/lock-screen"> Lock Screen </router-link></li>
                    </ul>
                </li>
                <li class="submenu">
                    <a href="javascript:void(0);"><i class="fe fe-warning"></i> <span> Error Pages </span> <span class="menu-arrow"></span></a>
                    <ul style="display: none;">
                        <li><router-link to="/admin/error-404">404 Error </router-link></li>
                        <li><router-link to="/admin/error-500">500 Error </router-link></li>
                    </ul>
                </li>
                <li> 
                    <router-link to="/admin/blank-page"><i class="fe fe-file"></i> <span>Blank Page</span></router-link>
                </li>
                <li class="menu-title"> 
                    <span>UI Interface</span>
                </li>
                <li> 
                    <router-link to="/admin/components"><i class="fe fe-vector"></i> <span>Components</span></router-link>
                </li>
                <li class="submenu">
                    <a href="javascript:void(0);"><i class="fe fe-layout"></i> <span> Forms </span> <span class="menu-arrow"></span></a>
                    <ul style="display: none;">
                        <li><router-link to="/admin/form-basic-inputs">Basic Inputs </router-link></li>
                        <li><router-link to="/admin/form-input-groups">Input Groups </router-link></li>
                        <li><router-link to="/admin/form-horizontal">Horizontal Form </router-link></li>
                        <li><router-link to="/admin/form-vertical"> Vertical Form </router-link></li>
                        <li><router-link to="/admin/form-mask"> Form Mask </router-link></li>
                        <li><router-link to="/admin/form-validation"> Form Validation </router-link></li>
                    </ul>
                </li>
                <li class="submenu">
                    <a href="javascript:void(0);"><i class="fe fe-table"></i> <span> Tables </span> <span class="menu-arrow"></span></a>
                    <ul style="display: none;">
                        <li><router-link to="/admin/tables-basic">Basic Tables </router-link></li>
                        <li><router-link to="/admin/data-tables">Data Table </router-link></li>
                    </ul>
                </li>
                <li class="submenu">
                    <a href="javascript:void(0);"><i class="fe fe-code"></i> <span>Multi Level</span> <span class="menu-arrow"></span></a>
                    <ul style="display: none;">
                        <li class="submenu">
                            <a href="javascript:void(0);"> <span>Level 1</span> <span class="menu-arrow"></span></a>
                            <ul style="display: none;">
                                <li><a href="javascript:void(0);"><span>Level 2</span></a></li>
                                <li class="submenu">
                                    <a href="javascript:void(0);"> <span> Level 2</span> <span class="menu-arrow"></span></a>
                                    <ul style="display: none;">
                                        <li><a href="javascript:void(0);">Level 3</a></li>
                                        <li><a href="javascript:void(0);">Level 3</a></li>
                                    </ul>
                                </li>
                                <li><a href="javascript:void(0);"> <span>Level 2</span></a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="javascript:void(0);"> <span>Level 1</span></a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </perfect-scrollbar>
    </div>
</div>
<!-- /Sidebar -->
</template>
<script>
     import {PerfectScrollbar}  from 'vue3-perfect-scrollbar'
    import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
     import { onMounted } from 'vue'
    import feather from 'feather-icons'
    export default {
        components: {
             PerfectScrollbar ,
        },
  
   setup() { 
             onMounted(() => {
    feather.replace()
      $('#sidebar-menu a').on('click', function (e) {
        if ($(this).parent().hasClass('submenu')) {
          e.preventDefault();
        }
        if (!$(this).hasClass('subdrop')) {
          $('ul', $(this).parents('ul:first')).slideUp(350);
          $('a', $(this).parents('ul:first')).removeClass('subdrop');
          $(this).next('ul').slideDown(350);
          $(this).addClass('subdrop');
        } else if ($(this).hasClass('subdrop')) {
          $(this).removeClass('subdrop');
          $(this).next('ul').slideUp(350);
        }
      });
      $('#sidebar-menu ul li.submenu a.active').parents('li:last').children('a:first').addClass('active').trigger('click');

    });
         },
         computed: {
       currentPath() {
           return this.$route.name
       },
       report() {
           return this.$route.name == 'invoice' || this.$route.name == 'invoice-report';
       }
   },
  data() {
    return {
                settings: {
                    suppressScrollX: true,
                },
                activeClass: 'active',
            };
     

              //  isactive : true
    },
    methods: {
    scrollHanle(evt) {
      console.log(evt)
    }
  },
}
</script>
<style>
    .scroll-area {
      position: relative;
      margin: auto;
      height: calc(100vh - 60px);
      background-color: transparent !important;
  }
 .sidebar-menu ul li a.active {
    background-color: #0caead;
    color: #fff;
}
.sidebar-menu ul ul a.active {
    color: #f3c41b;
    text-decoration: underline;
    background-color: unset;
}
svg.feather.feather-home {
    width: 21px;
    height: 21px;
}
svg.feather.feather-layout {
    width: 21px;
    height: 21px;
}
svg.feather.feather-users {
    width: 21px;
    height: 21px;
}
svg.feather.feather-user-plus {
    width: 21px;
    height: 21px;
}
svg.feather.feather-user {
    width: 21px;
    height: 21px;
}
svg.feather.feather-star {
    width: 21px;
    height: 21px;
}
svg.feather.feather-bar-chart-2 {
    width: 21px;
    height: 21px;
}
svg.feather.feather-map {
    width: 21px;
    height: 21px;
}
svg.feather.feather-book {
    width: 21px;
    height: 21px;
}
svg.feather.feather-x-circle {
    width: 21px;
    height: 21px;
}
svg.feather.feather-file {
    width: 21px;
    height: 21px;
}
svg.feather.feather-credit-card {
    width: 21px;
    height: 21px;
}
svg.feather.feather-code {
    width: 21px;
    height: 21px;
}
</style>