<template>

<div class="account-page">

    <div class="main-wrapper">

        <layout-header></layout-header>

        <!-- Page Content -->
			<div class="content">
				<div class="container-fluid">
					
					<div class="row">
						<div class="col-md-8 offset-md-2">
							
							<!-- Login Tab Content -->
							<div class="account-content">
								<div class="row align-items-center justify-content-center">
									<div class="wave col-md-7 col-lg-6 login-left">
										<img src="../../../../assets/img/login-banner.jpg" class="img-fluid" alt="Doccure Login">	
									</div>
									<div class="col-md-12 col-lg-6 login-right">
										<div class="login-header">
											<h3>Login <span>TheBuukMe</span></h3>
										</div>
										<div>
											<div class="validation-error" v-if="emailError">Please Enter Valid Email Address</div>
											<div class="form-group form-focus">
												<input type="email" class="form-control floating" v-model = "email" @input="updateEmail" required>
												<label class="focus-label">Email</label>
											</div>
											<div class="validation-error" v-if="passwordError">Please Enter Password</div>
											<div class="form-group form-focus">
												<input type="password" class="form-control floating" v-model = "password" @input="updatePassword" required>
												<label class="focus-label">Password</label>
											</div>
											<p class="validation-error" v-if="errMsg">{{errMsg}}</p>
                                            <router-link to="/forgot-password">
											<div class="text-end">
												<router-link class="forgot-link" to="/forgot-password">Forgot Password ?</router-link>
											</div>
                                            </router-link>
											<button class="btn btn-primary w-100 btn-lg login-btn" @click = "register">Login</button>
											<!--
											<div class="login-or">
												<span class="or-line"></span>
												<span class="span-or">or</span>
											</div>
											-->
											<div class="row form-row social-login">
												<!--
												<div>
													<a href="javascript:void(0);" class="btn btn-google w-100"><i class="fab fa-google me-1"></i> Login</a>
												</div>
												-->
												<!--
												<div class="col-6">
													<a href="javascript:void(0);" class="btn btn-facebook w-100"><i class="fab fa-facebook-f me-1"></i> Login</a>
												</div>
												<div class="col-6">
													<a href="javascript:void(0);" class="btn btn-google w-100"><i class="fab fa-google me-1"></i> Login</a>
												</div>
												-->
											</div>
											<div class="text-center dont-have">Don’t have an account? <router-link to="/register">Register</router-link></div>
										</div>
									</div>
								</div>
							</div>
							<!-- /Login Tab Content -->
								
						</div>
					</div>

				</div>

			</div>		
			<!-- /Page Content -->

        <layout-footer></layout-footer>

    </div>

	</div>
	
</template>
<script setup>
	import {ref} from "vue";
	import {getAuth, signInWithEmailAndPassword} from "firebase/auth";
	import { useRouter } from "vue-router";
	const email = ref("");
	const emailError = ref(); 
	const password = ref("");
	const passwordError = ref();
	const errMsg = ref() //ERROR Message
	const router = useRouter()//get a reference to our vue router
	var errors = [];
	function validEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
	function updateEmail(event){
		emailError.value = false;
	}
	function updatePassword(event){
		passwordError.value = false;
	}
	//errors.push('Valid email required.');

	const register = () =>{
		if (email.value === '' || email.value === null || email.value === 0) {
			errors.push('Email required.');
			emailError.value = true; 
		} else if (!validEmail(email.value)) {
			errors.push('Valid email required.');
			emailError.value = true; 
		}

		if (password.value === '' || password.value === null || password.value === 0) {
			errors.push('Password required.');
			passwordError.value = true; 
		} else if (password.value.length < 6) {
			errors.push('Valid Password required.');
			passwordError.value = true; 
		}
		// need .value because ref()
		console.log(errors.length);
		if(!errors.length){
			const auth = getAuth(); //fromm firebase/auth
			signInWithEmailAndPassword(auth, email.value, password.value).then((data) => {
				console.log("Successfully registered");
				console.log(auth.currentUser);
				router.push('/map-list')
			}).catch((error) => {
				console.log(error.code);
				switch(error.code){
					case "auth/invalid-email":
						errMsg.value = "Invalid email";
						break;
					case "auth/user-not-found":
						errMsg.value = "No account with that email was found";
						break;
					case "auth/wrong-password":
						errMsg.value = "Incorrect password";
						break;
					default:
						errMsg.value = "Email or password was incorrect";
						break;
				}
				
				//alert(error.message);
				errors = [];
			})
		}
		errors = [];
		/**/
	};

	const signInWithGoogle = () =>{

	};

</script>