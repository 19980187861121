<template>
<section class="comp-section comp-dropdowns">
							<div class="comp-header">
                                <h3 class="comp-title">Dropdowns</h3>
                                <div class="line"></div>
                            </div>
							<div class="card">
								<div class="card-body">
									<h4 class="card-title">Dropdowns within Text</h4>
									<div class="dropdown">
										<a class="dropdown-toggle" href="javascript:void(0);" role="button" data-bs-toggle="dropdown" aria-expanded="false"> Dropdown </a>
										<div class="dropdown-menu">
											<a class="dropdown-item" href="javascript:void(0);">Action</a>
											<a class="dropdown-item" href="javascript:void(0);">Another action</a>
										</div>
									</div>
									<hr>
									<h4 class="card-title">Dropdowns within Buttons</h4>
									<div class="btn-group">
										<button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Action</button>
										<div class="dropdown-menu">
											<a class="dropdown-item" href="javascript:void(0);">Action</a>
											<a class="dropdown-item" href="javascript:void(0);">Another action</a>
											<div class="dropdown-divider"></div>
											<a class="dropdown-item" href="javascript:void(0);">Separated link</a>
										</div>
									</div>
									<div class="btn-group ms-1">
										<button type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Action</button>
										<div class="dropdown-menu">
											<a class="dropdown-item" href="javascript:void(0);">Action</a>
											<a class="dropdown-item" href="javascript:void(0);">Another action</a>
											<div class="dropdown-divider"></div>
											<a class="dropdown-item" href="javascript:void(0);">Separated link</a>
										</div>
									</div>
									<div class="btn-group ms-1">
										<button type="button" class="btn btn-info dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Action</button>
										<div class="dropdown-menu">
											<a class="dropdown-item" href="javascript:void(0);">Action</a>
											<a class="dropdown-item" href="javascript:void(0);">Another action</a>
											<div class="dropdown-divider"></div>
											<a class="dropdown-item" href="javascript:void(0);">Separated link</a>
										</div>
									</div>
									<div class="btn-group ms-1">
										<button type="button" class="btn btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Action</button>
										<div class="dropdown-menu">
											<a class="dropdown-item" href="javascript:void(0);">Action</a>
											<a class="dropdown-item" href="javascript:void(0);">Another action</a>
											<div class="dropdown-divider"></div>
											<a class="dropdown-item" href="javascript:void(0);">Separated link</a>
										</div>
									</div>
									<div class="btn-group ms-1">
										<button type="button" class="btn btn-warning dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Action</button>
										<div class="dropdown-menu">
											<a class="dropdown-item" href="javascript:void(0);">Action</a>
											<a class="dropdown-item" href="javascript:void(0);">Another action</a>
											<div class="dropdown-divider"></div>
											<a class="dropdown-item" href="javascript:void(0);">Separated link</a>
										</div>
									</div>
									<div class="btn-group ms-1">
										<button type="button" class="btn btn-danger dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Action</button>
										<div class="dropdown-menu">
											<a class="dropdown-item" href="javascript:void(0);">Action</a>
											<a class="dropdown-item" href="javascript:void(0);">Another action</a>
											<div class="dropdown-divider"></div>
											<a class="dropdown-item" href="javascript:void(0);">Separated link</a>
										</div>
									</div>
									<hr>
									<h4 class="card-title">Split button dropdowns</h4>
									<div class="btn-group">
										<button type="button" class="btn btn-primary">Action</button>
										<button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<span class="visually-hidden">Toggle Dropdown</span>
										</button>
										<div class="dropdown-menu">
											<a class="dropdown-item" href="javascript:void(0);">Action</a>
											<a class="dropdown-item" href="javascript:void(0);">Another action</a>
											<div class="dropdown-divider"></div>
											<a class="dropdown-item" href="javascript:void(0);">Separated link</a>
										</div>
									</div>
									<div class="btn-group ms-1">
										<button type="button" class="btn btn-secondary">Action</button>
										<button type="button" class="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<span class="visually-hidden">Toggle Dropdown</span>
										</button>
										<div class="dropdown-menu">
											<a class="dropdown-item" href="javascript:void(0);">Action</a>
											<a class="dropdown-item" href="javascript:void(0);">Another action</a>
											<div class="dropdown-divider"></div>
											<a class="dropdown-item" href="javascript:void(0);">Separated link</a>
										</div>
									</div>
									<div class="btn-group ms-1">
										<button type="button" class="btn btn-info">Action</button>
										<button type="button" class="btn btn-info dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<span class="visually-hidden">Toggle Dropdown</span>
										</button>
										<div class="dropdown-menu">
											<a class="dropdown-item" href="javascript:void(0);">Action</a>
											<a class="dropdown-item" href="javascript:void(0);">Another action</a>
											<div class="dropdown-divider"></div>
											<a class="dropdown-item" href="javascript:void(0);">Separated link</a>
										</div>
									</div>
									<div class="btn-group ms-1">
										<button type="button" class="btn btn-success">Action</button>
										<button type="button" class="btn btn-success dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<span class="visually-hidden">Toggle Dropdown</span>
										</button>
										<div class="dropdown-menu">
											<a class="dropdown-item" href="javascript:void(0);">Action</a>
											<a class="dropdown-item" href="javascript:void(0);">Another action</a>
											<div class="dropdown-divider"></div>
											<a class="dropdown-item" href="javascript:void(0);">Separated link</a>
										</div>
									</div>
									<div class="btn-group ms-1">
										<button type="button" class="btn btn-warning">Action</button>
										<button type="button" class="btn btn-warning dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<span class="visually-hidden">Toggle Dropdown</span>
										</button>
										<div class="dropdown-menu">
											<a class="dropdown-item" href="javascript:void(0);">Action</a>
											<a class="dropdown-item" href="javascript:void(0);">Another action</a>
											<div class="dropdown-divider"></div>
											<a class="dropdown-item" href="javascript:void(0);">Separated link</a>
										</div>
									</div>
									<div class="btn-group ms-1">
										<button type="button" class="btn btn-danger">Action</button>
										<button type="button" class="btn btn-danger dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<span class="visually-hidden">Toggle Dropdown</span>
										</button>
										<div class="dropdown-menu">
											<a class="dropdown-item" href="javascript:void(0);">Action</a>
											<a class="dropdown-item" href="javascript:void(0);">Another action</a>
											<div class="dropdown-divider"></div>
											<a class="dropdown-item" href="javascript:void(0);">Separated link</a>
										</div>
									</div>
								</div>
							</div>
						</section>
</template>