<template>
<section class="contact-section">
                <div class="container">
                    <div class="row mb-5">
                        <div class="col-md-12 text-center">
                            <h3 class="mb-4">Contact Us</h3>
                            <p>Great doctor if you need your family member to get effective immediate assistance, emergency treatment or a simple consultation.</p>
                        </div>
                    </div>
					<div class="row">
						<div class="col-md-4 d-flex">
							<div class="contact-box w-100 d-flex flex-wrap">
								<div class="infor-img">
									<div class="image-circle">
										<i class="feather-phone"></i>
									</div>									
								</div>
								<div class="infor-details text-center">
									<label>Phone Number</label>
									<p>+152 534-468-854</p>
								</div>
							</div>
						</div>
						<div class="col-md-4 d-flex">
							<div class="contact-box w-100 d-flex flex-wrap">
								<div class="infor-img">
									<div class="image-circle bg-primary">
										<i class="feather-mail"></i>
									</div>									
								</div>
								<div class="infor-details text-center">
									<label>Email</label>
									<p>contact@example.com</p>
								</div>
							</div>
						</div>
						<div class="col-md-4 d-flex">
							<div class="contact-box flex-fill">
								<div class="infor-img">
									<div class="image-circle">
										<i class="feather-map-pin"></i>
									</div>									
								</div>
								<div class="infor-details text-center">
									<label>Location</label>
									<p>C/54 Northwest Freeway, Suite 558,
										Houston, USA 485</p>
								</div>
							</div>
						</div>
					</div>
                </div>
            </section>
</template>