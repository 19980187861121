<template>
<div class="profile-header">
                            <div class="row align-items-center">
                                <div class="col-auto profile-image">
                                    <a href="javascript:void(0);">
                                        <img class="rounded-circle" alt="User Image" src="../../../../../assets/admin_img/profiles/avatar-01.jpg">
                                    </a>
                                </div>
                                <div class="col ml-md-n2 profile-user-info">
                                    <h4 class="user-name mb-0">James Ballenger</h4>
                                    <h6 class="text-muted">ryantaylor@admin.com</h6>
                                    <div class="user-Location"><i class="fa fa-map-marker"></i> Florida, United States</div>
                                    <div class="about-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>
                                </div>
                                <div class="col-auto profile-btn">
                                    
                                    <a href="" class="btn btn-primary">
                                        Edit
                                    </a>
                                </div>
                            </div>
                        </div>
</template>