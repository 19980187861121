<template>
	<div class="main-wrapper">
		
        <layout-adminheader></layout-adminheader>
		
        <layout-adminfooter></layout-adminfooter>
        
        <!-- Page Wrapper -->
        <div class="page-wrapper">
            <div class="content container-fluid">
            
                <!-- Page Header -->
                <breadcrumb36></breadcrumb36>
                <!-- /Page Header -->
                
                <div class="row">
                    <div class="col-sm-12">
                        Contents here
                    </div>			
                </div>
                
            </div>			
        </div>
        <!-- /Page Wrapper -->
		
	</div>
</template>