<template>
<!-- Address -->
			<section class="address-section">
				<div class="container">
					<div class="row">
						<div class="col-12 col-md-6">
							<address class="m-0 d-flex align-items-center">
								<span class="d-flex align-items-center justify-content-center map-icon">
									<i class="fas fa-map-marker-alt"></i>
								</span>
								<span>
									3556 Beech Street, San Francisco,<br>California, CA 94108
								</span>
							</address>
						</div>
						<div class="col-12 col-md-6">
							<div class="social-links">
								<ul>
									<li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
									<li><a href="#"><i class="fab fa-twitter"></i></a></li>
									<li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
									<li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
									<li><a href="#"><i class="fab fa-youtube"></i></a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- /Address -->
</template>