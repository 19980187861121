<template>

    <div class="main-wrapper">

        <layout-patientheader></layout-patientheader>

        <!-- Page Content -->
			<div class="content">
				<div class="container">
				
					<!-- Call Wrapper -->
					<div class="call-wrapper">
						<div class="call-main-row">
							<div class="call-main-wrapper">
								<div class="call-view">
									<div class="call-window">
									
										<voicecallheader></voicecallheader>
										
										<!-- Call Footer -->
										<div class="call-footer">
											<div class="call-icons">
												<ul class="call-items">
													<li class="call-item">
														<a href="javascript:void(0);" title="Enable Video" data-placement="top" data-bs-toggle="tooltip">
															<i class="fas fa-video camera"></i>
														</a>
													</li>
													<li class="call-item">
														<a href="javascript:void(0);" title="Mute" data-placement="top" data-bs-toggle="tooltip">
															<i class="fa fa-microphone microphone"></i>
														</a>
													</li>
													<li class="call-item">
														<a href="javascript:void(0);" title="Add User" data-placement="top" data-bs-toggle="tooltip">
															<i class="fa fa-user-plus"></i>
														</a>
													</li>
												</ul>
												<div class="end-call">
													<a href="javascript:void(0);">
														<i class="material-icons">call_end</i>
													</a>
												</div>
											</div>
										</div>
										<!-- /Call Footer -->
									
									</div>
								</div>
								
							</div>
						</div>
					</div>
					<!-- /Call Wrapper -->
					
				</div>

			</div>		
			<!-- /Page Content -->

    </div>
	
</template>

<script>
export default {
    data() {
        return {
        }
    }
}
</script>