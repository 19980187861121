<template>
	<div class="main-wrapper">
		
        <layout-adminheader></layout-adminheader>
		
        <layout-adminfooter></layout-adminfooter>
        
        <!-- Page Wrapper -->
        <div class="page-wrapper">
            <div class="content container-fluid">
            
                <!-- Invoice Container -->
                <div class="invoice-container">
                    
                    <invoicecontent></invoicecontent>
                    
                    <div class="table-responsive">
                        <invoicetable></invoicetable>
                    </div>
                    
                    <div>
                        <invoicedue></invoicedue>
                        <div class="invoice-info">
                            <h5>Other information</h5>
                            <p class="text-muted mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sed dictum ligula, cursus blandit risus. Maecenas eget metus non tellus dignissim aliquam ut a ex. Maecenas sed vehicula dui, ac suscipit lacus. Sed finibus leo vitae lorem interdum, eu scelerisque tellus fermentum. Curabitur sit amet lacinia lorem. Nullam finibus pellentesque libero.</p>
                        </div>
                    </div>
                    
                </div>
                <!-- /Invoice Container -->
                
            </div>			
        </div>
        <!-- /Page Wrapper -->
		
	</div>
</template>