<template>
	<section class="section section-featurebox">
		<div class="container">
			<div class="section-header text-center">
				<h2>Available Features in Our Clinic</h2>
				<p class="sub-title">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
			</div>
			<div class="row justify-content-center">
				<div class="feature-col-list">
					<div class="feature-col" v-for="item in clinic" :key="item.id">
						<div class="feature-subox d-flex flex-wrap justify-content-center">
							<img :src="loadImg(item.img)" class="img-fluid" alt="Features">
							<h4>{{item.title}}</h4>
						</div>
					</div>
				</div>
			</div>
		</div>
		
	</section>
</template>
<script>
	import clinic from '../../../../../assets/json/frontend/page/about/clinic.json'
	const images = require.context('../../../../../assets/img/features', false, /\.png$|\.jpg$/)
	export default {
	mounted() {
	},
	methods:{
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
    },
	data() {
        return {
            clinic: clinic
        }
    }
	}
	</script>