<template>
<section class="comp-section">
							<div class="comp-header">
                                <h3 class="comp-title">Pagination</h3>
                                <div class="line"></div>
                            </div>
								<div class="card">
								<div class="card-body">
									<div>
										<ul class="pagination">
											<li class="page-item disabled">
												<a class="page-link" href="javascript:void(0);" tabindex="-1">Previous</a>
											</li>
											<li class="page-item"><a class="page-link" href="javascript:void(0);">1</a></li>
											<li class="page-item active">
												<a class="page-link" href="javascript:void(0);">2 <span class="visually-hidden">(current)</span></a>
											</li>
											<li class="page-item"><a class="page-link" href="javascript:void(0);">3</a></li>
											<li class="page-item">
												<a class="page-link" href="javascript:void(0);">Next</a>
											</li>
										</ul>
									</div>
									<div>
										<ul class="pagination">
											<li class="page-item">
												<a class="page-link" href="javascript:void(0);" aria-label="Previous">
													<span aria-hidden="true">&laquo;</span>
													<span class="visually-hidden">Previous</span>
												</a>
											</li>
											<li class="page-item"><a class="page-link" href="javascript:void(0);">1</a></li>
											<li class="page-item"><a class="page-link" href="javascript:void(0);">2</a></li>
											<li class="page-item"><a class="page-link" href="javascript:void(0);">3</a></li>
											<li class="page-item">
												<a class="page-link" href="javascript:void(0);" aria-label="Next">
													<span aria-hidden="true">&raquo;</span>
												<span class="visually-hidden">Next</span>
												</a>
											</li>
										</ul>
									</div>
									<div>
										<ul class="pagination pagination-lg">
											<li class="page-item disabled">
												<a class="page-link" href="javascript:void(0);" tabindex="-1">Previous</a>
											</li>
											<li class="page-item"><a class="page-link" href="javascript:void(0);">1</a></li>
											<li class="page-item active">
												<a class="page-link" href="javascript:void(0);">2 <span class="visually-hidden">(current)</span></a>
											</li>
											<li class="page-item"><a class="page-link" href="javascript:void(0);">3</a></li>
											<li class="page-item">
												<a class="page-link" href="javascript:void(0);">Next</a>
											</li>
										</ul>
									</div>
									
									<div>
										<ul class="pagination pagination-sm mb-0">
											<li class="page-item disabled">
												<a class="page-link" href="javascript:void(0);" tabindex="-1">Previous</a>
											</li>
											<li class="page-item"><a class="page-link" href="javascript:void(0);">1</a></li>
											<li class="page-item active">
												<a class="page-link" href="javascript:void(0);">2 <span class="visually-hidden">(current)</span></a>
											</li>
											<li class="page-item"><a class="page-link" href="javascript:void(0);">3</a></li>
											<li class="page-item">
												<a class="page-link" href="javascript:void(0);">Next</a>
											</li>
										</ul>
									</div>
								</div>
								</div>
						</section>
</template>