<template>

    <div class="main-wrapper">

        <layout-patientheader></layout-patientheader>

        <!-- Breadcrumb -->
			<breadcrumb4></breadcrumb4>
			<!-- /Breadcrumb -->
			
			<!-- Page Content -->
			<div class="content">
				<div class="container-fluid">

					<div class="row">
						
						<!-- Profile Sidebar -->
						<layout-patientsidebar></layout-patientsidebar>
						<!-- / Profile Sidebar -->
						
						<div class="col-md-7 col-lg-8 col-xl-9">
							<div class="card">
								<div class="card-body pt-0">
								
									<!-- Tab Menu -->
									<nav class="user-tabs mb-4">
										<ul class="nav nav-tabs nav-tabs-bottom nav-justified">
											<li class="nav-item">
												<a class="nav-link active" href="#pat_appointments" data-bs-toggle="tab">Upcoming</a>
											</li>
											<li class="nav-item">
												<a class="nav-link" href="#pat_prescriptions" data-bs-toggle="tab">Requested</a>
											</li>
											<li class="nav-item">
												<a class="nav-link" href="#pat_billing" data-bs-toggle="tab">History</a>
											</li>
										</ul>
									</nav>
									<!-- /Tab Menu -->
									
									<!-- Tab Content -->
									<div class="tab-content pt-0">
										
										<!-- Upcoming Tab -->
										<div id="pat_appointments" class="tab-pane fade show active">
											<div class="card card-table mb-0">
												<div class="card-body">
                                                    <p class="ms-4 mt-4"><span class="available">PATIENT:   </span> <input type="text" value="Any" class="any-intake-box"></p>
													<div class="table-responsive">
														<table class="table table-hover table-center mb-0">
															<thead>
																<tr class="patient-appoint">
																	<th>Patient</th>
																	<th>Details</th>
																	<th>Doctor</th>
																	<th>Appointment Date</th>
																	<th></th>
																</tr>
															</thead>
															<tbody>
																<tr v-for="item in appointmentdetail" :key="item.id">
                                  <td>
																		<h2 class="table-avatar">
																			<router-link to="/patient-profile" class="patient-name-appoint">{{item.name}}</router-link>
																		</h2>
																	</td>
                                  <td>
																		<div class="table-action">
																			<a href="javascript:void(0);" class="btn btn-sm bg-info-light">
																				<i class="far fa-eye"></i> {{item.view}}
																			</a>
																		</div>
																	</td>
																	<td>
																		<h2 class="table-avatar">
																			<router-link to="/doctor-profile" class="avatar avatar-sm me-2">
																				<img class="avatar-img rounded-circle" :src="loadImg(item.img)" alt="User Image">
                                                                            </router-link>
																			<router-link to="/doctor-profile">{{item.name1}} </router-link>
																		</h2>
																	</td>
																	<td>{{item.date}}
                                    <span class="d-block text-info">{{item.time}}</span>
                                  </td>
																	<td>
                                  <i class="fas fa-video appoint-video"></i>  
                                   <p class="go-visit mb-0">{{item.type}}</p>
                                  </td>
                                </tr>
																
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>
										<!-- /Upcoming Tab -->
										
										<!-- Requested Tab -->
										<div class="tab-pane fade" id="pat_prescriptions">
											<div class="card card-table mb-0">
												<div class="card-body">
													<div class="table-responsive">
														<table class="table table-hover table-center mb-0">	
														</table>
													</div>
												</div>
											</div>
										</div>
										<!-- /Requested Tab -->
											
										
										
										<!-- History Tab -->
										<div id="pat_billing" class="tab-pane fade">
											<div class="card card-table mb-0">
												<div class="card-body">
                                                    <p class="ms-4 mt-4"><span class="available">PATIENT:   </span> <input type="text" value="Any" class="any-intake-box"></p>
													<div class="table-responsive">
														<table class="table table-hover table-center mb-0">
															<thead>
																<tr class="patient-appoint">
																	<th>Patient</th>
																	<th>Details</th>
                                  <th>Doctor</th>
                                  <th>Survey</th>
                                  <th>Med.</th>
																	<th>Appointment Date</th>
																	<th>Share</th>
																</tr>
															</thead>
															<tbody>
																<tr v-for="item in appointmentdetail1" :key="item.id">
                                  <td>
																		<h2 class="table-avatar">
																			<router-link to="/patient-profile" class="patient-name-appoint">{{item.name}}</router-link>
																		</h2>
																	</td>
                                  <td>
																		<div class="table-action">
																			<a href="javascript:void(0);" class="btn btn-sm bg-info-light">
																				<i class="far fa-eye"></i> {{item.view}}
																			</a>
																		</div>
																	</td>
																	<td>
																		<h2 class="table-avatar">
																			<router-link to="/doctor-profile" class="avatar avatar-sm me-2">
																				<img class="avatar-img rounded-circle" :src="loadImg1(item.img1)" alt="User Image">
                                                                            </router-link>
																			<router-link to="/doctor-profile" >{{item.name1}} </router-link>
																		</h2>
                                  </td>
                                  <td>
																		<div class="table-action">
																			<a href="javascript:void(0);" class="btn btn-sm bg-info-light me-1">
																				<i class="far fa-eye"></i> {{item.view1}}
                                      </a>
                                      <a href="javascript:void(0);" class="btn btn-sm bg-primary-light">
																				<i class="fas fa-print"></i> {{item.print}}
																			</a>
																		</div>
                                  </td>
                                  <td>
																		<div class="table-action">
																			<a href="javascript:void(0);" class="btn btn-sm bg-info-light me-1">
																				<i class="far fa-eye"></i> {{item.view2}}
                                      </a>
                                      <a href="javascript:void(0);" class="btn btn-sm bg-primary-light">
																				<i class="fas fa-print"></i> {{item.view1}}
																			</a>
																		</div>
																	</td>
																	<td>{{item.date}}
                                    <span class="d-block text-info">{{item.time}}</span>
                                  </td>
																	<td>
                                  <i class="fa fa-check"></i> 
                                  </td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>
										<!-- /History Tab -->
										
									</div>
									<!-- Tab Content -->
									
								</div>
							</div>
						</div>
					</div>

				</div>

			</div>		
			<!-- /Page Content -->

            <layout-footer></layout-footer>

    </div>
	
</template>
<script>
	import appointmentdetail from '../../../../../../assets/json/frontend/patient/appointmentdetail.json'
    import appointmentdetail1 from '../../../../../../assets/json/frontend/patient/appointmentdetail1.json'
	const images = require.context('../../../../../../assets/img/doctors', false, /\.png$|\.jpg$/)
    const images1 = require.context('../../../../../../assets/img/doctors', false, /\.png$|\.jpg$/)
	export default {
	mounted() {
	},
	methods:{
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
        loadImg1(img1Path) {
            return images1('./' + img1Path).default
        },
    },
	data() {
        return {
            appointmentdetail: appointmentdetail,
            appointmentdetail1: appointmentdetail1
        }
    }
	}
	</script>