<template>
<!-- Alerts -->
                    <section class="comp-section">
                        <div class="section-header">
                            <h3 class="section-title">Alerts</h3>
                            <div class="line"></div>
                        </div>
                        <div class="card">
                            <div class="card-body">
                                <div class="alert alert-primary alert-dismissible fade show" role="alert">
                                    <strong>Holy guacamole!</strong> You should check in on some of those fields below.
                                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                                <div class="alert alert-secondary alert-dismissible fade show" role="alert">
                                    <strong>Holy guacamole!</strong> You should check in on some of those fields below.
                                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                                <div class="alert alert-warning alert-dismissible fade show" role="alert">
                                    <strong>Warning!</strong> There was a problem with your <a href="javascript:void(0);" class="alert-link">network connection</a>.
                                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                                <div class="alert alert-danger alert-dismissible fade show" role="alert">
                                    <strong>Error!</strong> A <a href="javascript:void(0);" class="alert-link">problem</a> has been occurred while submitting your data.
                                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                                <div class="alert alert-success alert-dismissible fade show" role="alert">
                                    <strong>Success!</strong> Your <a href="javascript:void(0);" class="alert-link">message</a> has been sent successfully.
                                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                                <div class="alert alert-info alert-dismissible fade show" role="alert">
                                    <strong>Note!</strong> Please read the <a href="javascript:void(0);" class="alert-link">comments</a> carefully.
                                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                                <div class="alert alert-light alert-dismissible fade show" role="alert">
                                    <strong>Holy guacamole!</strong> You should check in on some of those fields below.
                                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                                <div class="alert alert-dark alert-dismissible fade show mb-0" role="alert">
                                    <strong>Holy guacamole!</strong> You should check in on some of those fields below.
                                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                            </div>
                        </div>
                    </section>
                    <!-- /Alerts -->
                    
                    <!-- Breadcrumbs -->
                    <section class="comp-section">
                        <div class="section-header">
                            <h3 class="section-title">Breadcrumbs</h3>
                            <div class="line"></div>
                        </div>
                        <div class="card">
                            <div class="card-body">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item active" aria-current="page">Home</li>
                                    </ol>
                                </nav>
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                                        <li class="breadcrumb-item active" aria-current="page">Products</li>
                                    </ol>
                                </nav>
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb mb-0">
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Products</a></li>
                                        <li class="breadcrumb-item active" aria-current="page">Accessories</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </section>
                    <!-- /Breadcrumbs -->
</template>