<template>
<div id="pat_medical_records" class="tab-pane fade">
											<div class="card card-table mb-0">
												<div class="card-body">
													<div class="table-responsive">
														<table class="table table-hover table-center mb-0">
															<thead>
																<tr>
																	<th>ID</th>
																	<th>Date </th>
																	<th>Description</th>
																	<th>Attachment</th>
																	<th>Created</th>
																	<th></th>
																</tr>     
															</thead>
															<tbody>
																<tr>
																	<td><a href="javascript:void(0);">#MR-0010</a></td>
																	<td>14 Nov 2019</td>
																	<td>Dental Filling</td>
																	<td><a href="javascript:void(0);">dental-test.pdf</a></td>
																	<td>
																		<h2 class="table-avatar">
																			<router-link to="/doctor-profile" class="avatar avatar-sm me-2">
																				<img class="avatar-img rounded-circle" src="../../../../../assets/img/doctors/doctor-thumb-01.jpg" alt="User Image">
                                                                            </router-link>
																			<router-link to="/doctor-profile">Dr. Matthew Dodd <span>Dental</span></router-link>
																		</h2>
																	</td>
																	<td class="text-end">
																		<div class="table-action">
																			<a href="javascript:void(0);" class="btn btn-sm bg-info-light me-1">
																				<i class="far fa-eye"></i> View
																			</a>
																			<a href="javascript:void(0);" class="btn btn-sm bg-primary-light">
																				<i class="fas fa-print"></i> Print
																			</a>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td><a href="javascript:void(0);">#MR-0009</a></td>
																	<td>13 Nov 2019</td>
																	<td>Teeth Cleaning</td>
																	<td><a href="javascript:void(0);">dental-test.pdf</a></td>
																	<td>
																		<h2 class="table-avatar">
																			<router-link to="/doctor-profile" class="avatar avatar-sm me-2">
																				<img class="avatar-img rounded-circle" src="../../../../../assets/img/doctors/doctor-thumb-02.jpg" alt="User Image">
                                                                            </router-link>
																			<router-link to="/doctor-profile">Dr. Eric Buckles <span>Dental</span></router-link>
																		</h2>
																	</td>
																	<td class="text-end">
																		<div class="table-action">
																			<a href="javascript:void(0);" class="btn btn-sm bg-info-light me-1">
																				<i class="far fa-eye"></i> View
																			</a>
																			<a href="javascript:void(0);" class="btn btn-sm bg-primary-light">
																				<i class="fas fa-print"></i> Print
																			</a>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td><a href="javascript:void(0);">#MR-0008</a></td>
																	<td>12 Nov 2019</td>
																	<td>General Checkup</td>
																	<td><a href="javascript:void(0);">cardio-test.pdf</a></td>
																	<td>
																		<h2 class="table-avatar">
																			<router-link to="/doctor-profile" class="avatar avatar-sm me-2">
																				<img class="avatar-img rounded-circle" src="../../../../../assets/img/doctors/doctor-thumb-03.jpg" alt="User Image">
                                                                            </router-link>
																			<router-link to="/doctor-profile">Dr. Lane Seay <span>Cardiology</span></router-link>
																		</h2>
																	</td>
																	<td class="text-end">
																		<div class="table-action">
																			<a href="javascript:void(0);" class="btn btn-sm bg-info-light me-1">
																				<i class="far fa-eye"></i> View
																			</a>
																			<a href="javascript:void(0);" class="btn btn-sm bg-primary-light">
																				<i class="fas fa-print"></i> Print
																			</a>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td><a href="javascript:void(0);">#MR-0007</a></td>
																	<td>11 Nov 2019</td>
																	<td>General Test</td>
																	<td><a href="javascript:void(0);">general-test.pdf</a></td>
																	<td>
																		<h2 class="table-avatar">
																			<router-link to="/doctor-profile" class="avatar avatar-sm me-2">
																				<img class="avatar-img rounded-circle" src="../../../../../assets/img/doctors/doctor-thumb-04.jpg" alt="User Image">
                                                                            </router-link>
																			<router-link to="/doctor-profile">Dr. Sofia Brient <span>Urology</span></router-link>
																		</h2>
																	</td>
																	<td class="text-end">
																		<div class="table-action">
																			<a href="javascript:void(0);" class="btn btn-sm bg-info-light me-1">
																				<i class="far fa-eye"></i> View
																			</a>
																			<a href="javascript:void(0);" class="btn btn-sm bg-primary-light">
																				<i class="fas fa-print"></i> Print
																			</a>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td><a href="javascript:void(0);">#MR-0006</a></td>
																	<td>10 Nov 2019</td>
																	<td>Eye Test</td>
																	<td><a href="javascript:void(0);">eye-test.pdf</a></td>
																	<td>
																		<h2 class="table-avatar">
																			<router-link to="/doctor-profile" class="avatar avatar-sm me-2">
																				<img class="avatar-img rounded-circle" src="../../../../../assets/img/doctors/doctor-thumb-05.jpg" alt="User Image">
                                                                            </router-link>
																			<router-link to="/doctor-profile">Dr. Marvin Campbell <span>Ophthalmology</span></router-link>
																		</h2>
																	</td>
																	<td class="text-end">
																		<div class="table-action">
																			<a href="javascript:void(0);" class="btn btn-sm bg-info-light me-1">
																				<i class="far fa-eye"></i> View
																			</a>
																			<a href="javascript:void(0);" class="btn btn-sm bg-primary-light">
																				<i class="fas fa-print"></i> Print
																			</a>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td><a href="javascript:void(0);">#MR-0005</a></td>
																	<td>9 Nov 2019</td>
																	<td>Leg Pain</td>
																	<td><a href="javascript:void(0);">ortho-test.pdf</a></td>
																	<td>
																		<h2 class="table-avatar">
																			<router-link to="/doctor-profile" class="avatar avatar-sm me-2">
																				<img class="avatar-img rounded-circle" src="../../../../../assets/img/doctors/doctor-thumb-06.jpg" alt="User Image">
                                                                            </router-link>
																			<router-link to="/doctor-profile">Dr. Katharine Berthold <span>Orthopaedics</span></router-link>
																		</h2>
																	</td>
																	<td class="text-end">
																		<div class="table-action">
																			<a href="javascript:void(0);" class="btn btn-sm bg-info-light me-1">
																				<i class="far fa-eye"></i> View
																			</a>
																			<a href="javascript:void(0);" class="btn btn-sm bg-primary-light">
																				<i class="fas fa-print"></i> Print
																			</a>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td><a href="javascript:void(0);">#MR-0004</a></td>
																	<td>8 Nov 2019</td>
																	<td>Head pain</td>
																	<td><a href="javascript:void(0);">neuro-test.pdf</a></td>
																	<td>
																		<h2 class="table-avatar">
																			<router-link to="/doctor-profile" class="avatar avatar-sm me-2">
																				<img class="avatar-img rounded-circle" src="../../../../../assets/img/doctors/doctor-thumb-07.jpg" alt="User Image">
                                                                            </router-link>
																			<router-link to="/doctor-profile">Dr. Linda Tobin <span>Neurology</span></router-link>
																		</h2>
																	</td>
																	<td class="text-end">
																		<div class="table-action">
																			<a href="javascript:void(0);" class="btn btn-sm bg-info-light me-1">
																				<i class="far fa-eye"></i> View
																			</a>
																			<a href="javascript:void(0);" class="btn btn-sm bg-primary-light">
																				<i class="fas fa-print"></i> Print
																			</a>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td><a href="javascript:void(0);">#MR-0003</a></td>
																	<td>7 Nov 2019</td>
																	<td>Skin Alergy</td>
																	<td><a href="javascript:void(0);">alergy-test.pdf</a></td>
																	<td>
																		<h2 class="table-avatar">
																			<router-link to="/doctor-profile" class="avatar avatar-sm me-2">
																				<img class="avatar-img rounded-circle" src="../../../../../assets/img/doctors/doctor-thumb-08.jpg" alt="User Image">
                                                                            </router-link>
																			<router-link to="/doctor-profile">Dr. Paul Richard <span>Dermatology</span></router-link>
																		</h2>
																	</td>
																	<td class="text-end">
																		<div class="table-action">
																			<a href="javascript:void(0);" class="btn btn-sm bg-info-light me-1">
																				<i class="far fa-eye"></i> View
																			</a>
																			<a href="javascript:void(0);" class="btn btn-sm bg-primary-light">
																				<i class="fas fa-print"></i> Print
																			</a>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td><a href="javascript:void(0);">#MR-0002</a></td>
																	<td>6 Nov 2019</td>
																	<td>Dental Removing</td>
																	<td><a href="javascript:void(0);">dental-test.pdf</a></td>
																	<td>
																		<h2 class="table-avatar">
																			<router-link to="/doctor-profile" class="avatar avatar-sm me-2">
																				<img class="avatar-img rounded-circle" src="../../../../../assets/img/doctors/doctor-thumb-09.jpg" alt="User Image">
                                                                            </router-link>
																			<router-link to="/doctor-profile">Dr. John Gibbs <span>Dental</span></router-link>
																		</h2>
																	</td>
																	<td class="text-end">
																		<div class="table-action">
																			<a href="javascript:void(0);" class="btn btn-sm bg-info-light me-1">
																				<i class="far fa-eye"></i> View
																			</a>
																			<a href="javascript:void(0);" class="btn btn-sm bg-primary-light">
																				<i class="fas fa-print"></i> Print
																			</a>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td><a href="javascript:void(0);">#MR-0001</a></td>
																	<td>5 Nov 2019</td>
																	<td>Dental Filling</td>
																	<td><a href="javascript:void(0);">dental-test.pdf</a></td>
																	<td>
																		<h2 class="table-avatar">
																			<router-link to="/doctor-profile" class="avatar avatar-sm me-2">
																				<img class="avatar-img rounded-circle" src="../../../../../assets/img/doctors/doctor-thumb-10.jpg" alt="User Image">
                                                                            </router-link>
																			<router-link to="/doctor-profile">Dr. Olga Barlow <span>Dental</span></router-link>
																		</h2>
																	</td>
																	<td class="text-end">
																		<div class="table-action">
																			<a href="javascript:void(0);" class="btn btn-sm bg-info-light me-1">
																				<i class="far fa-eye"></i> View
																			</a>
																			<a href="javascript:void(0);" class="btn btn-sm bg-primary-light">
																				<i class="fas fa-print"></i> Print
																			</a>
																		</div>
																	</td>
																</tr>
															</tbody>  	
														</table>
													</div>
												</div>
											</div>
										</div>
</template>