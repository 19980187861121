<template>
<section class="comp-section">
							<div class="comp-header">
                                <h3 class="comp-title">Typography</h3>
                                <div class="line"></div>
                            </div>

							<div class="row">
								<div class="col-md-12">
									<div class="card">
										<div class="card-header">
											<h4 class="card-title">Headings</h4>
										</div>
										<div class="card-body">
											<h1>h1. Bootstrap heading</h1>
											<h2>h2. Bootstrap heading</h2>
											<h3>h3. Bootstrap heading</h3>
											<h4>h4. Bootstrap heading</h4>
											<h5>h5. Bootstrap heading</h5>
											<h6>h6. Bootstrap heading</h6>
										</div>
									</div>
									<div class="card">
										<div class="card-header">
											<h4 class="card-title">Blockquotes</h4>
										</div>
										<div class="card-body">
											<blockquote>
												<p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
											</blockquote>
											<blockquote class="blockquote mb-0">
												<p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
											</blockquote>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-4 d-flex">
									<div class="card flex-fill">
										<div class="card-header">
											<h4 class="card-title">Text element</h4>
										</div>
										<div class="card-body">
											<p>You can use the mark tag to <mark>highlight</mark> text.</p>
											<p><del>This line of text is meant to be treated as deleted text.</del></p>
											<p><s>This line of text is meant to be treated as no longer accurate.</s></p>
											<p><ins>This line of text is meant to be treated as an addition to the document.</ins></p>
											<p><u>This line of text will render as underlined</u></p>
											<p><small>This line of text is meant to be treated as fine print.</small></p>
											<p><strong>This line rendered as bold text.</strong></p>
											<p><em>This line rendered as italicized text.</em></p>
											<p class="text-monospace mb-0">This is in monospace</p>
										</div>
									</div>
								</div>
								<div class="col-md-4 d-flex">
									<div class="card flex-fill">
										<div class="card-header">
											<h4 class="card-title">Coloured Link</h4>
										</div>
										<div class="card-body">
											<p class="text-primary">.text-primary</p>
											<p class="text-secondary">.text-secondary</p>
											<p class="text-success">.text-success</p>
											<p class="text-danger">.text-danger</p>
											<p class="text-warning">.text-warning</p>
											<p class="text-info">.text-info</p>
											<p class="text-light bg-dark">.text-light</p>
											<p class="text-dark">.text-dark</p>
											<p class="text-muted">.text-muted</p>
											<p class="text-white bg-dark mb-0">.text-white</p>
										</div>
									</div>
								</div>
								<div class="col-md-4 d-flex">
									<div class="card flex-fill">
										<div class="card-header">
											<h4 class="card-title">Coloured text</h4>
										</div>
										<div class="card-body">
											<p><a href="javascript:void(0);" class="text-primary">Primary link</a></p>
											<p><a href="javascript:void(0);" class="text-secondary">Secondary link</a></p>
											<p><a href="javascript:void(0);" class="text-success">Success link</a></p>
											<p><a href="javascript:void(0);" class="text-danger">Danger link</a></p>
											<p><a href="javascript:void(0);" class="text-warning">Warning link</a></p>
											<p><a href="javascript:void(0);" class="text-info">Info link</a></p>
											<p><a href="javascript:void(0);" class="text-light bg-dark">Light link</a></p>
											<p><a href="javascript:void(0);" class="text-dark">Dark link</a></p>
											<p><a href="javascript:void(0);" class="text-muted">Muted link</a></p>
											<p><a href="javascript:void(0);" class="text-white bg-dark mb-0">White link</a></p>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-4 d-flex">
									<div class="card flex-fill">
										<div class="card-header">
											<h4 class="card-title">Bullet Lists</h4>
										</div>
										<div class="card-body">
											<ul class="mb-0">
												<li>Lorem ipsum dolor sit amet</li>
												<li>Consectetur adipiscing elit</li>
												<li>Integer molestie lorem at massa</li>
												<li>Facilisis in pretium nisl aliquet</li>
												<li>Nulla volutpat aliquam velit
													<ul>
														<li>Phasellus iaculis neque</li>
														<li>Purus sodales ultricies</li>
														<li>Vestibulum laoreet porttitor sem</li>
														<li>Ac tristique libero volutpat at</li>
													</ul>
												</li>
												<li>Faucibus porta lacus fringilla vel</li>
												<li>Aenean sit amet erat nunc</li>
												<li>Eget porttitor lorem</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="col-md-4 d-flex">
									<div class="card flex-fill">
										<div class="card-header">
											<h4 class="card-title">Bullet Lists</h4>
										</div>
										<div class="card-body">
											<ol class="mb-0">
												<li>Lorem ipsum dolor sit amet</li>
												<li>Consectetur adipiscing elit</li>
												<li>Integer molestie lorem at massa</li>
												<li>Facilisis in pretium nisl aliquet</li>
												<li>Nulla volutpat aliquam velit
													<ul>
														<li>Phasellus iaculis neque</li>
														<li>Purus sodales ultricies</li>
														<li>Vestibulum laoreet porttitor sem</li>
														<li>Ac tristique libero volutpat at</li>
													</ul>
												</li>
												<li>Faucibus porta lacus fringilla vel</li>
												<li>Aenean sit amet erat nunc</li>
												<li>Eget porttitor lorem</li>
											</ol>
										</div>
									</div>
								</div>
								<div class="col-md-4 d-flex">
									<div class="card flex-fill">
										<div class="card-header">
											<h4 class="card-title">Unstyled Lists</h4>
										</div>
										<div class="card-body">
											<ul class="list-unstyled mb-0">
												<li>Lorem ipsum dolor sit amet</li>
												<li>Consectetur adipiscing elit</li>
												<li>Integer molestie lorem at massa</li>
												<li>Facilisis in pretium nisl aliquet</li>
												<li>Nulla volutpat aliquam velit
													<ul>
														<li>Phasellus iaculis neque</li>
														<li>Purus sodales ultricies</li>
														<li>Vestibulum laoreet porttitor sem</li>
														<li>Ac tristique libero volutpat at</li>
													</ul>
												</li>
												<li>Faucibus porta lacus fringilla vel</li>
												<li>Aenean sit amet erat nunc</li>
												<li>Eget porttitor lorem</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</section>
</template>