<template>

    <div class="main-wrapper">

        <layout-header></layout-header>

        <!-- Breadcrumb -->
		<breadcrumb26></breadcrumb26>
		<!-- /Breadcrumb -->
			
			<!-- Page Content -->
			<div class="content">
				<div class="container-fluid">

					<div class="row">
						<div class="col-md-12 col-lg-4 col-xl-3 theiaStickySidebar">
						<div class="stickyside">
							<!-- Search Filter -->
							<div class="card search-filter">
								<div class="card-header">
									<h4 class="card-title mb-0">Search Filter</h4>
								</div>
								<div class="card-body">
								<div class="filter-widget">
                                    <div class="cal-icon">
                                        <datepicker v-model="startdate"  class="picker" 
                                        :editable="true"
                                        :clearable="false" />
                                    </div>			
								</div>
								<div class="filter-widget">
									<h4>Gender</h4>
									<div>
										<label class="custom_check">
											<input type="checkbox" name="gender_type" checked>
											<span class="checkmark"></span> Male Doctor
										</label>
									</div>
									<div>
										<label class="custom_check">
											<input type="checkbox" name="gender_type">
											<span class="checkmark"></span> Female Doctor
										</label>
									</div>
								</div>
								<div class="filter-widget">
									<h4>Select Specialist</h4>
									<div>
										<label class="custom_check">
											<input type="checkbox" name="select_specialist" checked>
											<span class="checkmark"></span> Urology
										</label>
									</div>
									<div>
										<label class="custom_check">
											<input type="checkbox" name="select_specialist" checked>
											<span class="checkmark"></span> Neurology
										</label>
									</div>
									<div>
										<label class="custom_check">
											<input type="checkbox" name="select_specialist">
											<span class="checkmark"></span> Dentist
										</label>
									</div>
									<div>
										<label class="custom_check">
											<input type="checkbox" name="select_specialist">
											<span class="checkmark"></span> Orthopedic
										</label>
									</div>
									<div>
										<label class="custom_check">
											<input type="checkbox" name="select_specialist">
											<span class="checkmark"></span> Cardiologist
										</label>
									</div>
									<div>
										<label class="custom_check">
											<input type="checkbox" name="select_specialist">
											<span class="checkmark"></span> Cardiologist
										</label>
									</div>
								</div>
									<div class="btn-search">
										<button type="button" class="btn w-100">Search</button>
									</div>	
								</div>
							</div>
							<!-- /Search Filter -->
						</div>
						</div>
						
						<div class="col-md-12 col-lg-8 col-xl-9">

							<!-- Doctor Widget -->
							<div class="card" v-for="item in searchdoctor" :key="item.id">
								<div class="card-body">
									<div class="doctor-widget">
										<div class="doc-info-left">
											<div class="doctor-img">
												<router-link to="/doctor-profile">
													<img :src="loadImg(item.img)" class="img-fluid" alt="User Image">
                                                </router-link>
											</div>
											<div class="doc-info-cont">
												<h4 class="doc-name"><router-link to="/doctor-profile">{{item.name}}</router-link></h4>
												<p class="doc-speciality">{{item.description}}</p>
												<p class="doc-department"><img :src="loadImg1(item.img1)" class="img-fluid" alt="Speciality">{{item.name1}}</p>
												<div class="rating mapgridrating">
													<i class="fas fa-star filled"></i>
													<i class="fas fa-star filled"></i>
													<i class="fas fa-star filled"></i>
													<i class="fas fa-star filled"></i>
													<i class="fas fa-star"></i>
													<span class="d-inline-block average-rating">{{item.rating}}</span>
												</div>
												<div class="clinic-details">
													<p class="doc-location"><i class="fas fa-map-marker-alt"></i> {{item.city}}</p>
													<ul class="clinic-gallery">
														<li class="me-1">
															<a class="fancystyle" data-fancybox="gallery">
																<img src="../../../../../assets/img/features/feature-01.jpg" alt="Feature">
															</a>
														</li>
														<li class="me-1">
															<a class="fancystyle" data-fancybox="gallery">
																<img  src="../../../../../assets/img/features/feature-02.jpg" alt="Feature">
															</a>
														</li>
														<li class="me-1">
															<a class="fancystyle" data-fancybox="gallery">
																<img src="../../../../../assets/img/features/feature-03.jpg" alt="Feature">
															</a>
														</li>
														<li>
															<a class="fancystyle" data-fancybox="gallery">
																<img src="../../../../../assets/img/features/feature-04.jpg" alt="Feature">
															</a>
														</li>
													</ul>
												</div>
												<div class="clinic-services">
													<span>{{item.test}}</span>
													<span> {{item.detail}}</span>
												</div>
											</div>
										</div>
										<div class="doc-info-right">
											<div class="clini-infos">
												<ul>
													<li><i class="far fa-thumbs-up"></i> {{item.rate}}</li>
													<li><i class="far fa-comment"></i> {{item.comment}}</li>
													<li><i class="fas fa-map-marker-alt"></i> {{item.country}}</li>
													<li><i class="far fa-money-bill-alt"></i> {{item.cost}} <i class="fas fa-info-circle" data-bs-toggle="tooltip" title="Lorem Ipsum"></i> </li>
												</ul>
											</div>
											<div class="clinic-booking">
												<router-link class="view-pro-btn" to="/doctor-profile">View Profile</router-link>
												<router-link class="apt-btn" to="/booking">Book Appointment</router-link>
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- /Doctor Widget -->

							<div class="load-more text-center">
								<a class="btn btn-primary btn-sm" href="javascript:void(0);">Load More</a>	
							</div>	
						</div>
					</div>

				</div>

			</div>		
			<!-- /Page Content -->

        <layout-footer></layout-footer>

    </div>
	
</template>
<script>
	import searchdoctor from '../../../../../assets/json/frontend/patient/searchdoctor.json'
	const images = require.context('../../../../../assets/img/doctors', false, /\.png$|\.jpg$/)
    const images1 = require.context('../../../../../assets/img/specialities', false, /\.png$|\.jpg$/)
    import { ref } from 'vue'
    const currentDate = ref(new Date())
	export default {
	mounted() {
	},
	methods:{
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
        loadImg1(img1Path) {
            return images1('./' + img1Path).default
        },
    },
	data() {
        return {
            searchdoctor: searchdoctor,
            startdate: currentDate
        }
    }
	}
	</script>