<template>
<div class="tab-pane show active" id="upcoming-appointments">
												<div class="card card-table mb-0">
													<div class="card-body">
														<div class="table-responsive">
															<table class="table table-hover table-center mb-0">
																<thead>
																	<tr>
																		<th>Client Name</th>
																		<th>Appt Date</th>
																		<th>Purpose</th>
																		<th>Type</th>
																		<th class="text-center">Paid Amount</th>
																		<th></th>
																	</tr>
																</thead>
																<tbody>
																	<tr v-for="item in upcoming" :key="item.id">
																		<td>
																			<h2 class="table-avatar">
																				<router-link to="/patient-profile" class="avatar avatar-sm me-2"><img class="avatar-img rounded-circle" :src="loadImg(item.img)" alt="User Image"></router-link>
																				<router-link to="/patient-profile">{{item.name}} <span>{{item.invoiceno}}</span></router-link>
																			</h2>
																		</td>
																		<td>{{item.date}} <span class="d-block text-info">{{item.time}}</span></td>
																		<td>{{item.comment}}</td>
																		<td>{{item.detail}}</td>
																		<td class="text-center">{{item.rate}}</td>
																		<td class="text-end">
																			<div class="table-action">
																				<a href="javascript:void(0);" class="btn btn-sm bg-info-light me-1">
																					<i class="far fa-eye"></i> View
																				</a>
																				
																				<a href="javascript:void(0);" class="btn btn-sm bg-success-light me-1">
																					<i class="fas fa-check"></i> Accept
																				</a>
																				<a href="javascript:void(0);" class="btn btn-sm bg-danger-light">
																					<i class="fas fa-times"></i> Cancel
																				</a>
																			</div>
																		</td>
																	</tr>
																</tbody>
															</table>		
														</div>
													</div>
												</div>
											</div>
</template>
<script>
	import upcoming from '../../../../../assets/json/frontend/doctor/upcoming.json'
	const images = require.context('../../../../../assets/img/patients', false, /\.png$|\.jpg$/)
	export default {
	mounted() {
	},
	methods:{
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
    },
	data() {
        return {
            upcoming: upcoming
        }
    }
	}
	</script>